<template>
  <h2 class="page-title">Namespaces</h2>

  <!-- Search -->
  <div v-if="variables" class="hidden sm:block mb-4">
    <input
      type="text"
      v-model="variables.keyword"
      class="w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
      id="tk-form-layouts-search"
      placeholder="Search.."
    />
  </div>

  <Table
    :headers="['Namespace']"
    :pageIndicator="pageIndicator"
    :hasNextPage="!!result?.namespaces.pageInfo.hasNextPage"
    :hasPreviousPage="!!result?.namespaces.pageInfo.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? nextPage() : previousPage())"
  >
    <tr v-if="loading">
      <td class="p-3">Loading...</td>
    </tr>
    <tr v-if="error">
      <td class="p-3">Error: {{ error }}</td>
    </tr>
    <tr v-if="!loading && namespaces.length === 0">
      <td class="p-3">No namespaces found.</td>
    </tr>
    <tr
      v-for="namespace of namespaces"
      :key="namespace.cursor"
      class="border-b border-gray-200 even:bg-gray-50"
    >
      <td class="p-3">
        <router-link
          :to="{
            name: 'namespace',
            params: { id: namespace.node.id },
          }"
        >
          <p class="font-medium">
            {{ namespace.node.name }}
          </p>
          <p class="text-gray-500">
            {{ namespace.node.id }}
          </p>
        </router-link>
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';
import { NamespacesQueryVariables } from '../../__generated__/graphql';

import Table from '../base/Table.vue';

const initialVariables: NamespacesQueryVariables = {
  first: 10,
  after: null,
  before: null,
  last: null,
  keyword: null,
};

const pageIndicator = ref(1);
const { result, loading, error, fetchMore, variables } = useQuery(
  graphql(`
    query Namespaces(
      $first: Int
      $after: String
      $before: String
      $last: Int
      $keyword: String
    ) {
      namespaces(
        first: $first
        after: $after
        before: $before
        last: $last
        filterBy: { keyword: $keyword }
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
          }
        }
      }
    }
  `),
  initialVariables
);

function loadNext() {
  pageIndicator.value += 1;
  fetchMore({
    variables: {
      first: 10,
      last: null,
      after: result.value?.namespaces.pageInfo.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function loadPrevious() {
  pageIndicator.value -= 1;
  fetchMore({
    variables: {
      last: 10,
      first: null,
      before: result.value?.namespaces.pageInfo.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

const previousPage = loadPrevious;
const nextPage = loadNext;

const toast = useToast();

const namespaces = computed(() => {
  if (result?.value?.namespaces?.edges) {
    return result.value.namespaces.edges.map((edge: any) => edge);
  } else {
    return [];
  }
});

watch(namespaces, () => {
  if (namespaces.value.length === 0 && !loading) {
    toast.error("You don't have any namespaces available.");
  }
});
</script>
