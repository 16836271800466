<template>
  <h2 class="page-title">Periodical/s Subscription/s</h2>
  <div class="border border-gray-200 rounded min-w-full bg-white">
    <div class="px-5 pt-5 grow flex">
      <div class="w-full">
        <div>
          <namespace-selector
            v-model="namespaceSelectedRef"
            :inject-option-all-namespaces="true"
            :resourceType="resourceType"
          />
        </div>
      </div>
    </div>
    <div class="p-5 grow flex justify-end">
      <div class="w-full">
        <div>
          <select
            v-model="originRef"
            class="w-full block border border-gray-200 rounded px-3 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            id="tk-inputs-default-select"
          >
            <option
              v-if="origins"
              v-for="origin of origins"
              :value="origin"
              :key="origin"
            >
              {{ origin }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="px-5 pb-5 grow flex justify-end">
      <div class="w-full">
        <div class="grow justify-end w-full">
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 w-10 my-px ml-px flex items-center justify-center pointer-events-none rounded-l text-gray-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="hi-mini hi-magnifying-glass inline-block w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                />
              </svg>
            </div>
            <input
              class="w-full block border placeholder-gray-400 pl-10 pr-3 py-2 leading-6 text-sm rounded border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:bg-gray-900/25 dark:border-gray-700 dark:focus:border-blue-500"
              type="text"
              id="search"
              name="search"
              v-model="searchKeyword"
              placeholder="Filter subscriptions by periodical identifier.."
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-5 grow flex justify-end">
      <div class="flex w-full md:w-auto">
        <div class="gap-2 flex flex-col">
          <h4 class="flex items-center gap-2 w-full">
            <input
              class="form-input-checkbox"
              type="checkbox"
              v-model="onlyActive"
            />
            Show only active
          </h4>
          <h4 class="flex items-center gap-2 w-full">
            <input
              class="form-input-checkbox"
              type="checkbox"
              v-model="onlySubscriptionsAboutToExpireInThreeMonth"
            />
            Show only subscriptions that will expire in the next three months
          </h4>
        </div>
      </div>
    </div>
    <div class="p-5 grow flex justify-end">
      <dm-button
        :loading="creatingExcel"
        :enabled="presenceOfSubscriptions"
        id="create-upload-publications"
        variant="info"
        @click.prevent="createExcelFile()"
        size="xL"
      >
        {{ messageExportExcel }}</dm-button
      >
    </div>

    <div
      class="p-5 lg:p-6 flex-grow w-full"
      v-if="creatingExcelSuccess || creatingExcelFails"
    >
      <div class="space-y-1">
        <success-display :hide="!creatingExcelSuccess">
          The excel was created successfully
        </success-display>
        <error-display
          :error="creatingExcelFails"
          headerMessage="Failed to create the excel file"
        >
        </error-display>
      </div>
    </div>
  </div>
  <Table
    center-header
    :headers="headers"
    :hasNextPage="result?.periodicalTemplates.pageInfo.hasNextPage"
    :hasPreviousPage="result?.periodicalTemplates.pageInfo.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? nextPage() : previousPage())"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="result?.periodicalTemplates.edges"
      v-for="periodicalTemplate of result.periodicalTemplates.edges"
      :key="periodicalTemplate.node?.id"
      class="border-b border-gray-200 text-center"
    >
      <template v-if="periodicalTemplate.node">
        <td class="p-3">{{ periodicalTemplate.node.periodical.origin }}</td>
        <td class="p-3">{{ periodicalTemplate.node.namespace?.name }}</td>
        <td class="p-3">
          {{ periodicalTemplate.node.periodical.identifier }}
        </td>
        <td class="p-3">
          {{ periodicalTemplate.node.periodical.name }}
        </td>
        <td class="p-3">
          {{ periodicalTemplate.node.concurrency }}
        </td>
        <td class="p-3">
          {{ periodicalTemplate.node.count }}
        </td>
        <td class="p-3">
          {{ periodicalTemplate.node.startsAt }}
        </td>
        <td class="p-3">
          {{ periodicalTemplate.node.endsAt }}
        </td>
        <td class="p-3">
          {{ daysTillExpiration(periodicalTemplate.node.endsAt) }}
        </td>
        <td class="p-3">
          {{
            getCustomAttributeValue(
              periodicalTemplate.node.periodical.customAttributes,
              'ftp_server'
            )
          }}
        </td>
        <td class="p-3">
          {{
            getCustomAttributeValue(
              periodicalTemplate.node.periodical.customAttributes,
              'ftp_path'
            )
          }}
        </td>
      </template>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { useQuery, DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { Ref, computed, inject, ref } from 'vue';
import XLSX from 'xlsx';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';

import { graphql } from '../../__generated__/gql';

import { PageInfo } from '../../graphql';
import ErrorDisplay from '../ErrorDisplay.vue';
import SuccessDisplay from '../SuccessDisplay.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import { existingResourceTypes } from '../../auth';
import {
  PeriodicalTemplatesQueryVariables,
  TemplateFilter,
} from '../../__generated__/graphql';
import Table from '../base/Table.vue';

const resourceType: existingResourceTypes = 'periodicals';
const perPage = 15;
const origins: { [key: string]: string } = {
  all: 'All origins',
  deMarque: 'DM_SPAIN',
  odilo: 'ODILO_SPAIN',
};

let creatingExcelFails: Ref<null | string> = ref(null);
let creatingExcelSuccess: Ref<boolean> = ref(false);
const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const originRef: Ref<string> = ref(origins.all);
const onlyActive: Ref<boolean> = ref(false);
const onlySubscriptionsAboutToExpireInThreeMonth: Ref<boolean> = ref(false);
const searchKeyword: Ref<string> = ref('');
const creatingExcel: Ref<boolean> = ref(false);
const messageExportExcel: Ref<string> = computed(
  () =>
    `Export ${
      !result.value?.periodicalTemplates.totalCount
        ? ''
        : `${result.value?.periodicalTemplates.totalCount} ${
            result.value?.periodicalTemplates.totalCount > 1
              ? 'entries'
              : 'entry'
          }`
    } to Excel`
);
const presenceOfSubscriptions: Ref<boolean> = computed(() => {
  return result.value?.periodicalTemplates?.totalCount
    ? result.value.periodicalTemplates.totalCount > 0
    : false;
});
const filterBy: Ref<TemplateFilter> = computed(() => {
  const filters: { [key: string]: string | Date | { [key: string]: Date } } =
    {};
  if (namespaceSelectedRef.value) {
    filters.namespaceId = namespaceSelectedRef.value.id;
  }
  if (originRef.value != origins.all) {
    filters.periodicalOrigin = originRef.value;
  }
  if (onlyActive.value) {
    filters.onlyActiveAt = new Date();
  }
  if (onlySubscriptionsAboutToExpireInThreeMonth.value) {
    const expireAfter: Date = new Date();
    const expireBefore: Date = new Date();
    expireBefore.setMonth(expireBefore.getMonth() + 3);
    filters.expirationPeriod = {
      expireAfter,
      expireBefore,
    };
  }
  if (searchKeyword.value != '' && searchKeyword.value.length > 3) {
    filters.periodicalIdentifier = searchKeyword.value;
  }
  creatingExcelSuccess.value = false;
  creatingExcelFails.value = null;
  return filters;
});

const variables: Ref<PeriodicalTemplatesQueryVariables> = computed(() => ({
  filterBy: filterBy.value,
  first: perPage,
  last: null,
  before: undefined,
  after: undefined,
}));

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

//periodicals query
const periodicalsQuery = graphql(`
  query periodicalTemplates(
    $filterBy: TemplateFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    periodicalTemplates(
      filterBy: $filterBy
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          count
          concurrency
          startsAt
          endsAt
          namespace {
            name
            id
          }
          periodical {
            origin
            identifier
            name
            publisher
            lang
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
  }
`);

//appearing in order at periodical's info table
const headers: Array<string> = [
  'Origin',
  'Namespace',
  'Identifier',
  'Header',
  'Concurrency',
  'Count',
  'Starts at',
  'Ends at',
  'Total days till expire',
  'Ftp Server',
  'Ftp Path',
];

const { result: namespacesResult } = useQuery(
  graphql(`
    query PeriodicalSubscriptionsNamespaceQuery {
      namespaces {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  `),
  null,
  {
    fetchPolicy: 'cache-and-network',
  }
);

const { result, loading, fetchMore, error } = useQuery(
  periodicalsQuery,
  variables
);

async function createExcelFile() {
  creatingExcelFails.value = null;
  creatingExcelSuccess.value = false;
  creatingExcel.value = true;
  let hasNextPage = true;
  const dataExcel = [headers];
  var wb = XLSX.utils.book_new();

  const totalVariables: Ref<PeriodicalTemplatesQueryVariables> = computed(
    () => ({
      filterBy: filterBy.value,
      after: undefined,
    })
  );

  while (hasNextPage) {
    const resultTemplates = await apolloClient.query({
      query: periodicalsQuery,
      variables: totalVariables.value,
    });
    if (Array.isArray(resultTemplates.data.periodicalTemplates.edges)) {
      for (const actualEdge of resultTemplates.data.periodicalTemplates.edges) {
        if (actualEdge.node) {
          dataExcel.push([
            actualEdge.node.periodical.origin,
            actualEdge.node.namespace?.name,
            actualEdge.node.periodical.identifier,
            actualEdge.node.periodical.name,
            actualEdge.node.concurrency,
            actualEdge.node.count,
            actualEdge.node.startsAt,
            actualEdge.node.endsAt,
            daysTillExpiration(actualEdge.node.endsAt),
            getCustomAttributeValue(
              actualEdge.node.periodical.customAttributes,
              'ftp_server'
            ),
            getCustomAttributeValue(
              actualEdge.node.periodical.customAttributes,
              'ftp_path'
            ),
          ]);
        }
      }
      hasNextPage =
        resultTemplates.data.periodicalTemplates.pageInfo.hasNextPage;
      totalVariables.value.after =
        resultTemplates.data.periodicalTemplates.pageInfo.endCursor;
    } else {
      hasNextPage = false;
      creatingExcelFails.value =
        "The Excel's creation failed, try it again later. If the error persists, please do contact with the Warehouse team";
      creatingExcel.value = false;
    }
  }
  XLSX.utils.book_append_sheet(
    wb,
    XLSX.utils.aoa_to_sheet(dataExcel),
    'Subscriptions'
  );

  XLSX.writeFile(wb, fileName());
  creatingExcel.value = false;
  creatingExcelSuccess.value = true;
}

function fileName(): string {
  let fileName = `NewsPapersMagazines_subscriptions${dayjs().format(
    'DDMMYYYY'
  )}`;
  const format = '.xlsx';
  return `${
    Object.keys(filterBy.value).length === 0
      ? fileName
      : fileNameFilters(fileName)
  }${format}`;
}

function fileNameFilters(fileName: String) {
  if (filterBy?.value?.namespaceId) {
    const namespaceName = namespacesResult.value?.namespaces.edges.find(
      (edge: { node: { id: string; name: string } }) =>
        edge.node.id === filterBy.value.namespaceId
    )?.node.name;
    fileName += `_${namespaceName}`;
  }

  if (filterBy.value?.onlyActiveAt) {
    fileName += '_onlyActive';
  }

  if (filterBy.value?.periodicalIdentifier) {
    fileName += `_identifier_${filterBy.value.periodicalIdentifier}`;
  }

  if (filterBy.value?.periodicalOrigin) {
    fileName += `_origin_${filterBy.value.periodicalOrigin}`;
  }

  return fileName;
}

function daysTillExpiration(endsAt: string): number {
  dayjs.extend(customParseFormat);
  const actualDate = dayjs();
  const parsedDate = dayjs(endsAt, 'YYYY-MM-DD', true);
  return !parsedDate.isAfter(actualDate)
    ? 0
    : parsedDate.diff(actualDate, 'day');
}

function previousPage() {
  fetchMore({
    variables: {
      last: perPage,
      first: null,
      before: result?.value?.periodicalTemplates.pageInfo?.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function nextPage() {
  fetchMore({
    variables: {
      first: perPage,
      last: null,
      after: result?.value?.periodicalTemplates.pageInfo?.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
/*Compute custom attributes*/
function getCustomAttributeValue(
  customAttributesArray: Array<{ key: string; value: string }>,
  keyCustomAttributevalue: string
) {
  if (customAttributesArray) {
    const result = customAttributesArray.find(
      (attribute: { key: string; value: string }) =>
        attribute.key == keyCustomAttributevalue
    );
    return result ? result.value : '';
  }
  return '';
}
</script>
