<template>
  <div class="space-y-1">
    <label v-if="label" class="font-medium">{{ label }}</label>

    <input
      v-model.lazy="durationInput"
      :placeholder="placeholder || ''"
      class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
    />
  </div>
</template>

<script setup lang="ts">
import parse from 'parse-duration';
import { ref, watch } from 'vue';
import { humanReadableDuration } from '../../utils';

const props = defineProps<{
  modelValue: number | undefined | null;
  placeholder?: string;
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | undefined): void;
}>();

const durationInput = ref(humanReadableDuration(props.modelValue ?? 0));

watch(durationInput, (newValue) => {
  const durationToSeconds = convertDurationToSeconds(newValue?.toString());
  emit('update:modelValue', durationToSeconds);
});

const convertDurationToSeconds = (value: string | undefined) => {
  if (value && value.trim()) {
    return parse(value, 's');
  }
  return undefined;
};
</script>
