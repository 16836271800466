<template>
  <div class="space-y-1">
    <label class="font-medium italic" for="form-elements-is-part-type">{{
      label
    }}</label>
    <ul :class="['list', { 'list-changed': hasChanged }]">
      <li
        class="p-4 flex justify-between items-center"
        v-for="item in internalModel"
      >
        <span class="font-semibold text-sm mr-1">
          {{ item }}
        </span>
        <span>
          <dm-button
            variant="danger"
            @click.prevent="removeItem(item)"
            size="xs"
            >remove</dm-button
          >
        </span>
      </li>

      <li class="p-4 flex justify-between items-center">
        <span>
          <input
            v-model="newValue"
            class="form-input"
            type="text"
            :placeholder="placeholder || ''"
          />
        </span>
        <span>
          <dm-button size="xs" @click.prevent="addItem">Add</dm-button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue';

const props = defineProps<{
  label: string;
  initialValue?: string[];
  placeholder?: string | null;
  modelValue: string[] | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[] | null | undefined): void;
  (e: 'differs', value: boolean): void;
}>();

const newValue = ref<string>('');

const internalModel: Ref<string[]> = ref(
  props.initialValue && JSON.parse(JSON.stringify(props.initialValue))
);

const hasChanged = computed(
  () =>
    internalModel.value?.length != props.initialValue?.length ||
    !!props.initialValue?.find((v) => !internalModel.value?.includes(v))
);

function addItem() {
  internalModel.value = internalModel.value || [];
  internalModel.value.push(newValue.value);
  newValue.value = '';
}

const removeItem = (item: string) => {
  if (internalModel.value) {
    internalModel.value = internalModel.value.filter((c) => c != item);
  }
};

onMounted(() => {
  watch(props, (newProps) => {
    internalModel.value = newProps.modelValue!;
  });
  emit('update:modelValue', internalModel.value);
});

watch(
  internalModel,
  (newVal) => {
    emit('update:modelValue', newVal);
    emit('differs', hasChanged.value);
  },
  { deep: true }
);
</script>
<style scoped>
.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.list-changed {
  @apply border-purple-500  divide-purple-500;
}
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
