<template>
  <div
    v-for="release of CHANGELOG_DATA"
    :key="release.version"
    class="flex flex-col rounded shadow-sm bg-white overflow-hidden"
  >
    <div
      class="py-4 px-5 lg:px-6 w-full flex justify-between font-semibold space-x-2 bg-gray-50"
    >
      <h3 class="flex items-center space-x-2">
        <div
          class="font-semibold inline-flex px-2 py-1 leading-4 text-sm rounded text-gray-600 bg-gray-100"
        >
          {{ release.version }}
        </div>
      </h3>
    </div>
    <div class="p-5 lg:p-6 flex-grow w-full space-y-5">
      <p class="text-sm leading-6">{{ release.changelog }}</p>
      <div
        class="space-y-4"
        v-for="component of release.components"
        :key="component.name"
      >
        <h4 class="font-semibold">{{ component.name }}</h4>
        <ul class="text-sm space-y-2">
          <li v-for="change of component.changes" :key="change.description">
            <div
              class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded"
              :class="changeTypeToColor(change.type)"
            >
              {{ change.type.toString() }}
            </div>
            {{ change.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ChangeType, CHANGELOG_DATA } from '../Changes';

const changeTypeToColor = (typ: ChangeType): string => {
  return {
    [ChangeType.Added]: 'text-green-700 bg-green-200',
    [ChangeType.Fixed]: 'text-blue-700 bg-blue-200',
    [ChangeType.Changed]: 'text-yellow-700 bg-yellow-200',
    [ChangeType.Removed]: 'text-red-700 bg-red-200',
  }[typ];
};
</script>
