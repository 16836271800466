<template>
  <h2 class="page-title">Dashboard</h2>
  <div v-if="loading">Loading...</div>
  <div v-else>
    <div class="mb-6">Welcome, {{ result.viewer.name }}.</div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
      <Card
        v-if="canReadResourceTypeOnAllNamespaces('namespaces')"
        :totalCount="result.namespaces.totalCount"
        :cardTitle="'namespaces'"
        :enableLink="true"
        @router-link="
          $router.push({
            path: '/namespaces',
          })
        "
      />

      <Card
        v-if="canReadResourceTypeOnAllNamespaces('publications')"
        :totalCount="result.publications.totalCount"
        :cardTitle="'publications'"
        :enableLink="true"
        @router-link="
          $router.push({
            path: '/publications',
          })
        "
      />

      <Card
        v-if="canReadResourceTypeOnAllNamespaces('licenses')"
        :totalCount="result.licenses.totalCount"
        :cardTitle="'licenses'"
        :enableLink="true"
        @router-link="
          $router.push({
            path: '/licenses',
          })
        "
      />

      <Card
        v-if="canReadResourceTypeOnAllNamespaces('licenses')"
        :totalCount="'N/A'"
        :cardTitle="'loans'"
        :enableLink="true"
        @router-link="
          $router.push({
            path: '/loans',
          })
        "
      />

      <Card
        v-if="canReadResourceTypeOnAllNamespaces('files')"
        :totalCount="result.files.totalCount"
        :cardTitle="'files'"
        :enableLink="false"
        :fileSize="fileSize"
      />
    </div>

    <hr v-if="displaySeparator" class="my-6" />

    <template v-if="displayNamespaces">
      <div>
        <h3 class="text-lg font-semibold">My namespaces</h3>
        <ul class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 my-4">
          <li v-for="namespace of namespaces" :key="namespace.id">
            <router-link
              class="w-full"
              :to="{
                name: 'namespace',
                params: { id: namespace.id },
              }"
            >
              <div class="bg-white p-2 rounded shadow-sm">
                <p class="font-medium">
                  {{ namespace.name }}
                </p>
                <p class="text-gray-500 text-sm">
                  {{ namespace.id }}
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useAuth } from '../auth';
import Card from '@/components/base/Card.vue';
import { computed } from '@vue/reactivity';
import { readableFileSize } from '../utils';
import { sortBy, uniqBy } from 'lodash';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { canReadResourceTypeOnAllNamespaces, getUserNamespaces } = useAuth();

const { result, loading } = useQuery(gql`
query WarehouseInventory {
  ${
    canReadResourceTypeOnAllNamespaces('publications')
      ? 'publications { totalCount }'
      : ''
  }

  ${
    canReadResourceTypeOnAllNamespaces('namespaces')
      ? 'namespaces { totalCount }'
      : ''
  }

  ${
    canReadResourceTypeOnAllNamespaces('licenses')
      ? 'licenses { totalCount }'
      : ''
  }

  ${
    canReadResourceTypeOnAllNamespaces('files')
      ? 'files { totalCount totalSize } '
      : ''
  }

  viewer {
    name
    namespaces {
      id
      name
    }
  }
}
`);

const tokenNamespaces = ref(getUserNamespaces() ?? []);
const viewerNamespaces = computed(() => result.value?.viewer.namespaces ?? []);
const namespaces = computed(() =>
  sortBy(
    uniqBy(
      [...tokenNamespaces.value, ...viewerNamespaces.value],
      (namespace) => namespace.id
    ),
    (namespace) => namespace.name
  )
);

const fileSize = computed(() => {
  const totalSize = result.value.files.totalSize;
  return totalSize ? readableFileSize(totalSize) : '';
});

const displayAnyCard = computed(
  () =>
    canReadResourceTypeOnAllNamespaces('publications') ||
    canReadResourceTypeOnAllNamespaces('namespaces') ||
    canReadResourceTypeOnAllNamespaces('licenses') ||
    canReadResourceTypeOnAllNamespaces('files')
);
const displayNamespaces = computed(
  () => namespaces.value && namespaces.value.length > 0
);
const displaySeparator = computed(
  () => displayAnyCard.value && displayNamespaces.value
);
</script>
