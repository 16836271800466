<template>
  <h2 class="page-title">Periodicals</h2>

  <input
    type="text"
    v-model.trim.lazy="searchKeyword"
    class="mb-4 w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
    id="tk-form-layouts-search"
    placeholder="Search..."
  />

  <Table
    center-header
    :headers="
      ['Periodical', 'Publisher', 'Type', 'Language'].concat(
        hasPeriodicalWriteRight ? ['Actions'] : []
      )
    "
    :hasNextPage="result?.periodicals?.pageInfo?.hasNextPage"
    :hasPreviousPage="result?.periodicals?.pageInfo?.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? loadNext() : loadPrevious())"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="result"
      v-for="periodical of result.periodicals.edges"
      :key="periodical.cursor"
      class="border-b border-gray-200 even:bg-gray-50"
    >
      <td class="p-3">
        <router-link
          :to="{
            name: 'periodical',
            params: { id: identifier(periodical.node) },
          }"
        >
          <p class="font-medium">
            {{ periodical.node.name }}
          </p>
          <p class="text-gray-500">
            {{ periodical.node.origin }} ({{ periodical.node.identifier }})
          </p>
        </router-link>
      </td>
      <td class="p-3 text-gray-500 text-center">
        {{ periodical.node.publisher }}
      </td>
      <td class="p-3 text-gray-500 text-center">
        {{ periodical.node.type }}
      </td>
      <td class="p-3 text-center">
        <div
          class="font-semibold inline-flex px-2 py-1 leading-4 text-xs rounded-full text-green-700 bg-green-200"
        >
          {{ periodical.node.lang }}
        </div>
      </td>
      <td v-if="hasPeriodicalWriteRight" class="p-3 text-center">
        <router-link
          :to="{
            name: 'periodicals-edit',
            params: { id: identifier(periodical.node) },
          }"
        >
          <button
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
          >
            <svg
              class="hi-solid hi-pencil inline-block w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              />
            </svg>
            <span>Edit</span>
          </button>
        </router-link>
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';

import { graphql } from '../../__generated__/gql';
import { GetPeriodicalsQueryVariables } from '../../__generated__/graphql';
import Table from '../base/Table.vue';
import { useAuth } from '../../auth';

const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();
const hasPeriodicalWriteRight =
  canWriteResourceTypeOnAtLeastOneNamespace('periodicals');

const searchKeyword = ref('');

function identifier(periodical: {
  origin?: string | null;
  identifier?: string | null;
}): string {
  return `${periodical.origin}:${periodical.identifier}`;
}

const variables = computed<GetPeriodicalsQueryVariables>(() => ({
  first: 10,
  last: null,
  after: null,
  before: null,
  keyword: searchKeyword.value,
}));

const { result, loading, fetchMore, error } = useQuery(
  graphql(`
    query getPeriodicals(
      $keyword: String
      $first: Int
      $last: Int
      $before: String
      $after: String
    ) {
      periodicals(
        filterBy: { keyword: $keyword }
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        edges {
          node {
            origin
            identifier
            name
            publisher
            lang
            type
          }

          cursor
        }

        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
      }
    }
  `),
  variables
);

function loadNext() {
  fetchMore({
    variables: {
      first: 10,
      last: null,
      after: result.value?.periodicals?.pageInfo?.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function loadPrevious() {
  fetchMore({
    variables: {
      last: 10,
      first: null,
      before: result.value?.periodicals?.pageInfo?.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
</script>
