<template>
  <div class="space-y-1">
    <label class="font-medium italic">{{ label }}</label>
    <input
      v-model="internalModel"
      type="number"
      :placeholder="placeholder || ''"
      :class="['form-input', { 'form-input-changed': hasChanged }]"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps<{
  label: string;
  initialValue?: number | null;
  placeholder?: string | null;
  modelValue: number | null | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | null | undefined): void;
  (e: 'differs', value: boolean): void;
}>();

const internalModel = ref(props.initialValue);
const hasChanged = computed(() => internalModel.value !== props.initialValue);

onMounted(() => {
  watch(props, (newProps) => {
    if (newProps.modelValue) {
      internalModel.value = newProps.modelValue;
    } else {
      internalModel.value = null;
    }
  });
  emit('update:modelValue', internalModel.value);
});

watch(internalModel, (newVal) => {
  emit('update:modelValue', newVal);
  emit('differs', hasChanged.value);
});
</script>
<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
