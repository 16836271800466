<template>
  <h2
    class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
  >
    {{ props.title }}
  </h2>
  <template v-if="props.loading">Loading ... </template>
  <template v-else-if="props.loanList">
    <Table
      center-header
      :headers="
        ['Loan', 'Borrower', 'Transaction', 'Expires'].concat(
          props.status && props.enableActions ? ['Actions'] : []
        )
      "
      :hasNextPage="props.pageInfo.hasNextPage"
      :hasPreviousPage="props.pageInfo.hasPreviousPage"
      @changePage="(e) => (e === 'next' ? emitNextPage() : emitPrevPage())"
    >
      <tr
        v-for="loan of props.loanList.edges"
        :key="loan.cursor"
        class="border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
        @click="showLoan(loan.node.id)"
      >
        <td class="p-3">
          {{ loan.node.id }}
        </td>
        <td class="p-3 text-gray-500">
          {{ loan.node.borrowerId }}
        </td>
        <td class="p-3 text-center">
          {{ loan.node.transactionId }}
        </td>
        <td class="p-3 text-center">
          {{ loan.node.expires }}
        </td>
        <td v-if="props.status && props.enableActions" class="p-3 text-center">
          <button
            @click.stop="emitReturnLoan(loan.node.id)"
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-gray-300 bg-white text-red-600 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
          >
            <span>Return</span>
          </button>
        </td>
      </tr>
    </Table>
  </template>
</template>
<script setup lang="ts">
import ArrowLeftIcon from '@heroicons/vue/solid/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/vue/solid/ArrowRightIcon';
import Table from '../base/Table.vue';
import { router } from '../../routes';

const props = defineProps({
  loading: { type: Boolean, required: true },
  title: { type: String, required: true },
  loanList: { type: Object, required: true },
  status: { type: Boolean, required: true },
  pageInfo: { type: Object, required: true },
  enableActions: { type: Boolean, required: false, default: false },
});

const emits = defineEmits<{
  (e: 'next-page', endCursor: string | undefined): void;
  (e: 'previous-page', startCursor: string | undefined): void;
  (e: 'return-loan', loanId: string): void;
}>();

const emitNextPage = () => {
  emits('next-page', props.pageInfo.endCursor);
};

const emitPrevPage = () => {
  emits('previous-page', props.pageInfo.startCursor);
};

const emitReturnLoan = (loanId: string) => {
  emits('return-loan', loanId);
};

const showLoan = (loanId: string) => {
  router.push({ name: 'loan', params: { id: loanId } });
};
</script>

<style></style>
