<template>
  <message-confirmation
    :isHidden="hideAlertMessage"
    :bodyInfoMessage="messageBody"
    :showCancelButton="true"
    buttonConfirmMessage="Yes, create/update"
    variant="alert"
    @answerUser="answerAlertMessage"
  ></message-confirmation>
  <h2 class="page-title">Publication upload</h2>
  <div class="spacey-8">
    <div class="section">
      <!-- Target Info -->
      <div class="left-col-block">
        <p class="text-gray-500 text-sm mb-5">
          Select the namespace where the publications where you want the
          publications to be created and the subject's scheme transformation and
          then upload an excel file with the publications.
        </p>
      </div>
      <!-- END Target Info -->

      <!-- Card: Target -->
      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
      >
        <div class="px-5 pt-5 flex-grow w-full">
          <div class="mb-3 flex items-center gap-3 flex-col sm:flex-row">
            <h3 class="font-semibold w-full sm:w-auto sm:min-w-fit">
              Namespace:
            </h3>
            <namespace-selector v-model="namespaceSelectedRef" />
          </div>
        </div>
        <div class="px-5 flex-grow w-full">
          <div class="mb-3 flex items-center gap-3 flex-col sm:flex-row">
            <h3 class="font-semibold w-full sm:w-auto sm:min-w-fit">
              Subject's Scheme Transformation:
            </h3>
            <select
              v-model="selectedSubjectTransformationRef"
              class="w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
              id="tk-inputs-default-select"
            >
              <option
                v-for="subjectTransformation in subjectsTransformation"
                :value="subjectTransformation"
              >
                {{
                  `${subjectTransformation.from}->${subjectTransformation.to}`
                }}
              </option>
            </select>
          </div>
        </div>
        <div class="pt-5 px-5 lg:pt-6 lg:px-6 flex-grow w-full">
          <div class="space-y-1">
            <div class="flex-grow w-full flex justify-end mb-4">
              <div class="grid justify-items-center">
                <file-upload
                  @uploaded="handleUploadedExcel"
                  accept=".xlsx"
                  btsize="m"
                  class="mt-3"
                  text="Select publications Excel file."
                />
              </div>
            </div>
            <div class="flex gap-x-2 justify-end">
              <div class="underline text-sm">
                <a href="/publications/publications_template.xlsx"
                  >[Download Template]</a
                >
              </div>
            </div>
            <div class="pt-4">
              <success-display :hide="!pushingMetadataSuccess">
                {{ parseExcelResult?.entries?.length }} publication(s) created
                or updated.
              </success-display>
            </div>
            <div class="my-4">
              <error-display
                class="my-4"
                :error="errorsToDisplay"
                headerMessage="Errors found in the Excel file."
                :footerMessage="errorMessageFooter"
              ></error-display>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="publications && publications.length > 0" class="mt-4">
    <div
      class="flex flex-col rounded-lg shadow-sm bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800"
    >
      <div class="py-4 px-5 bg-gray-50 dark:bg-gray-700/50">
        <h3 class="font-semibold">Library</h3>
        <h4 class="text-sm font-medium text-gray-500 dark:text-gray-400">
          {{ namespaceSelectedRef?.name }}
        </h4>
      </div>
    </div>
    <Table center-header :headers="Object.keys(publications[0])">
      <tr v-for="publication of publications" class="border-b border-gray-200">
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          <strong>{{ publication.isbn }}</strong>
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.author?.name }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.title }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.summary }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          <p
            v-for="collection in publication.collections"
            :key="collection.label"
          >
            {{ collection.label }}
          </p>
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          <p v-for="subject in publication.subjects" :key="subject.code">
            <span>
              Scheme: <strong>{{ subject.scheme }}</strong>
            </span>
            <span>
              Code: <strong>{{ subject.code }}</strong>
            </span>
          </p>
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.language }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.publisher_name }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ formattedDate(publication.publication_date) }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.nature }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.paper_isbn }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.series_issn }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.series_name }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.series_issue }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.series_type }}
        </td>
        <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
          {{ publication.adults_only ? 'Yes' : 'No' }}
        </td>
      </tr>
    </Table>
    <div class="flex-grow w-full flex justify-end margin-space my-4">
      <dm-button
        id="create-upload-publications"
        variant="danger"
        size="xL"
        :enabled="!loading"
        @click.prevent="clickButtonCreateUpdate()"
        >create/update Publications</dm-button
      >
    </div>
  </div>
  <div class="grid justify-items-end mt-4" v-if="loading">
    <span> Loading... </span>
  </div>
  <div
    class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white repor mb-4 pl-3 pb-3"
    v-if="
      (publicationsCreatedOrUpdated &&
        publicationsCreatedOrUpdated.length > 0) ||
      (errorsPublicationsCreatingOrUpdating &&
        errorsPublicationsCreatingOrUpdating.length > 0)
    "
  >
    <h4 class="font-semibold py-3">Created or Updated</h4>
    <ul
      class="text-sm space-y-2"
      v-if="
        publicationsCreatedOrUpdated && publicationsCreatedOrUpdated.length > 0
      "
    >
      <li
        v-for="publcation in publicationsCreatedOrUpdated"
        :key="publcation.isbn"
      >
        <div
          class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-green-700 bg-green-200"
        >
          Success
        </div>
        <router-link
          class="ml-3"
          :to="{
            name: 'publication',
            params: { id: publcation.id },
          }"
          target="_blank"
          >{{ publcation.isbn }}</router-link
        >
      </li>
    </ul>
    <ul
      class="text-sm space-y-2"
      v-if="
        errorsPublicationsCreatingOrUpdating &&
        errorsPublicationsCreatingOrUpdating.length > 0
      "
    >
      <li
        v-for="error in errorsPublicationsCreatingOrUpdating"
        :key="error.isbn"
      >
        <div
          class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-red-700 bg-red-200"
        >
          Failed
        </div>
        {{ error }}
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { Ref, computed, defineComponent, inject, ref, watch } from 'vue';
import XLSX from 'xlsx';
import { DefaultApolloClient } from '@vue/apollo-composable';
import dayjs from 'dayjs';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { GraphQLFormattedError } from 'graphql';

import { graphql } from '../../__generated__/gql';

import DmButton from '../base/Button.vue';
import FileUpload from '../base/FileUpload.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import SuccessDisplay from '../SuccessDisplay.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import MessageConfirmation from '../base/DialogMessage.vue';
import Table from '../base/Table.vue';

//eBiblio's categeories
import { subjects } from '../../marc21-subjects.json';

/**
 * A representation of the minimalistic set of publication metadata defined in a
 * row of the excel file.
 */
interface BasicPublicationMetadata {
  isbn: string;
  author: string;
  title: string;
  summary: string | null;
  language: string;
  publisher_name: string;
  collections: string[];
  subjects: string[];
  publication_date: Date | null;
  paper_isbn: string | null;
  nature: 'AUDIO_BOOK' | 'EPUB_BOOK' | 'PDF_BOOK';
  series_issn: string | null;
  series_name: string | null;
  series_issue: string | null;
  series_type: string | null;
  adults_only: boolean;
}

/**
 * The result of validating a field of an excel file row. It contains:
 * 1) the normalized value of the field (that corresponds to the given type), if it can be obtained, or
 * 2) the validation error, if any.
 */
interface FieldValidation<Type> {
  value?: Type;
  error?: string;
}

/**
 * Capture the message errors returned by the publication service when pushing the metadata,
 * annotated with the identifier (isbn) of the publication being created/updated.
 */
interface PushMetadataError {
  identifier: string;
  error: GraphQLFormattedError;
}

/**
 * The result of parsing the excel file:
 *  - entries: the list of BasicPublicationMetadata that the parser was able to build.
 *  - errors: the errors found during the process, either as ExcelEntryError's, or simply strings.
 *  - success: whether there were errors or not.
 */
interface ParseExcelResult {
  entries?: BasicPublicationMetadata[];
  errors?: (ExcelEntryError | string)[];
  success: boolean;
}

/**
 * Subject's transformation schema
 */
interface SubjectTransformation {
  from: string;
  to: string;
  key: Transformation;
}

/**
 * Captures an excel row parsing error. It can contain:
 *  - message: a description of the error
 *  - entry: the position of the row in the excel file.
 *  - identifier: the identifier (isbn) if the parser was able to find one.
 *  - header: the header of the field containing the error, if any.
 */
class ExcelEntryError {
  constructor(
    public message: string,
    public entry: number,
    public identifier?: string,
    public header?: ExcelHeader
  ) {
    this.message = message;
    this.entry = entry;
    this.identifier = identifier;
    this.header = header;
  }

  public toString(): string {
    const idLabel = this.identifier
      ? `entry identified as ${this.identifier}`
      : `entry number ${this.entry}`;
    if (this.header) {
      return `At ${idLabel}, field '${this.header}': ${this.message}`;
    } else {
      return `At ${idLabel}: ${this.message}`;
    }
  }
}

/**
 * The final publication's structure. After formatting collections, subjects and author fields.
 */
type PublicationMetadata = Omit<
  BasicPublicationMetadata,
  'collections' | 'subjects' | 'author'
> & {
  collections: { label: string }[];
  subjects: { code: string; scheme: string }[];
  author: { name: string };
};

/**
 * All valid subject's transformation identifiers.
 */
type Transformation =
  | 'BISAC_TO_BISAC'
  | 'THEMA_TO_THEMA'
  | 'BIC_TO_BIC'
  | 'FEEDBOOKS_TO_FEEDBOOKS'
  | 'EBIBLIO_TO_BIC'
  | 'CUSTOM_TO_CUSTOM';

/**
 * The valid excel headers (after they have been normalized)
 */
type ExcelHeader = keyof BasicPublicationMetadata;

/**
 * The signature of a function that validates the value of a field in a row of the excel file.
 */
type FieldValidator<Type> = (value: any) => FieldValidation<Type>;

/**
 * A dictionary holding the values of a BasicPublicationMetadata that is being built during the parsing of a row
 * of the excel file.
 */
type MetadataCandidate = {
  [Property in keyof BasicPublicationMetadata]?: any;
};

/**
 * The maximum numbers of errors to display in the UI.
 */
const MAX_ERRORS_TO_DISPLAY = 10;

/**
 * Update or create metadata's query
 */
const pushMetadataMutation = graphql(`
  mutation pushMetadata(
    $namespaceId: ID!
    $input: PublicationMetadataInput!
    $isbn: PublicationIdentifier!
  ) {
    pushPublicationMetadata(
      namespaceId: $namespaceId
      isbn: $isbn
      input: $input
    ) {
      id
      isbn
    }
  }
`);
const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

/**
 * A dictionary mapping the strings accepted as publication nature in the excel file (epub, pdf).
 */
const natures: {
  [key: string]: 'EPUB_BOOK' | 'PDF_BOOK' | 'AUDIO_BOOK';
} = {
  epub: 'EPUB_BOOK',
  pdf: 'PDF_BOOK',
  audio: 'AUDIO_BOOK',
};

/**
 * A dictionary mapping the accepted languages, in the accepted iso-639-1 format, to the iso-639-2 codes
 * stored in the publication service.
 **/
const languages: { [key: string]: string } = {
  en: 'eng',
  sp: 'spa',
  fr: 'fre',
};

/**
 * A dictionary mapping Subject's transformation scheme to their transformation's method
 */
const subjectTransformation: {
  [key in Transformation]: (
    entrySubjects: string[],
    selectedSubjectScheme: string
  ) => { scheme: string; code: string }[];
} = {
  BISAC_TO_BISAC: (entrySubjects: string[], selectedSubjectScheme: string) =>
    identitySubjectTransformation(entrySubjects, selectedSubjectScheme),
  BIC_TO_BIC: (entrySubjects: string[], selectedSubjectScheme: string) =>
    identitySubjectTransformation(entrySubjects, selectedSubjectScheme),
  THEMA_TO_THEMA: (entrySubjects: string[], selectedSubjectScheme: string) =>
    identitySubjectTransformation(entrySubjects, selectedSubjectScheme),
  FEEDBOOKS_TO_FEEDBOOKS: (
    entrySubjects: string[],
    selectedSubjectScheme: string
  ) => identitySubjectTransformation(entrySubjects, selectedSubjectScheme),
  CUSTOM_TO_CUSTOM: (entrySubjects: string[], selectedSubjectScheme: string) =>
    identitySubjectTransformation(entrySubjects, selectedSubjectScheme),
  EBIBLIO_TO_BIC: function (
    entrySubjects: string[],
    selectedSubjectScheme: string
  ) {
    let result: Array<{ scheme: string; code: string }> = [];
    for (let subject of entrySubjects) {
      subject = subject
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f-\s]/g, '');
      const subjectComplete = subjects.find((currentValue) => {
        return (currentValue.category == subject &&
          currentValue.subcategory == '') ||
          currentValue.subcategory == subject
          ? true
          : false;
      });
      if (subjectComplete?.BIC) {
        result.push({
          scheme: selectedSubjectScheme,
          code: subjectComplete.BIC,
        });
      }
    }
    return result;
  },
};

/**
 * A dictionary mapping excel file headers to their validators.
 */
const fieldValidators: {
  [Property in keyof BasicPublicationMetadata]: FieldValidator<
    BasicPublicationMetadata[Property]
  >;
} = {
  isbn: nonEmptyStringValidator,
  author: nonEmptyStringValidator,
  title: nonEmptyStringValidator,
  summary: optionalStringValidator,
  publisher_name: nonEmptyStringValidator,
  collections: commaSeparatedStringsValidator,
  subjects: commaSeparatedStringsValidator,
  publication_date(value: any) {
    if (typeof value == 'string') {
      const parsedDate = dayjs(value);
      if (!parsedDate.isValid()) {
        return { error: `Invalid date format '${value}'` };
      } else {
        return { value: parsedDate.toDate() };
      }
    } else if (value instanceof Date) {
      return { value };
    } else {
      return { error: 'Unexpected field data type (expected Date or string).' };
    }
  },
  paper_isbn: optionalStringValidator,
  nature(value: any) {
    if (
      typeof value != 'string' ||
      !(
        value.toLowerCase() === 'epub' ||
        value.toLowerCase() === 'pdf' ||
        value.toLowerCase() === 'audio'
      )
    ) {
      return { error: 'nature must be epub, pdf or audio.' };
    } else {
      return { value: natures[value.toLowerCase()] };
    }
  },
  language(value: any) {
    if (
      typeof value != 'string' ||
      !['en', 'fr', 'es', 'esp', 'eng', 'fra'].includes(value.toLowerCase())
    ) {
      return { error: 'languages can be : esp, eng, fra.' };
    } else {
      return { value: languages[value.toLowerCase()] || value.toLowerCase() };
    }
  },
  series_issn: optionalStringValidator,
  series_name: optionalStringValidator,
  series_type(value: any) {
    if (value == null) {
      return { value: null };
    }
    if (
      typeof value != 'string' ||
      !['magazine', 'newspaper'].includes(value.toLowerCase())
    ) {
      return { error: 'Series type can be: magazine or newspaper.' };
    } else {
      return { value: value.toUpperCase() };
    }
  },
  series_issue: optionalStringValidator,
  adults_only(value: any) {
    //by default will be false
    if (value == null || value.trim().length === 0) {
      return { value: false };
    }
    if (
      typeof value != 'string' ||
      !['yes', 'no'].includes(value.toLowerCase())
    ) {
      return { error: 'Adults only can be: yes or no.' };
    } else {
      return { value: value.toLowerCase() == 'yes' ? true : false };
    }
  },
};

/**
 * Object's array with transformation methods
 */
const subjectsTransformation: SubjectTransformation[] = [
  { from: 'BISAC', to: 'BISAC', key: 'BISAC_TO_BISAC' },
  { from: 'EBIBLIO', to: 'BIC', key: 'EBIBLIO_TO_BIC' },
  { from: 'THEMA', to: 'THEMA', key: 'THEMA_TO_THEMA' },
  { from: 'FEEDBOOKS', to: 'FEEDBOOKS', key: 'FEEDBOOKS_TO_FEEDBOOKS' },
  { from: 'BIC', to: 'BIC', key: 'BIC_TO_BIC' },
  { from: 'CUSTOM', to: 'CUSTOM', key: 'CUSTOM_TO_CUSTOM' },
];

/**
 * An array with the publications metadata charged from the excel file
 */
const publications: Ref<PublicationMetadata[]> = ref([]);
const loading: Ref<boolean> = ref(false);
const pushingMetadataSuccess: Ref<boolean> = ref(false);
const hideAlertMessage: Ref<boolean> = ref(true);
const selectedSubjectTransformationRef: Ref<SubjectTransformation> = ref(
  subjectsTransformation[0]
);
const parseExcelResult: Ref<ParseExcelResult | undefined> =
  ref<ParseExcelResult>();
const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const errorsPublicationsCreatingOrUpdating: Ref<
  Array<{ isbn: string; errors: readonly GraphQLFormattedError[] }>
> = ref([]);
const publicationsCreatedOrUpdated: Ref<Array<{ isbn: string; id: string }>> =
  ref([]);

const answerAlertMessage = async (answer: boolean) => {
  hideAlertMessage.value = true;
  if (answer) {
    loading.value = true;
    await sendPublications();
    loading.value = false;
  } else {
    loading.value = false;
    hideAlertMessage.value = true;
  }
};

const messageBody = computed(
  () =>
    'Are you sure you want to create/uptade ' +
    publications.value.length +
    ' publications?'
);

const errorsToDisplay = computed(() => {
  if (
    parseExcelResult.value &&
    parseExcelResult.value.errors &&
    parseExcelResult.value.errors.length > 0
  ) {
    return parseExcelResult.value.errors.slice(0, MAX_ERRORS_TO_DISPLAY);
  }
});

const errorMessageFooter = computed(() => {
  if (
    parseExcelResult.value &&
    parseExcelResult.value.errors &&
    parseExcelResult.value.errors.length > 0
  ) {
    let errorMessage = '';
    if (parseExcelResult.value.errors.length > MAX_ERRORS_TO_DISPLAY) {
      const nonDisplayedErrorsCount =
        parseExcelResult.value.errors.length - MAX_ERRORS_TO_DISPLAY;
      errorMessage = ` and ${nonDisplayedErrorsCount} more error${
        nonDisplayedErrorsCount > 1 ? 's' : ''
      }.`;
    }
    return (
      errorMessage +
      `Please correct ${
        parseExcelResult.value.errors.length > 1 ? 'them' : 'it'
      } and try again.`
    );
  }
});

watch(selectedSubjectTransformationRef, async (newValue) => {
  if (newValue && parseExcelResult?.value?.entries) {
    loading.value = true;
    formattingSubjectsCollectionsAndAuthorFields(
      parseExcelResult.value.entries
    );
    loading.value = false;
  }
});

/**
 * A generic validator for string fields that can not be empty.
 */
function nonEmptyStringValidator(value: any): FieldValidation<string> {
  if (value == null || value.trim().length === 0) {
    return { error: 'Empty field' };
  }
  if (typeof value != 'string') {
    return {
      error: `Unexpected field data type (expected: string).`,
    };
  }
  return { value };
}

/**
 * A generic validator for string fields that can be null but not empty.
 */
function optionalStringValidator(value: any): FieldValidation<string | null> {
  if (value == null || value.trim().length === 0) {
    return { value: null };
  }

  if (typeof value != 'string') {
    return {
      error: `Unexpected field data type (expected: string).`,
    };
  }

  return { value };
}

/**
 * A generic validator for string fields that are interpreted as a comma separated
 * set of strings, and that can be empty.
 */
function commaSeparatedStringsValidator(value: any): FieldValidation<string[]> {
  if (value == null) {
    return { value: [] };
  }

  if (typeof value != 'string') {
    return {
      error: `Unexpected field data type (expected: string).`,
    };
  }

  const parts = value
    .split(',')
    .map((part) => part.trim())
    .filter((p) => p.length > 0);

  return { value: parts };
}

/**
 * A generic transformation without a validation's value
 */
function identitySubjectTransformation(
  entrySubjects: string[],
  selectedSubjectScheme: string
) {
  return entrySubjects.map((subject) => ({
    scheme: selectedSubjectScheme,
    code: subject,
  }));
}

/**
 * Remove the title row of the excel file, if any.
 */
function removeTableHeader(worksheet: any): any {
  return worksheet[0].length == 1 ? worksheet.slice(1) : worksheet;
}

/**
 * Given an xlsx worksheet transformed to json (using XLSX.utils.sheet_to_json), this
 * function parses it and returns a ParseExcelResult.
 */
function parseExcel(excelJsonData: any): ParseExcelResult {
  // a regexp to extract the normalized header from a header field (removing the content within '()' if any):
  const rx = /\s*([^\s\(\)][^\(\)]*[^\s\(\)])\s*(\(.*\))?\s*/;
  const entries = removeTableHeader(excelJsonData);
  const headers = entries[0];
  const data = entries.slice(1);
  const indexes: ExcelHeader[] = [];
  const parsingHeaderErrors: string[] = [];

  for (let i = 0; i < headers.length; i++) {
    const headerExtraction = rx.exec(headers[i]);

    if (!headerExtraction) {
      parsingHeaderErrors.push(`Unexpected header string ${headers[i]}`);
    } else {
      const header: string = headerExtraction[1]
        .toLowerCase()
        .replace(/\s/, '_');
      if (
        !(
          // not using Array#include to make it type safe.
          (
            header === 'author' ||
            header === 'isbn' ||
            header === 'title' ||
            header === 'collections' ||
            header === 'nature' ||
            header === 'paper_isbn' ||
            header === 'subjects' ||
            header === 'publication_date' ||
            header === 'publisher_name' ||
            header === 'language' ||
            header === 'summary' ||
            header === 'series_issn' ||
            header === 'series_name' ||
            header === 'series_issue' ||
            header === 'series_type' ||
            header === 'adults_only'
          )
        )
      ) {
        parsingHeaderErrors.push(`Unknown header ${header}`);
      } else {
        indexes[i] = header;
      }
    }
  }

  if (parsingHeaderErrors.length > 0) {
    return { errors: parsingHeaderErrors, success: false };
  }

  const parsingEntryErrors: ExcelEntryError[] = [];
  const publicationMetadataList: BasicPublicationMetadata[] = [];

  if (data.length == 0) {
    return { success: false, errors: ['No data in file'] };
  }

  for (let i = 0; i < data.length; i++) {
    const entry = data[i];
    if (entry.length > headers.length) {
      parsingEntryErrors.push(
        new ExcelEntryError('Unexpected entry length.', i)
      );
    } else {
      const publicationMetadataCandidate: MetadataCandidate = {};

      for (let j = 0; j < headers.length; j++) {
        // Entries can be shorter than the number of columns
        // because trailing nulls get trimmed.
        const val = j < headers.length ? entry[j] : null;
        const validation = fieldValidators[indexes[j]](val);
        if (validation.error != null) {
          parsingEntryErrors.push(
            new ExcelEntryError(
              validation.error,
              i + 1,
              publicationMetadataCandidate.isbn,
              indexes[j]
            )
          );
        }
        publicationMetadataCandidate[indexes[j]] = validation.value;
      }

      publicationMetadataList.push({
        isbn: publicationMetadataCandidate.isbn,
        author: publicationMetadataCandidate.author,
        title: publicationMetadataCandidate.title,
        summary: publicationMetadataCandidate.summary,
        collections: publicationMetadataCandidate.collections,
        subjects: publicationMetadataCandidate.subjects,
        language: publicationMetadataCandidate.language,
        publisher_name: publicationMetadataCandidate.publisher_name,
        publication_date: publicationMetadataCandidate.publication_date,
        nature: publicationMetadataCandidate.nature,
        paper_isbn: publicationMetadataCandidate.paper_isbn,
        series_issn: publicationMetadataCandidate.series_issn,
        series_name: publicationMetadataCandidate.series_name,
        series_issue: publicationMetadataCandidate.series_issue,
        series_type: publicationMetadataCandidate.series_type,
        adults_only: publicationMetadataCandidate.adults_only,
      });
    }
  }
  //parsing errors
  if (parsingEntryErrors.length > 0) {
    return {
      success: false,
      errors: parsingEntryErrors,
    };
  }
  return {
    success: true,
    entries: publicationMetadataList,
  };
}

/**
 * Function to formatting the fields subjects, collections and author
 */
function formattingSubjectsCollectionsAndAuthorFields(entries: any[]) {
  publications.value = entries.map((entry) => ({
    ...entry,
    subjects: buildSubjectsSchemes(entry.subjects),
    collections: entry.collections.map((collection: any) => ({
      label: collection,
    })),
    author: { name: entry.author },
  }));
}

/**
 * Build the publication subject's structure. Ex: Subjects:{Scheme: "BISAC", Code: "YPD"}
 */
function buildSubjectsSchemes(entrySubjects: string[]): any {
  const selectedTransformation: SubjectTransformation =
    selectedSubjectTransformationRef.value;
  return subjectTransformation[selectedTransformation.key](
    entrySubjects,
    selectedTransformation.to
  );
}

function clickButtonCreateUpdate() {
  hideAlertMessage.value = false;
}

function formattedDate(date: Date | null) {
  return date ? dayjs(date).format('DD/MM/YYYY') : '';
}

/**
 * Function restarting some result's values
 */
function restartValues() {
  publicationsCreatedOrUpdated.value =
    errorsPublicationsCreatingOrUpdating.value = [];
}

/*Functions performing when users click on the update/create button*/
async function sendPublications() {
  restartValues();
  let publicationsProcessed: Array<{ isbn: string; id: string }> = [];
  let errorsPublicationsCreatedOrUpdated: Array<{
    isbn: string;
    errors: readonly GraphQLFormattedError[];
  }> = [];
  for (const publication of publications.value) {
    const result = await createOrUpdatePublication(publication);
    result?.errors
      ? errorsPublicationsCreatedOrUpdated.push({
          isbn: publication.isbn,
          errors: result.errors,
        })
      : publicationsProcessed.push({
          isbn: result.data?.pushPublicationMetadata?.isbn,
          id: result.data?.pushPublicationMetadata?.id ?? '',
        });
  }
  errorsPublicationsCreatingOrUpdating.value =
    errorsPublicationsCreatedOrUpdated;
  publicationsCreatedOrUpdated.value = publicationsProcessed;
}

async function handleUploadedExcel(file: any) {
  restartValues();
  pushingMetadataSuccess.value = false;
  loading.value = true;
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { cellDates: true });
  const excelRawData = XLSX.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]],
    { header: 1, blankrows: false, raw: false }
  );
  parseExcelResult.value = parseExcel(excelRawData);
  if (
    parseExcelResult.value.success &&
    parseExcelResult.value.entries &&
    parseExcelResult.value.entries.length > 0
  ) {
    formattingSubjectsCollectionsAndAuthorFields(
      parseExcelResult.value.entries
    );
  } else {
  }
  loading.value = false;
}

async function createOrUpdatePublication(publication: any) {
  return apolloClient.mutate({
    mutation: pushMetadataMutation,
    variables: {
      namespaceId: namespaceSelectedRef.value?.id ?? '',
      isbn: publication.isbn,
      input: {
        title: publication.title,
        summary: publication.summary,
        author: publication.author,
        subjects: publication.subjects,
        collections: publication.collections,
        isPartOf: publication.series_issn && {
          issn: publication.series_issn,
          name: publication.series_name,
          issueNumber: publication.series_issue,
          type: publication.series_type,
        },
        nature: publication.nature,
        lang: publication.language,
        paperIsbn: publication.paper_isbn,
        published: publication.publication_date,
        publisher: publication.publisher_name,
        hasAdultConsideration: publication.adults_only,
      },
    },
  });
}
</script>
<style scoped>
.right-col-block {
  @apply flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3;
}

.section {
  @apply md:flex md:space-x-5;
}

.left-col-block {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}

.section-right {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}
</style>
