<template>
  <div class="hidden sm:block">
    <dm-button
      class="h-10 w-24"
      :loading="buttonLoading"
      :variant="buttonVariant"
      @click.prevent="(showModal = true), (searchKeyword = '')"
      >{{ buttonLabel }}</dm-button
    >
  </div>
  <div class="sm:hidden">
    <button
      @click="(showModal = true), (searchKeyword = '')"
      type="button"
      class="w-24 inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
    >
      <search-icon class="w-5 h-5" />
    </button>
  </div>

  <!-- Modal Backdrop -->
  <!--
  Visibility
    Closed        'hidden'
    Opened        '' (no class)

  Show/Hide with transitions
    enter         'transition ease-out duration-200'
    enter-start   'transform opacity-0'
    enter-end     'transform opacity-100'
    leave         'transition ease-in duration-100'
    leave-start   'transform opacity-100'
    leave-end     'transform opacity-0'
  -->
  <div
    :class="showModal ? 'visible' : 'invisible'"
    @keydown.esc="showModal = false"
    class="z-90 fixed inset-0 overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-75 p-4 lg:p-8"
    @click.self="showModal = false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="tk-modal-simple"
    aria-hidden="false"
  >
    <!-- Modal Dialog -->
    <!--
    Show/Hide with transitions
      enter         'transition ease-out duration-200'
      enter-start   'transform opacity-0 scale-125'
      enter-end     'transform opacity-100 scale-100'
      leave         'transition ease-in duration-100'
      leave-start   'transform opacity-100 scale-100'
      leave-end     'transform opacity-0 scale-125'
    -->
    <div
      class="flex flex-col rounded shadow-sm bg-white overflow-hidden w-full max-w-md mx-auto"
      role="document"
    >
      <div
        class="py-4 px-5 lg:px-6 w-full bg-gray-50 flex justify-between items-center"
      >
        <h3 class="font-medium">
          <input
            type="text"
            ref="searchBox"
            v-model="searchKeyword"
            @keydown.up="goUp()"
            @keydown.down="goDown()"
            @keydown.enter="emitSelected"
            class="w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            placeholder="Search..."
          />
        </h3>
        <div class="-my-4">
          <button
            @click="showModal = false"
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-transparent text-gray-600 hover:text-gray-400 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:text-gray-600"
          >
            <x-icon class="w-4 h-4 -mx-1" />
          </button>
        </div>
      </div>
      <div class="p-5 lg:p-6 flex-grow w-full">
        <div v-if="loading">Loading...</div>
        <template v-else-if="hits.length > 0">
          <ul>
            <li
              v-for="(hit, index) of hits"
              :key="hit.id"
              class="w-full rounded p-2 m-2 leading-5 text-sm cursor-pointer"
              :class="
                selected == index ? 'bg-indigo-700 text-white' : 'bg-gray-100'
              "
              href=" "
              @click.prevent="emitItem(hit)"
            >
              <div class="font-semibold">
                {{ hit.label }}
              </div>
              <div :class="selected == index ? '' : 'italic text-gray-600'">
                {{ hit.namespace || '' }}
              </div>
            </li>
          </ul>
        </template>
        <template v-else> No results </template>
      </div>
    </div>
    <!-- END Modal Dialog -->
  </div>
  <!-- END Modal Backdrop -->
</template>

<script lang="ts">
import { SearchIcon, XIcon } from '@heroicons/vue/solid';
import DmButton from './Button.vue';
import {
  ref,
  watch,
  nextTick,
  onMounted,
  onUnmounted,
  defineComponent,
  PropType,
  Ref,
} from 'vue';

export interface Hit {
  id: string;
  label: string;
  type?: string;
  namespace?: string;
}

type SearchFunction = (searchKeyword: string) => Promise<Hit[]>;

export default defineComponent({
  name: 'dm-search-dialog',
  emits: ['selected', 'instantMatch'],
  components: {
    SearchIcon,
    XIcon,
    DmButton,
  },
  props: {
    buttonVariant: {
      type: String,
      required: false,
      default: 'default',
    },
    buttonLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Function as PropType<SearchFunction>,
      required: true,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: 'Search...',
    },
    instantMatchRegExp: {
      type: String,
      required: false,
    },
  },
  setup(props, context: any) {
    const searchKeyword = ref<string | null>('');
    const searchBox = ref<HTMLInputElement | null>(null);
    const showModal = ref<boolean>(false);
    const selected = ref(0);
    const loading = ref(false);
    const hits: Ref<Hit[]> = ref([]);
    const shortcut = (ev: KeyboardEvent) => {
      if (ev.key == 'k' && ev.metaKey) {
        showModal.value = !showModal.value;
        ev.preventDefault();
      }
    };

    const instantMatcher = props.instantMatchRegExp
      ? new RegExp(props.instantMatchRegExp)
      : null;

    onMounted(() => {
      window.addEventListener('keydown', shortcut);
    });

    onUnmounted(() => window.removeEventListener('keydown', shortcut));

    watch(searchKeyword, async (newValue) => {
      if (newValue != null) {
        if (instantMatcher && newValue.match(instantMatcher)) {
          showModal.value = false;
          selected.value = 0;
          context.emit('instantMatch', newValue);
        } else {
          loading.value = true;
          selected.value = 0;
          hits.value = await props.search(newValue);
          loading.value = false;
          clampSelectedToHits();
        }
      }
    });

    watch(showModal, async (newValue) => {
      if (newValue) {
        await nextTick();
        if (searchBox.value) {
          searchBox.value.value = '';
          searchBox.value.focus();
        }
      }
    });

    const clampSelectedToHits = () => {
      if (selected.value >= hits.value.length) {
        selected.value = hits.value.length - 1;
      }
    };

    const goUp = () => {
      if (selected.value == 0) {
        selected.value = hits.value.length - 1;
      } else {
        selected.value--;
      }
    };

    const goDown = () => {
      if (selected.value == hits.value.length - 1) {
        selected.value = 0;
      } else {
        selected.value++;
      }
    };

    const emitItem = (entry: Hit) => {
      showModal.value = false;

      context.emit('selected', entry);
    };

    const emitSelected = (entry: any) => {
      if (hits.value.length > 0) {
        emitItem(hits.value[selected.value]);
      }
    };

    return {
      searchKeyword,
      showModal,
      searchBox,
      hits,
      loading,
      goUp,
      goDown,
      emitItem,
      emitSelected,
      selected,
    };
  },
});
</script>
