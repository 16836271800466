<template>
  <div>
    <div class="w-full max-w-4xl">
      <div class="space-y-1">
        <Listbox
          v-model="selectedOptions"
          multiple
          @update:modelValue="emitUpdateSelection"
        >
          <div class="relative">
            <ListboxButton
              class="focus:shadow-outline-indigo flex relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-2 pr-10 text-left text-gray-700 font-medium transition duration-150 ease-in-out focus:border-indigo-300 focus:outline-none sm:text-sm sm:leading-5"
            >
              REPRESENTATION :
              <span class="flex gap-2">
                <span
                  v-for="option in selectedOptions"
                  :key="option"
                  class="flex items-center gap-1 rounded bg-indigo-50 px-2 py-0.5"
                >
                  <span> {{ option }}</span>
                  <svg
                    class="h-4 w-4 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    @click.stop.prevent="removeOption(option)"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <svg
                  class="h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="none"
                  stroke="currentColor"
                >
                  <path
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </ListboxButton>

            <div class="absolute mt-1 w-full rounded-md bg-white shadow-lg">
              <ListboxOptions
                class="shadow-xs max-h-60 overflow-auto rounded-md py-1 text-base leading-6 focus:outline-none sm:text-sm sm:leading-5"
              >
                <ListboxOption
                  v-for="option in representationNatureOptions"
                  :key="option"
                  :value="option"
                  as="template"
                  v-slot="{ active, selected }"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-3 pr-9 focus:outline-none"
                    :class="active ? 'bg-gray-200' : 'text-gray-900'"
                  >
                    <span
                      class="block truncate"
                      :class="{
                        'font-semibold': selected,
                        'font-normal': !selected,
                      }"
                    >
                      {{ option }}
                    </span>
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600"
                    >
                      <svg
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </div>
        </Listbox>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';

const representationNatureOptions = [
  'ACS',
  'LCP',
  'CANTOOK_AUDIO',
  'CLOUDFLARE_IMAGE',
];

const emits = defineEmits(['update-representation-nature-selection']);
const selectedOptions = defineModel<string[]>({ required: true });

function removeOption(option: string) {
  selectedOptions.value = selectedOptions.value.filter(
    (opt: string) => opt !== option
  );
  emitUpdateSelection();
}

const emitUpdateSelection = () => {
  emits('update-representation-nature-selection', selectedOptions.value);
};
</script>
