import Home from './components/Home.vue';

import ManageNamespaces from './components/namespaces/ManageNamespaces.vue';
import ManageRelatedNamespaces from './components/namespaces/related-namespaces/ManageRelatedNamespaces.vue';
import ShowNamespace from './components/namespaces/ShowNamespace.vue';
import NewNamespace from './components/namespaces/NewNamespace.vue';
import CustomIngestionHooks from './components/namespaces/CustomIngestionHooks.vue';

import ManageUserTokens from './components/tokens/ManageUserTokens.vue';

import Periodicals from './components/periodicals/Periodicals.vue';
import PeriodicalSubscriptions from './components/periodicals/PeriodicalSubscriptions.vue';
import Periodical from './components/periodicals/Periodical.vue';
import NewPeriodicalSubscription from './components/periodicals/NewPeriodicalSubscription.vue';
import NewPeriodical from './components/periodicals/NewPeriodical.vue';
import EditPeriodical from './components/periodicals/EditPeriodical.vue';

import NewPublication from './components/publications/NewPublication.vue';
import ManagePublications from './components/publications/ManagePublications.vue';
import ShowPublication from './components/publications/ShowPublication.vue';
import EditPublicationMetadata from './components/publications/edition/EditPublicationMetadata.vue';
import NewBatchPublication from './components/publications/NewBatchPublication.vue';

import ManageShares from './components/publications/ManageShares.vue';
import NewShare from './components/publications/NewShare.vue';

import NewUser from './components/users/NewUser.vue';
import ShowUser from './components/users/ShowUser.vue';
import ManageUsers from './components/users/ManageUsers.vue';

import ShowLicense from './components/licenses/ShowLicense.vue';
import ManageLicenses from './components/licenses/ManageLicenses.vue';
import NewLicense from './components/licenses/NewLicense.vue';

import ManageLoans from './components/loans/ManageLoans.vue';
import ShowLoan from './components/loans/ShowLoan.vue';

import AudioJobs from './components/audio/ManageJobs.vue';

import Changelog from './components/Changelog.vue';
import AdminTools from './components/AdminTools.vue';
/*Marc 21 publications component*/
import Marc21 from './components/publications/Marc21.vue';
/*New order via excel file*/
import NewLicenses from './components/licenses/NewLicenses.vue';
/*New periodical's subscriptions creation*/
import PeriodicalSubscriptionsBulk from './components/periodicals/PeriodicalSubscriptionsBulk.vue';

import ShowFile from './components/files/ShowFile.vue';

import { createRouter, createWebHashHistory } from 'vue-router';

import { Auth0 } from './auth';

const HOME_ROUTE_NAME = 'home';

export const routes = [
  {
    name: HOME_ROUTE_NAME,
    path: '/',
    component: Home,
  },
  {
    name: 'namespaces',
    path: '/namespaces',
    component: ManageNamespaces,
  },
  {
    name: 'related-namespaces',
    path: '/namespaces/:id/related',
    component: ManageRelatedNamespaces,
  },
  {
    name: 'new-namespace',
    path: '/namespaces/new',
    component: NewNamespace,
  },
  {
    name: 'namespace',
    path: '/namespaces/:id',
    component: ShowNamespace,
  },
  {
    name: 'custom-ingestion-hooks',
    path: '/namespaces/custom_ingestion_hooks',
    component: CustomIngestionHooks,
  },
  {
    name: 'new-publication',
    path: '/publications/new',
    component: NewPublication,
  },
  {
    name: 'new-publication-share',
    path: '/publications/:id/shares/new',
    component: NewShare,
  },
  {
    name: 'publication-shares',
    path: '/publications/:id/shares',
    component: ManageShares,
  },
  {
    name: 'publications',
    path: '/publications',
    component: ManagePublications,
  },
  {
    name: 'publications-metadata-edit',
    path: '/publications/:id/metadata/edit',
    component: EditPublicationMetadata,
  },
  {
    name: 'new_batch_publication',
    path: '/publications/batch',
    component: NewBatchPublication,
  },
  {
    name: 'publication',
    path: '/publications/:id',
    component: ShowPublication,
  },
  {
    name: 'new_publication_metadata',
    path: '/publications/new_publication_metadata',
    props: (route: any) => ({
      existing: true,
      isbn: route.query.isbn,
      namespaceId: route.query.namespace_id,
      namespace: route.query.namespace,
    }),
    component: NewPublication,
  },
  {
    name: 'new_publication',
    path: '/publications/new',
    component: NewPublication,
  },
  {
    name: 'new_user',
    path: '/users/new',
    component: NewUser,
  },
  {
    name: 'user',
    path: '/users/:id',
    component: ShowUser,
  },
  {
    name: 'users',
    path: '/users',
    component: ManageUsers,
  },
  {
    name: 'user_tokens',
    path: '/usertokens',
    component: ManageUserTokens,
  },
  {
    name: 'licenses',
    path: '/licenses',
    component: ManageLicenses,
  },
  {
    name: 'new_license',
    path: '/publications/:id/licenses/new',
    component: NewLicense,
  },
  {
    name: 'license',
    path: '/licenses/:id',
    component: ShowLicense,
  },
  {
    name: 'loans',
    path: '/loans',
    component: ManageLoans,
  },
  {
    name: 'loan',
    path: '/loans/:id',
    component: ShowLoan,
  },
  {
    name: 'periodicals',
    path: '/periodicals',
    component: Periodicals,
  },
  {
    name: 'periodicalSubscriptions',
    path: '/periodicalSubscriptions',
    component: PeriodicalSubscriptions,
  },
  {
    name: 'audio-jobs',
    path: '/audio/jobs',
    component: AudioJobs,
  },
  {
    name: 'new_periodical',
    path: '/periodicals/new',
    component: NewPeriodical,
  },
  {
    name: 'new_periodical_subscription',
    path: '/periodicals/:id/subscriptions/new',
    component: NewPeriodicalSubscription,
  },
  {
    name: 'periodical',
    path: '/periodicals/:id',
    component: Periodical,
  },
  {
    name: 'periodicals-edit',
    path: '/periodicals/:id/edit',
    component: EditPeriodical,
  },
  {
    name: 'bulk-periodical-subscription-creation',
    path: '/periodicalSubscriptions/bulk',
    component: PeriodicalSubscriptionsBulk,
  },

  {
    name: 'changelog',
    path: '/changelog',
    component: Changelog,
  },
  {
    name: 'marc21',
    path: '/publications/marc21',
    component: Marc21,
  },
  {
    name: 'NewBatchLicenses',
    path: '/licenses/NewBatchLicenses',
    component: NewLicenses,
  },
  {
    name: 'file',
    path: '/files/:id',
    component: ShowFile,
  },
  {
    name: 'admin-tools',
    path: '/admin',
    component: AdminTools,
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Guard everything except the main page.
router.beforeEach((to, from, next) => {
  return to.name != HOME_ROUTE_NAME
    ? (Auth0.routeGuard as Function)(to, from, next)
    : next();
});
