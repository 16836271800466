<template>
  <progress-top-bar />
  <div v-if="loading">Loading</div>
  <login-prompt v-else-if="!isAuthenticated" />
  <div
    v-else
    class="flex flex-col mx-auto w-full min-h-screen min-w-[320px] bg-gray-100 dark:text-gray-100 dark:bg-gray-900"
    :class="{
      'lg:pl-64': desktopSidebarOpen,
    }"
  >
    <page-header @sidebar-desktop-status="getDesktopSidebarStatus($event)" />
    <!-- Page Content -->
    <main id="page-content" class="flex flex-auto flex-col max-w-full">
      <!-- Page Section -->
      <div class="max-w-10xl mx-auto p-4 lg:p-8 w-full">
        <router-view />
      </div>
      <!-- END Page Section -->
    </main>
    <!-- END Page Content -->
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useAuth } from './auth';
import LoginPrompt from './components/LoginPrompt.vue';
import Header from './components/Header.vue';
import ProgressTopBar from './components/ProgressTopBar.vue';

const PageHeader = Header;

const { isAuthenticated, loading } = useAuth();

const desktopSidebarOpen = ref(true);
const getDesktopSidebarStatus = (desktopSidebarStatus: boolean) => {
  desktopSidebarOpen.value = desktopSidebarStatus;
};
</script>

<style></style>
