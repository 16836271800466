import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

interface FieldValidation<Type> {
  value?: Type;
  error?: string;
}

export function optionalStringValidator(
  value: any
): FieldValidation<string | null> {
  if (value == null || value.trim().length === 0) {
    return { value: null };
  }

  if (typeof value != 'string') {
    return {
      error: `Empty field`,
    };
  }

  return { value };
}

export function optionalNumberValidator(
  value: any
): FieldValidation<number | null> {
  if (value == null || value.trim().length === 0) {
    return { value: null };
  }
  if (isNaN(value)) {
    return {
      error: `Unexpected field data value (expected: numerical):${value}`,
    };
  }
  if (value === '0') {
    return { error: 'The value must be greater than 0.' };
  }
  return { value: Number(value) };
}

export function nonEmptyNumberValidator(value: any): FieldValidation<number> {
  if (value == null || value.trim().length === 0) {
    return { error: `Empty field` };
  }
  return !isNaN(value)
    ? { value: Number(value) }
    : {
        error: `Unexpected field data value (expected: numerical):${value}`,
      };
}

export function nonEmptyDateRightFormatValidator(
  value: any,
  format: string = 'DD/MM/YYYY'
): FieldValidation<Date> {
  if (value == null || value.trim().length === 0) {
    return { error: 'Empty field' };
  }
  if (typeof value == 'string') {
    dayjs.extend(customParseFormat);
    const parsedDate = dayjs(value, format, true);
    if (!parsedDate.isValid()) {
      return {
        error: `Invalid date format, value:'${value}'`,
      };
    } else {
      return { value: parsedDate.toDate() };
    }
  } else if (value instanceof Date) {
    return { value };
  } else {
    return {
      error: `Unexpected field data type (expected Date or string).`,
    };
  }
}

export function nonEmptyIsbnAndRightFormatValidator(
  value: any
): FieldValidation<string> {
  if (value == null || value.trim().length === 0) {
    return { error: `Empty field` };
  }
  if (typeof value != 'string') {
    return {
      error: `Invalid isbn format, value: '${value}'`,
    };
  } else {
    value = value.replace(/\s/g, '').toLowerCase();
    if (!new RegExp(/^[a-z0-9-]+$/).test(value)) {
      return {
        error: `Invalid character in isbn, just letters, number and dashes. Value:'${value}'`,
      };
    } else {
      return { value: value };
    }
  }
}

export function nonEmptyLicenseModalityValitador(
  value: any
): FieldValidation<string> {
  if (value == null || value.trim().length === 0) {
    return { error: `Empty field` };
  }
  if (
    typeof value != 'string' ||
    !(
      value.toLowerCase() === 'general use' ||
      value.toLowerCase() === 'reading club'
    )
  ) {
    return {
      error: `License modality must be general use or reading club, value: ${value}`,
    };
  }
  value = value.toLowerCase();
  return { value: value.charAt(0).toUpperCase() + value.slice(1) };
}

export function numberOfLicensesAndRightQuantityValidator(
  value: any
): FieldValidation<number> {
  const MAX_LICENSES_ALLOWED = 100;
  if (value == null || value.trim().length === 0) {
    return { value: 1 };
  }

  if (value == '0') {
    return {
      error:
        'The value must be greater than 0 or leave it empty to create 1 license.',
    };
  }

  if (isNaN(value)) {
    return {
      error: `Unexpected field data value (expected: numerical): ${value}`,
    };
  }
  if (value > MAX_LICENSES_ALLOWED) {
    return {
      error: `The maximun number of licenses allowed to be created at the same time is ${MAX_LICENSES_ALLOWED} but ${value} was specified.`,
    };
  }
  return { value: Number(value) };
}
