<template>
  <button
    v-if="isSupported && props.dataToCopy"
    @click="copy(props.dataToCopy)"
    type="button"
    class="inline-flex space-x-2 border font-semibold rounded-lg px-2 py-1 leading-5 text-sm border-gray-400 bg-gray-200 text-gray-600 hover:border-gray-300 hover:text-gray-800/50 hover:shadow-sm"
  >
    <svg
      class="hi-solid hi-clipboard-copy inline-block w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z" />
      <path
        d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
      />
    </svg>
    {{ copied ? 'Copied' : 'Copy' }}
  </button>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core';

const props = defineProps({
  dataToCopy: { type: String, required: true },
});

const { copy, copied, isSupported } = useClipboard({
  source: props.dataToCopy,
  copiedDuring: 5000,
});
</script>
