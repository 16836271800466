<template>
  <message-confirmation
    :isHidden="hideAlertMessage"
    :bodyInfoMessage="messageBody"
    buttonConfirmMessage="Yes, create/update"
    variant="alert"
    @answerUser="answerAlertMessage"
  ></message-confirmation>
  <h2 class="page-title">Marc21 Upload</h2>
  <div class="spacey-8 relative">
    <div class="section">
      <!-- Target Info -->
      <div class="left-col-block">
        <p class="text-gray-500 text-sm mb-5">
          Select first a namespace and then a marc21 file.
        </p>
      </div>
      <div
        class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
      >
        <div class="p-5 lg:p-6 flex-grow w-full">
          <div class="space-y-1">
            <div>
              <namespace-selector v-model="namespaceSelectedRef" />
            </div>
            <div class="flex-grow w-full flex justify-end">
              <div class="grid justify-items-center" v-if="!loadingNamespace">
                <file-upload
                  @uploaded="handleUploadedMarc"
                  accept=".mrc"
                  btsize="m"
                  class="mt-3"
                  text="Select Marc 21 file."
                />
              </div>
            </div>
            <div class="mt-4">
              <error-display
                class="mt-4"
                :error="fileError"
                headerMessage="There are some errors found in the file"
                footerMessage="Note: Format accepted .mrc"
              ></error-display>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white mt-4"
        v-if="publications && publications.length > 0"
      >
        <Table
          center-header
          :headers="
            (errors && errors.some((arrayInside) => arrayInside.length > 0)
              ? ['Errors']
              : []
            ).concat([
              'Isbn',
              'Author',
              'Nature',
              'Title',
              'Page count',
              'Summary',
              'PaperIsbn',
              'Published',
              'Language',
              'Publisher',
              'Subjects',
              'Audiences',
              'Age range audiences',
              'Description',
              'Subtitle',
              'Contributors',
              'Adults only',
            ])
          "
        >
          <tr
            v-for="(publication, index) of publications"
            :key="publication.isbn"
            class="border-b border-gray-200"
          >
            <td
              v-if="
                errors && errors.some((arrayInside) => arrayInside.length > 0)
              "
              class="p-3 bg-red-100 text-center"
            >
              <p
                class="font-medium"
                v-if="errors[index].length > 0"
                v-for="error in errors[index]"
              >
                {{ error }}
              </p>
            </td>
            <td class="p-3 font-semibold text-sm text-center">
              <p class="font-medium">
                {{ publication.isbn }}
              </p>
            </td>

            <td class="p-3 text-center">
              {{ publication.author?.name }}
            </td>
            <td class="p-3 text-center">
              {{ publication.nature }}
            </td>
            <td class="p-3 text-center">
              {{ publication.title }}
            </td>
            <td class="p-3 text-center">
              {{ publication.pageCount }}
            </td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{ publication.summary }}
            </td>
            <td class="p-3 text-center">
              {{ publication.paperIsbn }}
            </td>
            <td class="p-3 text-center">
              {{ publication.published }}
            </td>
            <td class="p-3 text-center">
              {{ publication.lang }}
            </td>
            <td class="p-3 text-center">
              {{ publication.publisher }}
            </td>
            <td
              class="p-3 text-center"
              v-if="publication.subjects && publication.subjects.length > 0"
            >
              <p v-for="subject in publication.subjects" :key="subject.code">
                <span> Scheme: {{ subject.scheme }} </span>
                <span> Code: {{ subject.code }} </span>
              </p>
            </td>
            <td
              class="p-3 text-center"
              v-if="publication.audiences && publication.audiences.length > 0"
            >
              <p
                v-for="audience in publication.audiences"
                :key="audience.code?.toString()"
              >
                {{ audience.code }}
              </p>
            </td>
            <td class="p-3 text-center" v-else></td>
            <td
              class="p-3 text-center"
              v-if="
                publication.ageRangeAudiences &&
                publication.ageRangeAudiences.length > 0
              "
            >
              <p v-for="ageRange in publication.ageRangeAudiences">
                <span>From: {{ ageRange.from }} </span>
                <span> To: {{ ageRange.to }} </span>
              </p>
            </td>
            <td class="p-3" v-else></td>
            <td class="p-3 text-center overflow-hidden text-ellipsis max-w-xs">
              {{ publication.description }}
            </td>
            <td class="p-3 text-center">
              {{ publication.subtitle }}
            </td>
            <td
              class="p-3 text-center"
              v-if="
                publication.contributors && publication.contributors.length > 0
              "
            >
              <p v-for="contributor in publication.contributors">
                <span>Role: {{ contributor.role }} </span>
                <span> Name: {{ contributor.person.name }} </span>
              </p>
            </td>
            <td class="p-3 text-center" v-else></td>
            <td class="p-3 text-center">
              {{ publication.hasAdultConsideration ? 'Yes' : 'No' }}
            </td>
          </tr>
        </Table>
      </div>
      <div class="flex-grow w-full flex justify-end margin-space my-4">
        <div
          class="grid justify-items-center"
          v-if="
            publications &&
            !loading &&
            publications.length > 0 &&
            !errors?.some((arrayInside) => arrayInside.length > 0)
          "
        >
          <dm-button
            id="create-upload-publications"
            variant="danger"
            @click.prevent="clickButtonCreateUpdate()"
            size="xL"
            >create/update Publications</dm-button
          >
        </div>
        <div class="grid justify-items-center" v-else-if="loading">
          <span> Loading... </span>
        </div>
      </div>
      <div>
        <div
          class="border border-gray-200 rounded overflow-x-auto min-w-full bg-red-100 mt-4"
          v-if="
            errorsPublicationsConsulted &&
            errorsPublicationsConsulted.length > 0
          "
        >
          <ul v-for="errorConsultedPublication in errorsPublicationsConsulted">
            <li>{{ errorConsultedPublication.isbn }}</li>
            <ul v-for="errorGraph in errorConsultedPublication.errors">
              <li>
                {{ errorGraph }}
              </li>
            </ul>
          </ul>
        </div>
      </div>
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white repor mb-4 pl-3 pb-3"
        v-if="
          (publicationsUpdated && publicationsUpdated.length > 0) ||
          (errorsPublicationsUpdated && errorsPublicationsUpdated.length > 0)
        "
      >
        <h4 class="font-semibold py-3">Updated</h4>
        <ul
          class="text-sm space-y-2"
          v-if="publicationsUpdated && publicationsUpdated.length > 0"
        >
          <li
            v-for="publcationUpdated in publicationsUpdated"
            :key="publcationUpdated.isbn"
          >
            <div
              class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-green-700 bg-green-200"
            >
              Success
            </div>
            <router-link
              class="ml-3"
              :to="{
                name: 'publication',
                params: { id: publcationUpdated.id },
              }"
              target="_blank"
              >{{ publcationUpdated.isbn }}</router-link
            >
          </li>
        </ul>
        <ul
          class="text-sm space-y-2"
          v-if="
            errorsPublicationsUpdated && errorsPublicationsUpdated.length > 0
          "
        >
          <li v-for="isbn in errorsPublicationsUpdated" :key="isbn">
            <div
              class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-red-700 bg-red-200"
            >
              Failed
            </div>
            {{ isbn }}
          </li>
        </ul>
      </div>
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white repor mb-4 pl-3 pb-3"
        v-if="
          (publicationsCreated && publicationsCreated.length > 0) ||
          (errorsPublicationsCreated && errorsPublicationsCreated.length > 0)
        "
      >
        <h4 class="font-semibold py-3">Created</h4>
        <ul
          class="text-sm space-y-2"
          v-if="publicationsCreated && publicationsCreated.length > 0"
        >
          <li
            v-for="publicationCreated in publicationsCreated"
            :key="publicationCreated.isbn"
          >
            <div
              class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-green-700 bg-green-200"
            >
              Success
            </div>
            <router-link
              class="ml-3"
              :to="{
                name: 'publication',
                params: { id: publicationCreated.id },
              }"
              target="_blank"
              >{{ publicationCreated.isbn }}</router-link
            >
          </li>
        </ul>
        <ul
          class="text-sm space-y-2"
          v-if="
            errorsPublicationsCreated && errorsPublicationsCreated.length > 0
          "
        >
          <li v-for="isbn in errorsPublicationsCreated" :key="isbn">
            <div
              class="font-semibold inline-flex px-2 py-1 leading-4 mr-1 text-xs rounded text-red-700 bg-red-200"
            >
              Failed
            </div>
            {{ isbn }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject, Ref, ref } from 'vue';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { DefaultApolloClient, useQuery } from '@vue/apollo-composable';
import { GraphQLError } from 'graphql';
import { computed } from '@vue/reactivity';

import { graphql } from '../../__generated__/gql';
import {
  PublicationMetadata,
  PublicationMetadataInput,
} from '../../__generated__/graphql';

import { marc21Format, registerOfMarc21File } from '../../marc21';
import FileUpload from '../base/FileUpload.vue';
import MessageConfirmation from '../base/DialogMessage.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import Table from '../base/Table.vue';

interface Publication extends PublicationMetadata {
  isbn: string;
}

export default defineComponent({
  name: 'Marc21',
  data() {
    return {};
  },

  components: {
    FileUpload,
    MessageConfirmation,
    ErrorDisplay,
    NamespaceSelector,
    Table,
  },

  setup() {
    const loading = ref(false);
    const hideAlertMessage = ref(true);
    const apolloClient = inject(
      DefaultApolloClient
    ) as ApolloClient<NormalizedCacheObject>;
    const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
      ref(null);
    const fileError = ref();
    const { loading: loadingNamespace } = useQuery(
      graphql(`
        query Marc21ViewerQuery {
          viewer {
            namespaces {
              name
              id
            }
          }
        }
      `),
      null,
      {
        fetchPolicy: 'cache-and-network',
      }
    );
    const messageBody = computed(
      () =>
        'Are you sure you want to create/uptade ' +
        publications.value.length +
        ' publications?'
    );
    /*Updated Publications***/
    const publicationsUpdated: Ref<Array<{ isbn: string; id: string }>> = ref(
      []
    );
    /*Errors in uploaded publications*/
    const errorsPublicationsUpdated: Ref<Array<string>> = ref([]);
    /*Created Publications*/
    const publicationsCreated: Ref<Array<{ isbn: string; id: string }>> = ref(
      []
    );
    /*Errors in created publications*/
    const errorsPublicationsCreated: Ref<Array<string>> = ref([]);
    /*Errors in consulting publications*/
    const errorsPublicationsConsulted: Ref<
      Array<{ isbn: string; errors: Array<GraphQLError> }>
    > = ref([]);
    /*Publications */
    const publications: Ref<Array<Publication>> = ref([]);
    /*Errors in publicaction's values*/
    const errors: Ref<Array<Array<string>>> = ref([]);
    /*Queries graphql */
    const _PUBLICATION_MARC_21_FRAGMENT = graphql(`
      fragment PublicationMarc21 on Publication {
        id
        isbn
        metadata {
          nature
          title
          subtitle
          author {
            name
          }
          summary
          lang
          publisher
          paperIsbn
          description
          contributors {
            role
            person {
              name
            }
          }
          published
          hasAdultConsideration
        }
      }
    `);
    const searchPublicationbyIsbnQuery = graphql(`
      query publicationByISBN($isbn: PublicationIdentifier!) {
        publication: publicationByISBN(isbn: $isbn) {
          id
          metadata {
            contributors {
              role
              person {
                name
              }
            }
            nature
            paperIsbn
            hasAdultConsideration
          }
        }
      }
    `);
    const updatePublicationQuery = graphql(`
      mutation updateMetadataForMarc(
        $id: ID!
        $changes: UpdatePublicationMetadataChanges!
      ) {
        updatePublicationMetadata(id: $id, changes: $changes) {
          success
          publication {
            ...PublicationMarc21
          }
        }
      }
    `);
    const setMetadataPublicationQuery = graphql(`
      mutation pushMetadata(
        $namespaceId: ID!
        $input: PublicationMetadataInput!
        $isbn: PublicationIdentifier!
      ) {
        pushPublicationMetadata(
          namespaceId: $namespaceId
          isbn: $isbn
          input: $input
        ) {
          id
          isbn
        }
      }
    `);
    const createPublicationQuery = graphql(`
      mutation createPublicationFromMetadata(
        $namespaceId: ID!
        $isbn: PublicationIdentifier!
        $input: PublicationMetadataInput!
      ) {
        createPublicationFromMetadata(
          namespaceId: $namespaceId
          isbn: $isbn
          input: $input
        ) {
          success
          publication {
            id
            isbn
          }
        }
      }
    `);
    /*Function getting publication data */
    async function getPublication(isbn: string): Promise<any> {
      return apolloClient.query({
        query: searchPublicationbyIsbnQuery,
        variables: { isbn: isbn },
        fetchPolicy: 'network-only',
      });
    }
    /*Function updating a publication*/
    async function updatePublication(
      publication: Publication,
      id: string
    ): Promise<any> {
      return apolloClient.mutate({
        mutation: updatePublicationQuery,
        variables: {
          id: id,
          changes: {
            pageCount: publication.pageCount,
            subjects: publication.subjects,
            ageRangeAudiences: publication.ageRangeAudiences,
            audiences: publication.audiences.map((audience) => ({
              code: audience.code!,
            })),
          },
        },
      });
    }
    /*Function updating a publication*/
    async function updatePublicationsetMetadata(
      publication: Publication
    ): Promise<any> {
      const namespaceId = namespaceSelectedRef.value?.id;
      if (!namespaceId) {
        return;
      }

      let publicationReactive: PublicationMetadataInput = {
        ...publication,
        audiences: publication.audiences.map((audience) => ({
          code: audience.code!,
        })),
      };
      // @ts-ignore
      delete publicationReactive.isbn;
      return apolloClient.mutate({
        mutation: setMetadataPublicationQuery,
        variables: {
          namespaceId,
          isbn: publication.isbn,
          input: publicationReactive,
        },
      });
    }
    /*Function creating a publication*/
    async function createPublication(publication: Publication): Promise<any> {
      let publicationReactive: PublicationMetadataInput = {
        ...publication,
        audiences: publication.audiences.map((audience) => ({
          code: audience.code!,
        })),
      };
      // @ts-ignore
      delete publicationReactive.isbn;
      return apolloClient.mutate({
        mutation: createPublicationQuery,
        variables: {
          namespaceId: namespaceSelectedRef.value?.id ?? '',
          isbn: publication.isbn,
          input: publicationReactive,
        },
      });
    }
    /*Function updating all the values in the table from the updated publications*/
    function updatedData(value: any) {
      let publication = publications.value.find(
        (publicationFound) => publicationFound.isbn == value.publication.isbn
      );
      if (publication) {
        if (value.publication.metadata.contributors) {
          value.publication.metadata.contributors = getContributors(
            value.publication.metadata.contributors
          );
        }
        Object.assign(publication, value.publication.metadata);
      }
      return { isbn: value.publication.isbn, id: value.publication.id };
    }
    /*Function cleaning contributors from the query's result*/
    function getContributors(contributorsArray: any) {
      return contributorsArray.reduce(
        (
          contributors: { role: any; person: { name: string } }[],
          actualContributor: {
            role: any;
            person: { __typename: string; name: string };
          }
        ): { role: any; person: { name: string } }[] => {
          if (actualContributor.role != 'AUTHOR') {
            contributors.push({
              role: actualContributor.role,
              person: actualContributor.person,
            });
          }
          return contributors;
        },
        []
      );
    }
    /*Function cleaning all the result's values*/
    function restartValues() {
      publicationsUpdated.value =
        publicationsCreated.value =
        errorsPublicationsCreated.value =
        errorsPublicationsUpdated.value =
          [];
    }
    function clickButtonCreateUpdate() {
      loading.value = true;
      hideAlertMessage.value = false;
    }
    /*Functions performing when users click on the update/create button*/
    async function sendPublications() {
      restartValues();
      let publicationsUpdatedArray: Array<{ isbn: string; id: string }> = [];
      let errorsPublicationsUpdatedArray: Array<string> = [];
      let publicationsCreatedArray: Array<{ isbn: string; id: string }> = [];
      let errorsPublicationsCreatedArray: Array<string> = [];
      let errorsPublicationsConsultedArray: Array<{
        isbn: string;
        errors: Array<GraphQLError>;
      }> = [];
      for (const publication of publications.value) {
        const result = await getPublication(publication.isbn);
        /*errors getting publications*/
        if (result.errors) {
          errorsPublicationsConsultedArray.push({
            isbn: publication.isbn,
            errors: result.errors,
          });
        } else {
          if (result.data.publication != null) {
            if (result.data.publication.metadata != null) {
              /*With metadata*/
              const resultUpdating = await updatePublication(
                publication,
                result.data.publication.id
              );

              if (resultUpdating?.data?.updatePublicationMetadata?.success) {
                publicationsUpdatedArray.push(
                  updatedData(resultUpdating.data.updatePublicationMetadata)
                );
              } else {
                errorsPublicationsUpdatedArray.push(publication.isbn);
              }
            } else {
              /* With cover or content but not metadata*/
              const resultPush = await updatePublicationsetMetadata(
                publication
              );
              if (resultPush.errors) {
                errorsPublicationsCreatedArray.push(publication.isbn);
              } else {
                publicationsCreatedArray.push({
                  isbn: resultPush.data.pushPublicationMetadata.isbn,
                  id: resultPush.data.pushPublicationMetadata.id,
                });
              }
            }
          } else {
            /* The publication does not exist at all  */
            const resultCreating = await createPublication(publication);
            if (resultCreating?.data?.createPublicationFromMetadata?.success) {
              publicationsCreatedArray.push({
                isbn: resultCreating.data.createPublicationFromMetadata
                  .publication.isbn,
                id: resultCreating.data.createPublicationFromMetadata
                  .publication.id,
              });
            } else {
              errorsPublicationsCreatedArray.push(publication.isbn);
            }
          }
        }
        publicationsUpdated.value = publicationsUpdatedArray;
        errorsPublicationsUpdated.value = errorsPublicationsUpdatedArray;
        publicationsCreated.value = publicationsCreatedArray;
        errorsPublicationsCreated.value = errorsPublicationsCreatedArray;
        errorsPublicationsConsulted.value = errorsPublicationsConsultedArray;
        loading.value = false;
      }
    }
    /*Readingg and printing method that reads the file's values */
    const handleUploadedMarc = async (file: any) => {
      restartValues();
      loading.value = true;
      publications.value = [];
      errors.value = [];
      let publicationsArray: Array<Publication> = [];
      let errorsForPublications: Array<any> = [];
      var dataFieldTokens = await registerOfMarc21File(file);
      if (dataFieldTokens == null) {
        loading.value = false;
        fileError.value =
          'Try uploading it again or contacting your administrator';
      } else {
        fileError.value = null;
        [publicationsArray, errorsForPublications] =
          marc21Format(dataFieldTokens);
        publications.value = publicationsArray;
        errors.value = errorsForPublications;
        loading.value = false;
      }
    };
    /*Confirm performed action*/
    const answerAlertMessage = async (answer: boolean) => {
      hideAlertMessage.value = true;
      if (answer) {
        await sendPublications();
      } else {
        restartValues();
      }
      loading.value = false;
    };
    return {
      handleUploadedMarc,
      publications,
      errors,
      sendPublications,
      clickButtonCreateUpdate,
      loading,
      loadingNamespace,
      publicationsUpdated,
      errorsPublicationsUpdated,
      publicationsCreated,
      errorsPublicationsCreated,
      namespaceSelectedRef,
      errorsPublicationsConsulted,
      answerAlertMessage,
      hideAlertMessage,
      messageBody,
      fileError,
    };
  },
});
</script>
<style scoped>
.right-col-block {
  @apply flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3;
}
.section {
  @apply md:flex md:space-x-5;
}
.left-col-block {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}
.section-right {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}
</style>
