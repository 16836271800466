<template>
  <div v-show="isLoading" class="progress-bar">
    <span class="bar">
      <span class="progress" />
    </span>
  </div>
</template>

<script setup lang="ts">
import {
  useGlobalQueryLoading,
  useGlobalMutationLoading,
} from '@vue/apollo-composable';
import { computed, inject, watch } from 'vue';
import { GLOBAL_LOADING_REF } from '../main';

const globalLoading = inject(GLOBAL_LOADING_REF);

const isLoading = computed(
  () =>
    useGlobalQueryLoading().value ||
    useGlobalMutationLoading().value ||
    globalLoading?.value
);
</script>

<style scoped>
@keyframes loader {
  0% {
    width: 0;
    left: 0;
  }

  10% {
    width: 25%;
    left: 12%;
  }

  50% {
    width: 50%;
    left: 25%;
  }

  90% {
    width: 25%;
    left: 62%;
  }

  100% {
    width: 0;
    left: 100%;
  }
}

.progress-bar {
  @apply w-full;
  @apply overflow-hidden;
}

.progress-bar span {
  @apply block;
}

.bar {
  @apply bg-transparent;
}

.progress {
  @apply bg-emerald-400;
  @apply p-0.5;
  @apply relative;
  animation: loader 2s linear infinite;
}

.progress-bar {
  @apply h-full;
  @apply z-100;
  @apply sticky;
  @apply top-0;
}
</style>
