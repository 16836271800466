<template>
  <div v-if="!loading" class="max-w-full">
    <select v-model="selectedOption" class="w-full">
      <option v-for="user in users" :key="user.id ?? 'NONE'" :value="user">
        {{ user.name }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';
import { graphql } from '../../__generated__/gql';

const props = defineProps<{
  noneOption?: string | null;
}>();

type User = {
  id: string | null;
  name: string;
};

const { result, loading, error } = useQuery(
  graphql(`
    query GetUsersForSelect {
      users(first: 999) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)
);

const users: ComputedRef<User[]> = computed(() => {
  const queried: User[] =
    result?.value?.users.edges.map((edge) => edge.node) ?? [];
  if (props.noneOption) {
    queried.unshift({ id: null, name: props.noneOption });
  }
  return queried;
});

const selectedOption = defineModel<User | null>();
</script>
