<template>
  <h2 class="page-title">New License</h2>
  <div v-if="loading || createLoading || createOpenAccessLoading">Loading</div>
  <div v-else-if="createError">
    <error-display
      :error="createError"
      header-message="Failed to create license."
    />
  </div>
  <div v-else class="space-y-8">
    <form
      @submit="isOpenAccess ? createNewOpenAccessLicense() : createNewLicense()"
      class="space-y-6"
    >
      <!-- Target -->
      <div class="md:flex md:space-x-5">
        <!-- Target Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Target</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">The target of the license.</p>
        </div>
        <!-- END Target Info -->

        <!-- Card: Target -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <!-- Card Body: Target -->
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium">Publication</label>
              <h2 class="text-xl font-semibold">
                {{
                  publication?.metadata?.title ||
                  `Untitled:  ${publication?.isbn}`
                }}
              </h2>
            </div>

            <div class="space-y-1 mt-1">
              <label class="font-medium" for="tk-inputs-default-select"
                >Namespace</label
              >
              <namespace-selector v-model="namespaceSelectedRef" />
            </div>

            <div class="space-y-1 mt-1">
              <label class="font-medium" for="tk-form-elements-open-access"
                >Open-Access</label
              >
              <input
                v-model="isOpenAccess"
                class="block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="checkbox"
                id="tk-form-elements-open-access"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Terms -->
      <div v-if="!isOpenAccess" class="md:flex md:space-x-5">
        <!-- Terms Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Terms</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">
            The terms of the license. You can leave fields empty and the license
            will not be limited on those aspects.
          </p>
        </div>
        <!-- END Terms Info -->

        <!-- Card: Terms -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <!-- Card Body: Terms -->
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="tk-form-elements-expires"
                >Expires</label
              >
              <date-input
                v-model="expires"
                class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                id="tk-form-elements-expires"
                placeholder="Enter the expiration date or leave empty (YYYY-MM-DD)"
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="tk-form-elements-concurrency"
                >Concurrency</label
              >
              <input
                v-model.number="concurrency"
                class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="number"
                id="tk-form-elements-concurrency"
                placeholder="Enter the number of concurrent uses"
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="tk-form-elements-checkouts"
                >Checkouts</label
              >
              <input
                v-model.number="checkouts"
                class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="number"
                id="tk-form-elements-checkouts"
                placeholder="Enter the number of total checkouts"
              />
            </div>

            <div class="space-y-1">
              <duration-input
                id="tk-form-elements-max-loan-duration"
                v-model="maxLoanDuration"
                placeholder="1d 20h 30m"
                label="Max loan duration"
              ></duration-input>
            </div>

            <div class="space-y-1">
              <label
                class="font-medium"
                for="tk-form-elements-max-loan-duration"
                >Cap loan expiration to license expiration.</label
              >
              <input
                v-model.number="capLoanExpirationToLicense"
                class="block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="checkbox"
                id="tk-form-elements-max-loan-duration"
                placeholder="blah blah"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Custom Attributes -->
      <div class="md:flex md:space-x-5">
        <!-- Custom Attributes Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Custom Attributes</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">
            Add custom attributes as required. Attributes without a key field
            will be ignored.
          </p>
        </div>
        <!-- Card: Custom Attributes -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md: md:w-2/3"
        >
          <!-- Card Body: Custom Attributes -->
          <div class="p-5 lg:p-6 flex-grow w-full flex flex-col">
            <div
              v-for="(customAttribute, i) in customAttributes"
              v-bind:key="i"
              class="flex flex-row w-full"
            >
              <input
                class="flex-grow block border border-gray-200 rounded px-3 py-2 mr-4 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="text"
                placeholder="Key"
                v-model="customAttribute.key"
              />
              <input
                class="flex-grow block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="text"
                placeholder="Value"
                v-model="customAttribute.value"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="md:flex md:flex-row md:space-x-5">
        <div class="md:flex-none md:w-1/3 text-center md:text-left"></div>
        <div class="flex flex-col rounded shadow-sm overflow-hidden md:w-2/3">
          <div class="space-y-1">
            <dm-button type="submit" :enabled="validForm">
              {{
                isOpenAccess ? 'Create Open Access License' : 'Create License'
              }}
            </dm-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import DateInput from '../base/DateInput.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import DurationInput from '../base/DurationInput.vue';

const route = useRoute();
const router = useRouter();

const publicationId = Array.isArray(route.params.id)
  ? route.params.id[0]
  : route.params.id;

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const expires = ref('');
const concurrency = ref<number | null | undefined>();
const checkouts = ref<number | null | undefined>();
const maxLoanDuration = ref<number | null | undefined>();
const capLoanExpirationToLicense = ref<boolean | undefined>();
const createError: Ref<string | null> = ref(null);
const isOpenAccess: Ref<boolean> = ref(false);

watch(isOpenAccess, (value: boolean) => {
  if (value) {
    expires.value = '';
    concurrency.value = null;
    checkouts.value = null;
    maxLoanDuration.value = null;
    capLoanExpirationToLicense.value = false;
  }
});

const customAttributes = ref<{ key: string; value: string }[]>([
  { key: '', value: '' },
  { key: '', value: '' },
  { key: '', value: '' },
]);

const validForm = computed(
  () =>
    namespaceSelectedRef.value?.id &&
    concurrency.value !== 0 &&
    checkouts.value !== 0 &&
    (expires.value == '' ||
      new Date(expires.value).toISOString() >= new Date().toISOString())
);

const { result, loading } = useQuery(
  graphql(`
    query NewLicenseInitialData($publicationId: ID!) {
      publication(id: $publicationId) {
        id
        isbn

        metadata {
          title
        }

        cover {
          href
        }
      }
    }
  `),
  { publicationId }
);

const publication = computed(() => {
  return result.value?.publication ?? null;
});

const {
  mutate: createNewLicense,
  loading: createLoading,
  onDone: onCreateDone,
  onError: onCreateError,
} = useMutation(
  graphql(`
    mutation CreateLicense(
      $publicationId: ID!
      $targetId: ID
      $namespaceId: ID!
      $checkouts: Int
      $concurrency: Int
      $expires: DateTime
      $maxLoanDuration: Int
      $capLoanExpirationToLicense: Boolean
      $customAttributes: [AttributeInput!]
    ) {
      createLicense(
        input: {
          entityId: $publicationId
          target: $targetId
          namespaceId: $namespaceId
          source: "frontend"
          protection: { copy: 0, devices: 6, print: 0, tts: true }
          terms: {
            checkouts: $checkouts
            concurrency: $concurrency
            expires: $expires
            length: $maxLoanDuration
            capLoanExpirationToLicense: $capLoanExpirationToLicense
          }
          customAttributes: $customAttributes
        }
      ) {
        id
      }
    }
  `),
  () => ({
    variables: {
      publicationId,
      targetId: publication.value?.isbn,
      namespaceId: namespaceSelectedRef.value?.id ?? '',
      expires:
        expires.value == '' ? null : new Date(expires.value).toISOString(),
      concurrency: concurrency.value,
      checkouts: checkouts.value,
      capLoanExpirationToLicense: capLoanExpirationToLicense.value,
      maxLoanDuration: maxLoanDuration.value,
      customAttributes: customAttributes.value.filter(({ key }) => key !== ''),
    },
  })
);

const {
  mutate: createNewOpenAccessLicense,
  loading: createOpenAccessLoading,
  onDone: onCreateOpenAccessLicenseDone,
  onError: onCreateOpenAccessLicenseError,
} = useMutation(
  graphql(`
    mutation CreateOpenAccessLicense(
      $publicationId: ID!
      $targetId: ID
      $namespaceId: ID!
      $customAttributes: [AttributeInput!]
    ) {
      createLicense(
        input: {
          entityId: $publicationId
          target: $targetId
          namespaceId: $namespaceId
          source: "frontend"
          customAttributes: $customAttributes
          restrictionType: OPEN_ACCESS
        }
      ) {
        id
      }
    }
  `),
  () => ({
    variables: {
      publicationId,
      targetId: publication.value?.isbn,
      namespaceId: namespaceSelectedRef.value?.id ?? '',
      customAttributes: customAttributes.value.filter(({ key }) => key !== ''),
    },
  })
);

onCreateDone((result) => {
  createError.value = null;
  router.push({
    name: 'license',
    params: { id: result?.data?.createLicense?.id },
  });
});

onCreateError((error) => {
  createError.value = error.message;
});

onCreateOpenAccessLicenseDone((result) => {
  router.push({
    name: 'license',
    params: { id: result?.data?.createLicense?.id },
  });
});

onCreateOpenAccessLicenseError((error) => {
  createError.value = error.message;
});
</script>
