<template>
  <!-- Card: Simple Widget with Action -->
  <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
    <!-- Card Body: Simple Widget with Action -->
    <div class="p-5 lg:p-6 flex-grow w-full flex justify-between items-center">
      <dl>
        <dt class="text-2xl font-semibold">
          {{ props.totalCount }}
        </dt>
        <dd class="uppercase font-medium text-sm text-gray-500 tracking-wider">
          {{ props.cardTitle }}
          <span v-if="slots.default" class="text-xs"> - <slot></slot></span>
        </dd>
      </dl>
    </div>
    <!-- END Card Body: Simple Widget with Action -->

    <!-- Action Link -->
    <div
      v-if="props.enableLink"
      @click="emitRouterLink()"
      class="block p-3 font-medium text-sm text-center bg-gray-50 hover:bg-gray-100 hover:bg-opacity-75 active:bg-gray-50 text-indigo-600 hover:text-indigo-500 cursor-pointer"
    >
      Explore {{ props.cardTitle }}
    </div>
    <div
      v-else
      class="block p-3 font-medium text-sm text-center bg-gray-50 active:bg-gray-50 text-indigo-400"
    >
      Explore {{ props.cardTitle }}
    </div>
    <!-- END Action Link -->
  </div>
  <!-- END Card: Simple Widget with Action -->
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

const slots = useSlots();

const props = defineProps({
  totalCount: { type: [Number, String], required: true },
  cardTitle: { type: String, required: true },
  enableLink: { type: Boolean, required: true },
});

const emits = defineEmits<{
  (e: 'router-link'): void;
}>();

const emitRouterLink = () => {
  emits('router-link');
};
</script>

<style></style>
