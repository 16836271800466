<template>
  <div :class="cardStyle">
    <div :class="loadingStyle" v-if="loading">Loading publication...</div>
    <div v-else-if="error == null">
      <router-link
        v-if="publication"
        :to="{
          name: 'publication',
          params: { id: publication.entityId },
        }"
      >
        <div :class="linkStyle">
          <div v-if="coverSrc" :class="coverWrapStyle">
            <img :src="coverSrc" alt="Cover preview" :class="coverStyle" />
          </div>
          <div :class="infoWrapStyle">
            <h3 :class="headerStyle">Publication</h3>
            <h4 :class="titleStyle">
              {{ publication.title }}
            </h4>
          </div>
        </div>
      </router-link>
      <div v-else>
        <h3 :class="targetStyle">Target: {{ target }}</h3>
      </div>
    </div>
    <div v-else>
      <h3 :class="errorStyle">{{ error }}</h3>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { Ref, inject, ref } from 'vue';
import UrlTemplate from 'url-template';
import { graphql } from '../../../__generated__';

const props = defineProps<{
  entityId?: string | null;
  title?: string | null;
  target?: string | null;
  namespaceId?: string | null;
  loadCover?: boolean | null;
  cardStyle?: string;
  linkStyle?: string;
  headerStyle?: string;
  titleStyle?: string;
  targetStyle?: string;
  loadingStyle?: string;
  errorStyle?: string;
  coverWrapStyle?: string;
  coverStyle?: string;
  infoWrapStyle?: string;
}>();

const loading = ref(true);
const error: Ref<string | null> = ref(null);

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

// Content providers
const namespaces: Ref<string[]> = ref(
  props.namespaceId ? [props.namespaceId] : []
);

const allContentProvidersLoaded: Ref<boolean> = ref(false);
const after: Ref<string | null> = ref(null);

const getContentProvidersQuery = graphql(`
  query GetContentProviders($namespaceId: ID!, $after: String) {
    namespace(id: $namespaceId) {
      allowedContentProviders(after: $after, first: 9999) {
        edges {
          node {
            id
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

const getContentProviders = async () => {
  if (!props.namespaceId || props.entityId) {
    return;
  }
  while (!allContentProvidersLoaded.value) {
    const res = await apolloClient.query({
      query: getContentProvidersQuery,
      variables: {
        namespaceId: props.namespaceId,
        after: after.value,
      },
    });

    if (
      !res.data.namespace ||
      !res.data.namespace.allowedContentProviders.pageInfo.endCursor
    ) {
      break;
    }

    allContentProvidersLoaded.value =
      !res.data.namespace.allowedContentProviders.pageInfo.hasNextPage;
    after.value = res.data.namespace.allowedContentProviders.pageInfo.endCursor;

    namespaces.value = [
      ...namespaces.value,
      ...res.data.namespace.allowedContentProviders.edges.map(
        (edge: any) => edge.node.id
      ),
    ];
  }
};

// Publication
interface Publication {
  title: string;
  entityId: string;
}

const publication: Ref<Publication | null> = ref(null);
const coverSrc: Ref<string | null> = ref(null);

if (props.title && props.entityId) {
  publication.value = {
    title: props.title,
    entityId: props.entityId,
  };
}

const getPublication = async () => {
  if (props.entityId) {
    const res = await apolloClient.query({
      query: graphql(`
        query GetPublicationTitle($id: ID!) {
          publication(id: $id) {
            metadata {
              title
            }
          }
        }
      `),
      variables: { id: props.entityId },
    });

    publication.value = {
      title: res.data.publication?.metadata?.title ?? 'Undefined title',
      entityId: props.entityId,
    };
  } else if (props.target) {
    const res = await apolloClient.query({
      query: graphql(`
        query GetPublicationByISBN($isbn: String!, $namespaces: [String!]!) {
          publicationByISBNForNamespaces(isbn: $isbn, namespaces: $namespaces) {
            metadata {
              title
            }
            id
          }
        }
      `),
      variables: { isbn: props.target, namespaces: namespaces.value },
    });

    const maybeTitle = res.data.publicationByISBNForNamespaces?.metadata?.title;
    const maybeEntityId = res.data.publicationByISBNForNamespaces?.id;

    if (maybeTitle && maybeEntityId) {
      publication.value = {
        title: maybeTitle,
        entityId: maybeEntityId,
      };
    }
  } else {
    error.value = 'Could not load publication.';
  }
  loading.value = false;
};

const getCover = async () => {
  if (!publication.value?.entityId || !props.loadCover) {
    return;
  }

  const res = await apolloClient.query({
    query: graphql(`
      query GetCover($publicationId: ID!) {
        publication(id: $publicationId) {
          cover {
            href
            templated
          }
        }
      }
    `),
    variables: { publicationId: publication.value.entityId },
  });

  if (res.data.publication?.cover) {
    const cover = res.data.publication.cover;
    coverSrc.value = cover?.templated
      ? UrlTemplate.parse(cover.href).expand({
          variant: 'medium',
        })
      : cover?.href;
  }
};

// Setup
if (publication.value == null) {
  getContentProviders().then(getPublication).then(getCover);
} else {
  getCover();
  loading.value = false;
}
</script>
