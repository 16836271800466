export function shortId(id: string): string {
  let splits = id.split('/');
  return splits[splits.length - 1];
}

export function validPublicationIdentifier(str: string): boolean {
  return !str.includes(' ');
}

export function readableFileSize(fileSizeBytes: string): string {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;
  let number = parseInt(fileSizeBytes, 10) || 0;
  while (number >= 1024 && ++index) {
    number = number / 1024;
  }
  const decimal = number < 10 && index > 0 ? 1 : 0;
  return number.toFixed(decimal) + ' ' + units[index];
}

export function splitPublicationNature(publicationNature: string | undefined) {
  switch (publicationNature) {
    case 'EPUB_BOOK':
      return 'EPUB';
    case 'PDF_BOOK':
      return 'PDF';
    case 'AUDIO_BOOK':
      return 'AUDIO';
    case 'MAGAZINE':
      return 'MAGAZINE';
    case 'NEWSPAPER':
      return 'NEWSPAPER';
    default:
      return 'Unknown';
  }
}

export function colorAccordingToPublicationNature(publicationNature: string) {
  switch (publicationNature) {
    case 'EPUB_BOOK':
      return 'text-blue-700 bg-blue-200';
    case 'PDF_BOOK':
      return 'text-red-700 bg-red-200';
    case 'AUDIO_BOOK':
      return 'text-teal-700 bg-teal-200';
    default:
      return 'text-gray-700 bg-gray-200';
  }
}

export function humanReadableDuration(seconds: number) {
  let duration = '';

  if (!seconds) {
    return duration;
  }
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  duration += days > 0 ? `${days}d ` : '';
  duration += hours > 0 ? `${hours}h ` : '';
  duration += minutes > 0 ? `${minutes}m ` : '';

  return duration;
}
