<template>
  <h2 class="page-title">New Namespace</h2>

  <form @submit.prevent="createNamespace()" class="space-y-6 mb-4">
    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-name">Name</label>
      <input
        class="input-text"
        type="text"
        v-model="name"
        id="tk-form-elements-name"
        placeholder="Enter the namespace's name"
      />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-description"
        >Description</label
      >
      <input
        class="input-text"
        type="description"
        v-model="description"
        id="tk-form-elements-description"
        placeholder="Enter the namespace description"
      />
    </div>

    <dm-button
      :enabled="validName"
      type="submit"
      :loading="createNamespaceLoading"
      >Create Namespace</dm-button
    >
  </form>

  <error-display
    :error="createNamespaceError"
    headerMessage="Failed to create the namespace"
  />
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import ErrorDisplay from '../ErrorDisplay.vue';

const name = ref<string>('');
const description = ref<string>('');

const validName = computed(() => name.value.length >= 3);

const {
  mutate: createNamespace,
  loading: createNamespaceLoading,
  error: createNamespaceError,
  onDone,
} = useMutation(
  graphql(`
    mutation CreateNamespace($name: String!, $description: String) {
      createNamespace(input: { name: $name, description: $description }) {
        id
      }
    }
  `),
  () => ({
    variables: {
      description:
        description.value.trim() === '' ? null : description.value.trim(),
      name: name.value,
    },
  })
);

const router = useRouter();

onDone((result) => {
  router.push({
    name: 'namespace',
    params: {
      id: `${result.data?.createNamespace.id}`,
    },
  });
});
</script>

<style scoped>
.input-text {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

input:invalid {
  @apply w-full block border rounded px-3 py-2 leading-6 text-red-700 border-red-400 focus:border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-50;
}
</style>
