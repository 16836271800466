<template>
  <template v-if="periodicalTemplatesErrors">
    <error-display
      class="mt-3"
      :error="periodicalTemplatesErrors"
      headerMessage="An error has occurred querying the periodical's templates"
    ></error-display>
  </template>
  <template v-else-if="periodicalTemplatesResult?.periodical">
    <message-confirmation
      :isHidden="hideConfirmation"
      titleInfoMessage="Delete a Periodical's Subcription"
      :bodyInfoMessage="messageBody"
      buttonConfirmMessage="Yes, delete"
      buttonCancelMessage="Cancel"
      :showCancelButton="true"
      variant="alert"
      @answerUser="answerDeletePeriodicalTemplateAlert"
    >
      <div>
        <p class="mt-5 text-gray-800">
          <strong>Note: </strong>The previous license/s associated to this
          subscription won't be deleted automatically.
        </p>
      </div>
    </message-confirmation>

    <div>
      <h2 class="page-title">
        {{ periodicalTemplatesResult.periodical.name }}
      </h2>
      <ul
        class="inline-flex list-none flex-wrap items-center justify-center gap-3 text-sm font-medium text-gray-600 dark:text-gray-400 md:justify-start mb-5"
      >
        <li class="inline-flex items-center gap-1.5">
          <span class="text-gray-500"
            >Identifier:
            <span class="text-gray-700"> {{ identifier }}</span></span
          >
        </li>
        <li class="inline-flex items-center gap-1.5">
          <span class="text-gray-500"
            >Origin: <span class="text-gray-700">{{ origin }}</span></span
          >
        </li>
      </ul>
      <div>{{ periodicalTemplatesResult.periodical.summary }}</div>
      <!-- Subscriptions -->
      <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
        <!-- Card Header -->
        <div
          class="py-4 px-5 lg:px-6 w-full bg-gray-50 sm:flex sm:justify-between sm:items-center"
        >
          <div class="text-center sm:text-left">
            <h3 class="font-semibold">Subscriptions</h3>
            <h4 class="text-gray-500 text-sm">
              You have
              <span class="text-blue-600">
                {{ periodicalTemplatesResult.periodical.templates.totalCount }}
                templates
              </span>
            </h4>
            <h4>
              Show only active:<input
                class="form-input-checkbox hover:cursor-pointer"
                type="checkbox"
                v-model="onlyActive"
              />
            </h4>
          </div>
          <div
            v-if="canWriteResourceTypeOnAllNamespaces('periodicals')"
            class="mt-3 sm:mt-0 text-center sm:text-right"
          >
            <router-link
              :to="{
                name: 'new_periodical_subscription',
                params: { id: route.params.id },
              }"
              class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-3 py-2 leading-5 text-sm rounded border-blue-700 bg-blue-700 text-white hover:text-white hover:bg-blue-800 hover:border-blue-800 focus:ring focus:ring-blue-500 focus:ring-opacity-50 active:bg-blue-700 active:border-blue-700"
            >
              <svg
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                class="hi-solid hi-plus inline-block w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>New Subscription</span>
            </router-link>
          </div>
        </div>
        <!-- END Card Header -->
        <!-- Card Body -->
        <!-- Error message -->
        <div class="p-5 lg:p-6 flex-grow w-full">
          <div v-if="errorDeletingSubscription">
            <error-display
              class="mt-3"
              error="Try it again later
                and if the error persists, please, contact the System Administrator."
              headerMessage="The choosen subscription couldn't be deleted."
            ></error-display>
          </div>
          <!-- END Error message -->
          <!-- Confirmation message-->
          <success-display :hide="!deletingSubscriptionSuccess" class="mb-5">
            The periodical subscription was deleted successfully. Remember that
            the previous license/s associated to this subscription won't be
            deleted automatically.</success-display
          >
          <!-- END Confirmation message-->
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 pb-5 max-h-96 overflow-x-auto"
          >
            <div
              v-for="template of periodicalTemplatesResult.periodical.templates
                .edges"
              :key="template.node?.id"
              class="flex flex-col rounded shadow bg-white overflow-hidden"
              :class="{
                selectedSubscription:
                  template.node?.id ==
                  currentSelectedPeriodicalSubscription?.id,
              }"
            >
              <div
                class="p-5 lg:p-6 flex-grow w-full text-center"
                v-if="template.node"
              >
                <h3
                  class="text-xl font-semibold mb-2"
                  v-if="template.node.namespace"
                >
                  {{ template.node.namespace.name }}
                </h3>
                <h4
                  v-if="templateIsActive(template.node)"
                  class="text-sm font-semibold text-green-500"
                >
                  Active
                </h4>
                <h4 v-else class="text-sm font-semibold text-red-500">
                  Inactive
                </h4>
                <h4 class="text-sm">From: {{ template.node.startsAt }}</h4>
                <h4 class="text-sm">To: {{ template.node.endsAt }}</h4>
                <h4 class="text-sm">
                  Count:
                  <span class="font-semibold">{{ template.node.count }}</span>
                </h4>
                <h4 class="text-sm mb-2">
                  Concurrency:
                  <span class="font-semibold">
                    {{ template.node.concurrency }}
                  </span>
                </h4>
                <h4
                  class="text-sm"
                  v-if="template.node.customAttributes.length > 0"
                >
                  Custom Attributes:
                </h4>
                <h4
                  class="text-sm truncate first-letter:uppercase"
                  v-if="template.node.customAttributes.length > 0"
                  v-for="ca of template.node.customAttributes"
                >
                  <span
                    class="text-sm group"
                    :class="{
                      'block ': isOrderIdentifier(ca.key),
                    }"
                  >
                    {{ ca.key }}:
                  </span>
                  <span
                    class="text-sm group relative"
                    :class="
                      isOrderIdentifier(ca.key)
                        ? 'font-semibold'
                        : 'text-sm group'
                    "
                  >
                    {{ ca.value }}
                  </span>
                </h4>
                <button
                  type="button"
                  class="mt-3 inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
                  :disabled="
                    !canWriteResourceTypeOnAllNamespaces('periodicals')
                  "
                  @click="setCurrentPeriodicalSubscription(template.node)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="hi-solid hi-trash inline-block w-4 h-4"
                  >
                    <path
                      d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="flex w-full gap-2 justify-center items-center mt-5">
            <button
              :disabled="
                !periodicalTemplatesResult.periodical.templates.pageInfo
                  .hasPreviousPage
              "
              @click="previousPageTemplates()"
              type="button"
              :class="
                periodicalTemplatesResult.periodical.templates.pageInfo
                  .hasPreviousPage
                  ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
                  : 'text-gray-300'
              "
              class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
            >
              <arrow-left-icon class="w-5 h-5" />
            </button>
            <button
              :disabled="
                !periodicalTemplatesResult.periodical.templates.pageInfo
                  .hasNextPage
              "
              @click="nextPageTemplates()"
              :class="
                periodicalTemplatesResult.periodical.templates.pageInfo
                  .hasNextPage
                  ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
                  : 'text-gray-300'
              "
              type="button"
              class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
            >
              <arrow-right-icon class="w-5 h-5" />
            </button>
          </div>
        </div>
        <!-- END Card Body -->
      </div>
    </div>
  </template>
  <template v-if="periodicalLatestIssuesErrors">
    <error-display
      class="mt-3"
      :error="periodicalLatestIssuesErrors"
      headerMessage="An error has occurred querying the Latest Issues"
    ></error-display>
  </template>
  <template v-else-if="periodicalLatestIssuesResult">
    <!-- Latest Issues -->
    <div class="flex flex-col rounded shadow-sm bg-white overflow-hidden">
      <!-- Card Header -->
      <div
        class="py-4 px-5 lg:px-6 w-full bg-gray-50 sm:flex sm:justify-between sm:items-center"
      >
        <div class="text-center sm:text-left">
          <h3 class="font-semibold">Latest Issues For Collection</h3>
          <h4 class="text-gray-500 text-sm">
            You have
            <span class="text-blue-600">
              {{ periodicalLatestIssuesResult.latestIssues.totalCount }}
              Issues
            </span>
          </h4>
        </div>
      </div>
      <!-- END Card Header -->
      <div class="p-5 lg:p-6 flex-grow w-full">
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8"
        >
          <div
            class="text-center"
            v-for="publication of periodicalLatestIssuesResult.latestIssues
              .edges"
            :key="publication.node.id"
          >
            <router-link
              :to="{
                name: 'publication',
                params: { id: publication.node.id },
              }"
              >{{ publication.node.metadata?.title ?? 'Untitled' }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="flex w-full gap-2 justify-center items-center mb-5 lg:mb-6">
        <button
          :disabled="
            !periodicalLatestIssuesResult.latestIssues.pageInfo.hasPreviousPage
          "
          @click="previousPageLatestIssues()"
          type="button"
          :class="
            periodicalLatestIssuesResult.latestIssues.pageInfo.hasPreviousPage
              ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
              : 'text-gray-300'
          "
          class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
        >
          <arrow-left-icon class="w-5 h-5" />
        </button>
        <button
          :disabled="
            !periodicalLatestIssuesResult.latestIssues.pageInfo.hasNextPage
          "
          @click="nextPageLatestIssues()"
          :class="
            periodicalLatestIssuesResult.latestIssues.pageInfo.hasNextPage
              ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
              : 'text-gray-300'
          "
          type="button"
          class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
        >
          <arrow-right-icon class="w-5 h-5" />
        </button>
      </div>
      <!-- END Card -->
    </div>
  </template>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import { DefaultApolloClient, useQuery } from '@vue/apollo-composable';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import ErrorDisplay from '../ErrorDisplay.vue';

import { graphql } from '../../__generated__/gql';
import {
  GetPeriodicalQueryVariables,
  GetPeriodicalLatestsIssuesQueryVariables,
  PeriodicalOrigin,
  PeriodicalTemplate,
  RemovePeriodicalTemplateMutation,
} from '../../__generated__/graphql';
import {
  ApolloClient,
  FetchResult,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { useAuth } from '../../auth';
import MessageConfirmation from '../base/DialogMessage.vue';
import SuccessDisplay from '../SuccessDisplay.vue';

const PERIODICAL_TEMPLATES_QUERY = graphql(`
  query getPeriodical(
    $origin: PeriodicalOrigin!
    $identifier: ID!
    $filterBy: PeriodicalTemplateFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    periodical(origin: $origin, identifier: $identifier) {
      name
      summary
      origin
      identifier
      templates(
        filterBy: $filterBy
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        totalCount
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            count
            concurrency
            startsAt
            endsAt
            namespace {
              name
            }
            customAttributes {
              key
              value
            }
          }
        }
      }
    }
  }
`);
const PERIODICAL_LATEST_ISSUES_QUERY = graphql(`
  query getPeriodicalLatestsIssues(
    $filterByPublications: PublicationFilter
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    latestIssues: publications(
      filterBy: $filterByPublications
      orderBy: { field: UPDATED_AT, direction: DESC }
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      totalCount
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          metadata {
            title
          }
        }
      }
    }
  }
`);
const REMOVE_PERIODICAL_TEMPLATE_QUERY = graphql(`
  mutation removePeriodicalTemplate($id: ID!) {
    removePeriodicalTemplate(id: $id) {
      success
    }
  }
`);

const LATEST_ISSUES_PER_PAGE: number = 10;
const TEMPLATES_PER_PAGE: number = 48;
const route: RouteLocationNormalizedLoaded = useRoute();
const onlyActive: Ref<boolean> = ref(false);
const hideConfirmation: Ref<boolean> = ref(true);

const id = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const firstDash = computed(() => id.value.indexOf(':'));

const identifier = computed(() => {
  return id.value ? id.value.slice(firstDash.value + 1) : '';
});

const origin: Ref<PeriodicalOrigin> = computed(() => {
  const maybeOrigin = id.value ? id.value.slice(0, firstDash.value) : '';
  if (['DM_SPAIN', 'ODILO_SPAIN'].includes(maybeOrigin)) {
    return maybeOrigin as PeriodicalOrigin;
  }
  return undefined!;
});

const filterBy: Ref<{ onlyActiveAt: Date } | null> = computed(() => {
  return onlyActive.value ? { onlyActiveAt: new Date() } : null;
});

const variablesTemplatesQuery: Ref<GetPeriodicalQueryVariables> = ref({
  origin,
  identifier,
  filterBy: filterBy,
  first: TEMPLATES_PER_PAGE,
  last: null,
  before: null,
  after: undefined,
});

const variablesLatestIssuesQuery: Ref<GetPeriodicalLatestsIssuesQueryVariables> =
  ref({
    origin,
    identifier,
    filterByPublications: {
      issn: identifier,
      customAttributes: [
        {
          key: 'subscription_origin',
          value: origin,
        },
      ],
    },
    first: LATEST_ISSUES_PER_PAGE,
    last: null,
    before: null,
    after: undefined,
  });

const {
  result: periodicalTemplatesResult,
  fetchMore: periodicalTemplatesFetchMore,
  refetch: periodicalTemplatesRefetch,
  error: periodicalTemplatesErrors,
} = useQuery(PERIODICAL_TEMPLATES_QUERY, variablesTemplatesQuery, {
  fetchPolicy: 'cache-and-network',
});

const {
  result: periodicalLatestIssuesResult,
  fetchMore: periodicalLatestIssuesFetchMore,
  error: periodicalLatestIssuesErrors,
} = useQuery(PERIODICAL_LATEST_ISSUES_QUERY, variablesLatestIssuesQuery, {
  fetchPolicy: 'cache-and-network',
});

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

function templateIsActive(tpl: { startsAt?: any; endsAt?: any }) {
  const now = new Date();
  return new Date(tpl.startsAt) <= now && new Date(tpl.endsAt) >= now;
}

function nextPageTemplates() {
  periodicalTemplatesFetchMore({
    variables: {
      first: TEMPLATES_PER_PAGE,
      last: null,
      after:
        periodicalTemplatesResult.value?.periodical?.templates.pageInfo
          .endCursor,
      before: undefined,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function previousPageTemplates() {
  periodicalTemplatesFetchMore({
    variables: {
      first: null,
      last: TEMPLATES_PER_PAGE,
      after: undefined,
      before:
        periodicalTemplatesResult.value?.periodical?.templates.pageInfo
          .startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function nextPageLatestIssues() {
  periodicalLatestIssuesFetchMore({
    variables: {
      first: LATEST_ISSUES_PER_PAGE,
      last: null,
      after:
        periodicalLatestIssuesResult.value?.latestIssues.pageInfo.endCursor,
      before: undefined,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function previousPageLatestIssues() {
  periodicalLatestIssuesFetchMore({
    variables: {
      first: null,
      last: LATEST_ISSUES_PER_PAGE,
      after: undefined,
      before:
        periodicalLatestIssuesResult.value?.latestIssues.pageInfo.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function isOrderIdentifier(customAttributeKey: string) {
  return customAttributeKey == 'order_identifier';
}

const currentSelectedPeriodicalSubscription: Ref<PeriodicalTemplate | null> =
  ref(null);
const errorDeletingSubscription: Ref<boolean> = ref(false);
const deletingSubscriptionSuccess: Ref<boolean> = ref(false);
const messageBody = computed(
  () =>
    `Are you sure you want to delete the Subscription from ${currentSelectedPeriodicalSubscription.value?.namespace?.name} with ${currentSelectedPeriodicalSubscription.value?.count} license/s with ${currentSelectedPeriodicalSubscription.value?.concurrency} concurrency, from ${currentSelectedPeriodicalSubscription.value?.startsAt} till ${currentSelectedPeriodicalSubscription.value?.endsAt}?`
);
function setCurrentPeriodicalSubscription(periodicalSubscription: any) {
  deletingSubscriptionSuccess.value = hideConfirmation.value = false;
  currentSelectedPeriodicalSubscription.value = periodicalSubscription;
}

async function deletePeriodicalTemplate(
  id: string
): Promise<FetchResult<RemovePeriodicalTemplateMutation>> {
  return await apolloClient.mutate({
    mutation: REMOVE_PERIODICAL_TEMPLATE_QUERY,
    variables: { id },
  });
}

async function answerDeletePeriodicalTemplateAlert(answer: boolean) {
  if (answer) {
    const result = await deletePeriodicalTemplate(
      currentSelectedPeriodicalSubscription.value?.id!
    );
    if (result.data?.removePeriodicalTemplate?.success) {
      periodicalTemplatesRefetch();
      deletingSubscriptionSuccess.value = true;
    } else {
      errorDeletingSubscription.value = true;
    }
  } else {
    currentSelectedPeriodicalSubscription.value = null;
  }
  hideConfirmation.value = true;
}

let { canWriteResourceTypeOnAllNamespaces } = useAuth();
</script>

<style scoped>
.form-input-checkbox {
  @apply ml-1 border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}
.page-title {
  @apply mb-0;
}
button:disabled {
  @apply opacity-50 cursor-not-allowed;
}
.selectedSubscription {
  @apply border-blue-500 border rounded;
}
</style>
