<template>
  <h2 class="page-title">Users</h2>
  <input
    type="text"
    v-model="keyword"
    class="mb-4 w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
    id="tk-form-layouts-search"
    placeholder="Search.."
  />

  <Table
    :headers="['Name', 'Email']"
    :hasNextPage="usersPageInfo?.hasNextPage"
    :hasPreviousPage="usersPageInfo?.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? loadNext() : loadPrevious())"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="users"
      v-for="user of users"
      :key="user.node.id"
      class="border-b border-gray-200 even:bg-gray-50"
    >
      <td class="p-3">
        <router-link
          :to="{
            name: 'user',
            params: { id: user.node.id },
          }"
        >
          {{ user.node.name }}
        </router-link>
      </td>
      <td class="p-3 text-gray-500">
        {{ user.node.email }}
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { Ref, computed, ref } from 'vue';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';

import { graphql } from '../../__generated__/gql';
import { UsersQueryVariables } from '../../__generated__/graphql';
import Table from '../base/Table.vue';

const numberOfEntriesToDisplay = 10;
const keyword: Ref<string | null> = ref(null);
const variables: Ref<UsersQueryVariables> = computed(() => ({
  first: numberOfEntriesToDisplay,
  after: null,
  before: null,
  last: null,
  keyword: keyword.value?.trim(),
}));

const { result, loading, error, fetchMore } = useQuery(
  graphql(`
    query Users(
      $first: Int
      $after: String
      $before: String
      $last: Int
      $keyword: String
    ) {
      users(
        first: $first
        after: $after
        before: $before
        last: $last
        filterBy: { keyword: $keyword }
      ) {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            email
          }
        }
      }
    }
  `),
  variables
);

const users = computed(() => result?.value?.users.edges ?? []);

const usersPageInfo = computed(() => result?.value?.users.pageInfo ?? null);

function loadNext() {
  fetchMore({
    variables: {
      first: numberOfEntriesToDisplay,
      last: null,
      after: usersPageInfo?.value?.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function loadPrevious() {
  fetchMore({
    variables: {
      last: numberOfEntriesToDisplay,
      first: null,
      before: usersPageInfo?.value?.startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
</script>
