<template>
  <template v-if="customIngestionHooksLoading">Loading ... </template>
  <template v-if="customIngestionHooksError">
    <error-display headerMessage="Error" :error="customIngestionHooksError" />
  </template>
  <template v-else-if="customIngestionHooks">
    <h2 class="page-title">Custom Ingestion Hooks</h2>
    <div
      v-for="hook of customIngestionHooks"
      class="flex flex-col rounded-lg shadow-sm bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800 m-3"
    >
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-white"
      >
        <h3 class="text-lg font-medium text-gray-500 dark:text-gray-400 p-2">
          {{ hook.node.action + ' - ' + shortId(hook.node.id) }}
        </h3>
        <!-- Cards in Grid -->
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-2"
        >
          <!-- Custom Card for each key/value pair-->
          <div
            v-for="actionParam in hook.node.actionParams"
            class="flex flex-col rounded-lg bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800"
          >
            <div class="p-3 lg:p-3 grow w-full text-center">
              <div class="space-y-1 text-left flex flex-col">
                <label class="font-medium">
                  {{ actionParam.key }}
                </label>

                <label
                  class="w-60 block border placeholder-gray-500 px-3 py-2 leading-6 rounded-lg border-gray-200 bg-gray-100"
                >
                  {{ actionParam.value }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed } from '@vue/reactivity';

import { graphql } from '../../__generated__/gql';

import { shortId } from '../../utils';
import ErrorDisplay from '../ErrorDisplay.vue';

interface CustomIngestionData {
  customIngestionHooks: {
    edges: CustomIngestionHookEdge[];
  };
}

interface CustomIngestionHookEdge {
  node: {
    id: string;
    action: string;
    namespaceId: string;
    actionParams: ActionParamsData[];
  };
}

interface ActionParamsData {
  key: string;
  value: string;
}

const customIngestionHooks = computed(() => {
  if (customIngestionHooksResult?.value?.customIngestionHooks.edges) {
    return customIngestionHooksResult.value.customIngestionHooks.edges.map(
      (edge: CustomIngestionHookEdge) => edge
    );
  } else {
    return [];
  }
});

const {
  result: customIngestionHooksResult,
  loading: customIngestionHooksLoading,
  error: customIngestionHooksError,
} = useQuery(
  graphql(`
    query GetCustomIngestionHooks {
      customIngestionHooks(first: 100) {
        totalCount
        edges {
          node {
            id
            action
            namespaceId
            actionParams {
              key
              value
            }
          }
        }
      }
    }
  `)
);
</script>
