<template>
  <div
    class="border border-gray-200 rounded overflow-x-auto max-w-full min-w-full bg-white h-full"
  >
    <table
      class="min-w-full text-sm align-middle whitespace-nowrap table-fixed"
    >
      <thead>
        <tr class="border-b border-gray-200">
          <th
            v-for="header in headers"
            :key="typeof header == 'string' ? header : header.label"
            class="p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider uppercase text-left"
            :class="centerHeader ? 'text-center' : 'text-left'"
          >
            {{ typeof header == 'string' ? header : header.label }}
            <input
              v-if="
                typeof header != 'string' && header.type === 'input_checkbox'
              "
              @click="emits('selectAll')"
              :checked="isInputHeaderChecked"
              type="checkbox"
              name="check_all"
              class="size-4 rounded border border-gray-400 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-800 dark:ring-offset-gray-900 dark:checked:border-transparent dark:checked:bg-blue-500 dark:focus:border-blue-500"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <slot />
      </tbody>
    </table>
    <div
      v-show="hasNextPage || hasPreviousPage"
      class="p-3 text-gray-700 bg-gray-100 font-semibold text-sm flex w-full gap-2 justify-center items-center sticky left-0 right-0"
    >
      <button
        :disabled="!hasPreviousPage"
        @click="emits('changePage', 'prev')"
        type="button"
        :class="
          hasPreviousPage
            ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
            : 'text-gray-300'
        "
        class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
      >
        <svg
          class="hi-solid hi-arrow-left inline-block w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <p v-if="pageIndicator">Page {{ pageIndicator }}</p>
      <button
        :disabled="!hasNextPage"
        @click="emits('changePage', 'next')"
        :class="
          hasNextPage
            ? 'hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow text-gray-800'
            : 'text-gray-300'
        "
        type="button"
        class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
      >
        <svg
          class="hi-solid hi-arrow-right inline-block w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  headers: string[] | { label: string; type: string | null }[];
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  pageIndicator?: number;
  centerHeader?: boolean;
  isInputHeaderChecked?: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits<{
  changePage: [direction: 'next' | 'prev'];
  selectAll: [];
}>();
</script>
