<template>
  <select
    v-if="displaySelectBox && (userNamespaces?.length ?? 0) > 0"
    v-model="selectedNamespaceRef"
    class="w-full block border border-gray-200 rounded px-3 py-2 leading-5 text-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
    id="tk-inputs-default-select"
  >
    <option
      v-if="props.injectOptionAllNamespaces"
      :value="SELECT_ALL_NAMESPACES"
    >
      {{ SELECT_ALL_NAMESPACES.name }}
    </option>
    <option
      v-for="(namespace, _index) in userNamespaces"
      :value="namespace"
      :key="namespace.id"
    >
      {{ namespace.name }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { existingResourceTypes, useAuth } from '../../auth';
import { SELECT_ALL_NAMESPACES, QUERY_ALL_NAMESPACES } from './allNamespaces';

interface NamespacesData {
  namespaces: {
    edges: {
      node: {
        id: string;
        name: string;
      };
    }[];
  };
}

export interface Props {
  injectOptionAllNamespaces?: boolean;
  resourceType?: existingResourceTypes;
  modelValue?: { id: string; name: string } | null;
}
const props = withDefaults(defineProps<Props>(), {
  injectOptionAllNamespaces: false,
});

const emit = defineEmits(['update:model-value']);

const { getUserNamespaces, canReadResourceTypeOnAllNamespaces } = useAuth();
const route = useRoute();

const userNamespaces = ref(getUserNamespaces());

if (canReadResourceTypeOnAllNamespaces('namespaces')) {
  const { result } = useQuery(QUERY_ALL_NAMESPACES, null, {
    fetchPolicy: 'cache-and-network',
  });

  watch(result, () => {
    userNamespaces.value = result.value?.namespaces.edges
      .map((edge) => ({
        id: edge.node.id,
        name: edge.node.name,
      }))
      .sort((previousEdge, edge) => (previousEdge.name < edge.name ? -1 : 1));
  });
}

const queryNamespaceId = Array.isArray(route.query.namespaceId)
  ? route.query.namespaceId[0]?.toString()
  : route.query.namespaceId?.toString();

const canReadAllNamespaces = props.resourceType
  ? canReadResourceTypeOnAllNamespaces(props.resourceType)
  : false;

// We hide the select box when a user with full rights navigate to the publications page
// from a namespace that is not in its list of namespaces.
const displaySelectBox = ref(true);

// Pick an initial namespace (tricky!):
// => If there is no namespace defined in the query string, select either the "All" option
//    or the first in the list of the user namespaces, depending on the user rights.
// => If there is a namespace in the query string, use it if its in the list of the usernamespaces
// => If there is a namespace in the query string, and the user has rights over all namespaces, use it
// even if its not in the list of user namespaces.
// => If still there is no namespace selected, use the first in the user namespaces list.
let initialSelectedNamespace: { id: string; name: string } | null = null;

const namespaceFromQueryInList = userNamespaces.value?.find(
  (namespace) => namespace.id === queryNamespaceId
);

if (namespaceFromQueryInList) {
  initialSelectedNamespace = namespaceFromQueryInList;
}

if (initialSelectedNamespace == null) {
  if (canReadAllNamespaces) {
    if (queryNamespaceId) {
      initialSelectedNamespace = {
        id: queryNamespaceId,
        name: (route.query.namespace as string) || '',
      };
      if (!route.query.namespace) {
        displaySelectBox.value = false;
      }
    } else {
      initialSelectedNamespace = SELECT_ALL_NAMESPACES;
    }
  } else {
    initialSelectedNamespace = userNamespaces.value?.[0] || null;
  }
}

const selectedNamespaceRef = ref(initialSelectedNamespace);

const computeNamespaceStructure = (
  namespace: { id: string; name: string } | null
) =>
  namespace && {
    id: namespace.id === SELECT_ALL_NAMESPACES.id ? undefined : namespace.id,
    name: namespace.name,
  };

watch(props, () => {
  if (!props.modelValue) {
    selectedNamespaceRef.value = null;
  }
});

watch(selectedNamespaceRef, () => {
  emit(
    'update:model-value',
    computeNamespaceStructure(selectedNamespaceRef.value)
  );
});

watch(
  () => route.query,
  (newValue) => {
    if (newValue.namespace && newValue.namespaceId) {
      selectedNamespaceRef.value = {
        id: newValue.namespaceId as string,
        name: newValue.namespace as string,
      };
    }
  }
);

emit(
  'update:model-value',
  computeNamespaceStructure(selectedNamespaceRef.value)
);
</script>
