<template>
  <div class="space-y-1">
    <label class="font-medium italic">{{ label }}</label>
    <ul :class="['list', { 'list-changed': hasChanged }]">
      <li
        class="p-4 flex justify-between items-center"
        v-for="pair in internalModel"
      >
        <span class="font-semibold text-sm mr-1">
          {{ pair.label }}
        </span>
        <span class="text-green-500 text-sm">
          {{ pair.value }}
        </span>
        <span>
          <dm-button
            variant="danger"
            @click.prevent="removePair(pair.label, pair.value)"
            size="xs"
            >remove</dm-button
          >
        </span>
      </li>

      <li class="p-4 flex justify-between items-center">
        <span class="font-semibold text-sm mr-1">
          <select
            v-model="newLabel"
            class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
          >
            <option v-for="label of labels" :value="label" key="label">
              {{ label }}
            </option>
          </select>
        </span>
        <span>
          <input
            v-model="newValue"
            class="form-input"
            type="text"
            :placeholder="placeholder || ''"
          />
        </span>
        <span>
          <dm-button size="xs" @click.prevent="addPair">Add</dm-button>
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue';

type TypedKeyValuePairs = { label: string; value: string }[];

const props = defineProps<{
  label: string;
  initialValue: TypedKeyValuePairs;
  labels: string[];
  placeholder?: string | null;
  modelValue: TypedKeyValuePairs | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: TypedKeyValuePairs): void;
  (e: 'differs', value: boolean): void;
}>();

const newLabel = ref<string>(props.labels[0]);
const newValue = ref<string>('');

const internalModel: Ref<TypedKeyValuePairs> = ref(
  JSON.parse(JSON.stringify(props.initialValue))
);
const hasChanged = computed(
  () =>
    internalModel.value.length != props.initialValue.length ||
    !!props.initialValue.find(
      (iv) =>
        !internalModel.value.find(
          (mv) => iv.label === mv.label && iv.value === mv.value
        )
    )
);

function addPair() {
  internalModel.value = internalModel.value || [];
  internalModel.value.push({
    label: newLabel.value,
    value: newValue.value,
  });
  newValue.value = '';
  newLabel.value = props.labels[0];
}

const removePair = (label: string, value: string) => {
  if (internalModel.value) {
    internalModel.value = internalModel.value.filter(
      (c) => !(c.label == label && c.value == value)
    );
  }
};

onMounted(() => {
  watch(props, (newProps) => {
    internalModel.value = newProps.modelValue!;
  });
  emit('update:modelValue', internalModel.value);
});

watch(
  internalModel,
  (newVal) => {
    emit('update:modelValue', newVal);
    emit('differs', hasChanged.value);
  },
  { deep: true }
);
</script>
<style scoped>
.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.list-changed {
  @apply border-purple-500  divide-purple-500;
}

.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
