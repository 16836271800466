<template>
  <h2 class="page-title">Audio Encoding Jobs (up to 2 months in the past)</h2>

  <div class="p-4 md:p-5 rounded text-green-700 bg-green-100" v-if="reranJob">
    <div class="flex items-center mb-2">
      <svg
        class="hi-solid hi-check-circle inline-block w-5 h-5 mr-3 flex-none text-green-500"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        />
      </svg>
      <h3 class="font-semibold">Launched job re-run.</h3>
    </div>
  </div>

  <div class="mt-2">
    <label class="inline-flex items-center mr-6">
      <input
        type="checkbox"
        v-model="onlyNonCompleted"
        class="border border-gray-200 rounded h-4 w-4 text-indigo-500 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
      />
      <span class="ml-2">Only display unfinished tasks.</span>
    </label>
  </div>

  <namespace-selector
    class="my-4"
    v-model="namespaceSelectedRef"
    resource-type="audiobooks"
    inject-option-all-namespaces
  />

  <Table
    :headers="[
      'Namespace',
      'Completed',
      'Success',
      'Last update',
      'Log',
      'Re-run',
    ]"
    :hasNextPage="jobs?.pageInfo?.hasNextPage"
    :hasPreviousPage="jobs?.pageInfo?.hasPreviousPage"
    @changePage="(e) => (e === 'next' ? nextPage() : previousPage())"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="jobs"
      v-for="edge of jobs.edges"
      :key="edge.node.id"
      class="border-b border-gray-200 even:bg-gray-50"
    >
      <td class="p-3 text-gray-500">{{ edge.node.namespace.name }}</td>
      <td class="p-3 text-gray-500">{{ edge.node.completed }}</td>
      <td class="p-3 text-gray-500">{{ edge.node.success }}</td>
      <td class="p-3 text-gray-500">{{ dayjs(edge.node.updatedAt) }}</td>
      <td class="p-3 text-gray-500">
        <dm-button size="xs" @click.prevent="openLog(edge.node.id)"
          >Show Log
        </dm-button>
      </td>
      <td class="p-3 text-gray-500">
        <dm-button
          size="xs"
          variant="danger"
          @click.prevent="rerunJob(edge.node.id)"
          >Run again
        </dm-button>
      </td>
    </tr>
  </Table>
</template>

<script lang="ts">
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import {
  DefaultApolloClient,
  useQuery,
  useResult,
} from '@vue/apollo-composable';
import dayjs from 'dayjs';
import { computed, defineComponent, inject, ref, Ref } from 'vue';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';
import { JobsQueryVariables } from '../../__generated__/graphql';

import DmButton from '../base/Button.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import Table from '../base/Table.vue';

const toast = useToast();

export default defineComponent({
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    DmButton,
    NamespaceSelector,
    Table,
  },
  setup() {
    const perPage = 25;
    const reranJob = ref<any>(null);
    const onlyNonCompleted = ref<boolean>(false);
    const apolloClient = inject(
      DefaultApolloClient
    ) as ApolloClient<NormalizedCacheObject>;
    const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
      ref(null);
    const namespaceId: Ref<string | null> = computed(
      () => namespaceSelectedRef.value && namespaceSelectedRef.value.id
    );
    const variables: Ref<JobsQueryVariables> = computed(() => ({
      first: perPage,
      last: null,
      before: undefined,
      after: undefined,
      namespaceId: namespaceId.value,
      onlyNonComplete: onlyNonCompleted.value,
      createdAfter: dayjs().subtract(2, 'month').toISOString(),
    }));
    const { result, loading, error, fetchMore } = useQuery(
      graphql(`
        query Jobs(
          $namespaceId: ID
          $first: Int
          $last: Int
          $after: String
          $before: String
          $onlyNonComplete: Boolean
          $createdAfter: DateTime
        ) {
          jobs(
            filterBy: {
              namespaceId: $namespaceId
              onlyNonComplete: $onlyNonComplete
              createdAfter: $createdAfter
            }
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: DESC, field: UPDATED_AT }
          ) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                success
                updatedAt
                createdAt
                completed
                namespaceId
                namespace {
                  name
                }
              }
            }
          }
        }
      `),
      variables
    );

    const jobs = useResult(result);

    async function openLog(id: string) {
      let result = await apolloClient.mutate({
        mutation: graphql(`
          query openLog($id: ID!) {
            job(id: $id) {
              logViewerURL
            }
          }
        `),
        variables: { id },
      });
      if (result.data) {
        window.open(result.data.job.logViewerURL, `joblog-${id}`);
      } else {
        toast.error('Error getting logs.');
      }
    }

    async function rerunJob(id: string) {
      await apolloClient.mutate({
        mutation: graphql(`
          mutation rerunJob($id: ID!) {
            rerunJob(jobId: $id)
          }
        `),
        variables: { id },
      });
      reranJob.value = true;
    }

    function loadNext() {
      fetchMore({
        variables: {
          first: perPage,
          last: null,
          after: jobs?.value?.pageInfo?.endCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          fetchMoreResult || previousResult,
      });
    }

    function loadPrevious() {
      fetchMore({
        variables: {
          last: perPage,
          first: null,
          before: jobs?.value?.pageInfo?.startCursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) =>
          fetchMoreResult || previousResult,
      });
    }

    return {
      jobs,
      nextPage: loadNext,
      previousPage: loadPrevious,
      loading,
      openLog,
      error,
      namespaceSelectedRef,
      rerunJob,
      reranJob,
      onlyNonCompleted,
      dayjs,
    };
  },
});
</script>
