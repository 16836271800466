<template>
  <div v-if="loading">Loading...</div>
  <div v-else-if="!result?.periodical">An error has occurred</div>
  <div v-else-if="result?.periodical">
    <h2 class="page-title">Update Periodical</h2>
    <div class="space-y-8">
      <!-- Basic Info -->
      <div class="md:flex md:space-x-5">
        <!-- Basic Info Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Basic Information</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">
            Here you can edit all the information related to the periodical
            whose origin is <strong>{{ origin }}</strong> and whose identifier
            is <strong>{{ identifier }}</strong
            >.
          </p>
        </div>
        <!-- END Basic Info Info -->

        <!-- Card: Basic Info -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <!-- Card Body: Basic Info -->
          <div class="p-5 lg:p-6 flex-grow w-full">
            <form @submit.prevent="tryToPerformChanges()" class="space-y-6">
              <div class="space-y-1">
                <label for="form-input-origin" class="font-medium"
                  >Subscription origin</label
                >
                <p
                  class="cursor-not-allowed block border border-gray-200 rounded px-3 py-2 leading-6 w-full text-gray-500"
                >
                  {{ result.periodical.origin }}
                </p>
              </div>
              <div class="space-y-1">
                <label for="form-input-identifier" class="font-medium"
                  >Identifier</label
                >
                <p
                  class="cursor-not-allowed block border border-gray-200 rounded px-3 py-2 leading-6 w-full text-gray-500"
                >
                  {{ result.periodical.identifier }}
                </p>
              </div>
              <div class="space-y-1">
                <label
                  for="tk-form-layouts-multiple-sections-name"
                  class="font-medium"
                  >Name</label
                >
                <input
                  v-model="name"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': nameHaschanged && !nameIsNull,
                      'form-input-required': nameIsNull,
                    },
                  ]"
                  type="text"
                  id="tk-form-layouts-multiple-sections-name"
                  placeholder="El País"
                />
                <p v-if="nameIsNull" class="text-red-500 font-medium">
                  The field name can't be empty
                </p>
              </div>
              <div class="space-y-1">
                <label for="form-input-type" class="font-medium">Type</label>
                <select
                  v-model="type"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': typeHaschanged,
                    },
                  ]"
                  id="form-input-type"
                >
                  <option value="NEWSPAPER">Newspaper</option>
                  <option value="MAGAZINE">Magazine</option>
                </select>
                <p class="text-sm text-gray-500">
                  will determine the max loan duration
                </p>
              </div>

              <div class="space-y-1">
                <label
                  for="tk-form-layouts-multiple-sections-publisher"
                  class="font-medium"
                  >Publisher</label
                >
                <input
                  v-model="publisher"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': publisherHaschanged,
                    },
                  ]"
                  type="text"
                  id="tk-form-layouts-multiple-sections-publisher"
                  placeholder="RBA"
                />
              </div>
              <div class="space-y-1">
                <label for="form-input-lang" class="font-medium"
                  >Language</label
                >
                <select
                  v-model="lang"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': langHaschanged,
                    },
                  ]"
                  id="form-input-lang"
                >
                  <option value="cat">Catalan</option>
                  <option value="eng">English</option>
                  <option value="fre">French</option>
                  <option value="glg">Galician</option>
                  <option value="ita">Italian</option>
                  <option value="spa">Spanish</option>
                </select>
              </div>
              <div class="space-y-1">
                <label class="font-medium" for="tk-form-elements-summary"
                  >Summary</label
                >
                <textarea
                  v-model="summary"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': summaryHaschanged,
                    },
                  ]"
                  id="tk-form-elements-summary"
                  :rows="4"
                  placeholder="Enter further details"
                ></textarea>
              </div>
              <div class="space-y-1">
                <label
                  class="font-medium"
                  for="tk-form-elements-hasAdultConsideration"
                  >Only Adult</label
                >
                <div class="w-4">
                  <input
                    v-model="hasAdultConsideration"
                    :class="[
                      'form-input',
                      {
                        'form-input-changed': hasAdultConsiderationHaschanged,
                      },
                    ]"
                    type="checkbox"
                    id="tk-form-elements-hasAdultConsideration"
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label class="font-medium" for="tk-form-elements-ftpServer"
                  >Ftp Server</label
                >
                <input
                  v-model="ftpServer"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': ftpServerHasChanged,
                    },
                  ]"
                  type="text"
                  id="tk-form-elements-ftpServer"
                  placeholder="periodicals"
                />
              </div>
              <div class="space-y-1">
                <label class="font-medium" for="tk-form-elements-ftpPath"
                  >Ftp Path</label
                >
                <input
                  v-model="ftpPath"
                  :class="[
                    'form-input',
                    {
                      'form-input-changed': ftpPathHasChanged,
                    },
                  ]"
                  type="text"
                  id="tk-form-elements-ftpPath"
                  placeholder="relative route in ftp folder"
                />
              </div>
              <success-display
                :hide="
                  !(periodicalUpdatedSuccessfully && !periodicalHasChanged)
                "
              >
                Periodical updated successfully
              </success-display>
              <dm-button :enabled="periodicalHasChanged" type="submit"
                >Save Changes
              </dm-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed, ref, watch, Ref } from 'vue';
import { useRoute } from 'vue-router';

import { graphql } from '../../__generated__/gql';
import {
  AttributeInput,
  PeriodicalOrigin,
  PeriodicalType,
} from '../../__generated__/graphql';

import SuccessDisplay from '../SuccessDisplay.vue';

const FTP_SERVER_KEY = 'ftp_server';
const FTP_PATH_KEY = 'ftp_path';
const _PERIODICAL_FRAGMENT = graphql(`
  fragment ShowPeriodicalPagePeriodical on PeriodicalMetadata {
    name
    identifier
    origin
    type
    lang
    summary
    hasAdultConsideration
    customAttributes {
      key
      value
    }
    publisher
  }
`);
const GET_PERIODICAL_QUERY = graphql(`
  query getPeriodicalForEdit($origin: PeriodicalOrigin!, $identifier: ID!) {
    periodical(origin: $origin, identifier: $identifier) {
      ...ShowPeriodicalPagePeriodical
    }
  }
`);
const UPDATE_PERIODICAL_QUERY = graphql(`
  mutation updatePeriodical(
    $origin: PeriodicalOrigin!
    $identifier: ID!
    $changes: UpdatePeriodicalChanges!
  ) {
    updatePeriodical(
      origin: $origin
      identifier: $identifier
      changes: $changes
    ) {
      success
      periodical {
        ...ShowPeriodicalPagePeriodical
      }
    }
  }
`);

const name = ref<string | undefined>('');
const publisher = ref<string | undefined | null>('');
const lang = ref<string | undefined>('');
const summary = ref<string | undefined>(undefined);
const hasAdultConsideration = ref<boolean | undefined>(false);
const type = ref<PeriodicalType | undefined>('NEWSPAPER');
const ftpServer = ref<string | undefined>(undefined);
const ftpPath = ref<string | undefined>(undefined);
const periodicalUpdatedSuccessfully = ref<boolean>(false);
const customAttributes = ref<
  | {
      key: string;
      value: string;
    }[]
>([
  { key: FTP_SERVER_KEY, value: '' },
  { key: FTP_PATH_KEY, value: '' },
]);
const route = useRoute();

const id = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);
const firstDash = computed(() => id.value.indexOf(':'));
const identifier = computed(() => {
  return id.value ? id.value.slice(firstDash.value + 1) : '';
});
const origin: Ref<PeriodicalOrigin> = computed(() => {
  const value = id.value ? id.value.slice(0, firstDash.value) : '';
  if (['DM_SPAIN', 'ODILO_SPAIN'].includes(value)) {
    return value as PeriodicalOrigin;
  }
  return undefined!;
});
const variables = computed(() => ({
  origin: origin.value,
  identifier: identifier.value,
}));

const nameHaschanged = computed(
  () => name.value != result.value?.periodical?.name
);

const nameIsNull = computed(() => {
  return name.value ? name.value.replace(/\s/g, '') === '' : true;
});

const publisherHaschanged = computed(
  () => publisher.value != result.value?.periodical?.publisher
);

const langHaschanged = computed(
  () => lang.value != result.value?.periodical?.lang
);

const summaryHaschanged = computed(
  () => summary.value != result.value?.periodical?.summary
);

const typeHaschanged = computed(
  () => type.value != result.value?.periodical?.type
);

const hasAdultConsiderationHaschanged = computed(
  () =>
    hasAdultConsideration.value !=
    result.value?.periodical?.hasAdultConsideration
);

const periodicalHasChanged = computed(
  () =>
    (nameHaschanged.value && !nameIsNull.value) ||
    publisherHaschanged.value ||
    langHaschanged.value ||
    summaryHaschanged.value ||
    typeHaschanged.value ||
    ftpPathHasChanged.value ||
    ftpServerHasChanged.value ||
    hasAdultConsiderationHaschanged.value
);

const ftpServerHasChanged = computed(
  () =>
    ftpServer.value !=
    customAttributes.value.find((value) => value.key == FTP_SERVER_KEY)?.value
);

const ftpPathHasChanged = computed(
  () =>
    ftpPath.value !=
    customAttributes.value.find((value) => value.key == FTP_PATH_KEY)?.value
);

const { result, loading } = useQuery(GET_PERIODICAL_QUERY, variables, {
  fetchPolicy: 'network-only',
});

const { mutate: saveChanges, onDone: onSaveDone } = useMutation(
  UPDATE_PERIODICAL_QUERY,
  variablesToUpdate
);

watch(result, (value) => {
  name.value = value?.periodical?.name;
  publisher.value = value?.periodical?.publisher;
  lang.value = value?.periodical?.lang;
  summary.value = value?.periodical?.summary ?? undefined;
  type.value = value?.periodical?.type;
  customAttributes.value = defineCustomAttributes(
    value?.periodical?.customAttributes
  );
  ftpServer.value = customAttributes.value.find(
    (customAttribute) => customAttribute.key == FTP_SERVER_KEY
  )?.value;
  ftpPath.value = customAttributes.value.find(
    (customAttribute) => customAttribute.key == FTP_PATH_KEY
  )?.value;
  hasAdultConsideration.value = value?.periodical?.hasAdultConsideration;
});

function variablesToUpdate() {
  const varOrigin = origin.value;
  if (!varOrigin) {
    throw new Error('Invalid periodical origin: ' + origin.value);
  }

  const varType = type.value;
  if (!varType) {
    throw new Error('Invalid periodical type: ' + type.value);
  }

  const varCustomAttributes: AttributeInput[] = [];

  if (ftpServerHasChanged.value && ftpServer.value) {
    varCustomAttributes.push({
      key: FTP_SERVER_KEY,
      value: ftpServer.value,
    });
  }

  if (ftpPathHasChanged.value && ftpPath.value) {
    varCustomAttributes.push({
      key: FTP_PATH_KEY,
      value: ftpPath.value,
    });
  }

  return {
    variables: {
      origin: varOrigin,
      identifier: identifier.value,
      changes: {
        ...(nameHaschanged.value && !nameIsNull.value
          ? { name: name.value?.trim().replace(/\s\s+/g, ' ') }
          : {}),
        ...(publisherHaschanged.value ? { publisher: publisher.value } : {}),
        ...(langHaschanged.value ? { lang: lang.value } : {}),
        ...(summaryHaschanged.value ? { summary: summary.value } : {}),
        ...(typeHaschanged.value ? { type: varType } : {}),
        ...(hasAdultConsiderationHaschanged.value
          ? { hasAdultConsideration: hasAdultConsideration.value }
          : {}),
        customAttributes: varCustomAttributes,
      },
    },
    refetchQueries: [
      {
        query: GET_PERIODICAL_QUERY,
        variables: { origin: varOrigin, identifier: identifier.value },
      },
    ],
  };
}

function tryToPerformChanges() {
  periodicalUpdatedSuccessfully.value = false;
  saveChanges();
}

function defineCustomAttributes(
  customAttributes:
    | {
        key: string;
        value: string;
      }[]
    | undefined
) {
  let ftpServer = '';
  let ftpPath = '';

  if (customAttributes) {
    for (const customAttribute of customAttributes) {
      if (customAttribute.key == FTP_SERVER_KEY) {
        ftpServer = customAttribute.value;
      }
      if (customAttribute.key == FTP_PATH_KEY) {
        ftpPath = customAttribute.value;
      }
    }
  }

  return [
    { key: FTP_SERVER_KEY, value: ftpServer },
    { key: FTP_PATH_KEY, value: ftpPath },
  ];
}

onSaveDone(() => {
  periodicalUpdatedSuccessfully.value = true;
});
</script>
<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}

.form-input-required {
  @apply focus:border-red-500 focus:ring-red-500 border-red-500;
}
</style>
