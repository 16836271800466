<template>
  <template v-if="loading">Loading...</template>
  <template v-else-if="result?.namespace">
    <h2 class="page-title">{{ result.namespace.name }}</h2>
    <h3>Related namespaces</h3>
    <section>
      <h4>Content providers</h4>
      <p class="description">
        The current namespace can create licenses for content in these
        namespaces.
      </p>
      <related-namespaces
        :namespace-id="(route.params.id as string)"
        listed-role="allowedContentProviders"
      />
    </section>
    <section>
      <h4>Licensers</h4>
      <p class="description">
        These namespaces can create licenses for content in the current
        namespace.
      </p>
      <related-namespaces
        :namespace-id="(route.params.id as string)"
        listed-role="allowedLicensers"
      />
    </section>
  </template>
</template>

<script lang="ts" setup>
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';

import { graphql } from '../../../__generated__/gql';

import RelatedNamespaces from './RelatedNamespaces.vue';

const route = useRoute();

const { result, loading } = useQuery(
  graphql(`
    query RelatedNamespacesParent($id: ID!) {
      namespace(id: $id) {
        id
        name
      }
    }
  `),
  { id: route.params.id as string }
);
</script>

<style scoped>
section {
  @apply bg-slate-200 rounded-lg my-8 p-4;
}

h3 {
  @apply text-lg font-semibold mb-1;
}

h4 {
  @apply font-semibold mb-1;
}

.description {
  @apply mb-6;
}
</style>
