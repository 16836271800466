<template>
  <button
    @click.stop="checkStorage() ? signedURL() : urlClicked()"
    :class="
      props.buttonSize === 'large'
        ? 'inline-flex justify-center items-center space-x-2 border rounded-lg font-normal px-3 py-2 leading-6 border-indigo-700 bg-indigo-700 text-indigo-100 hover:border-indigo-300 hover:text-indigo-300 hover:shadow-sm focus:ring focus:ring-indigo-300 focus:ring-opacity-25 active:border-indigo-200 active:shadow-none dark:border-indigo-200 dark:bg-indigo-200 dark:hover:border-indigo-300 dark:hover:bg-indigo-300 dark:focus:ring-indigo-500 dark:focus:ring-opacity-50 dark:active:border-indigo-200 dark:active:bg-indigo-200'
        : ''
    "
  >
    <svg
      class="hi-outline hi-document-download inline-block"
      :class="props.buttonSize === 'small' ? 'w-5 h-5' : 'w-4 h-4'"
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
    <span v-if="props.buttonSize === 'large'">Download</span>
  </button>
</template>

<script setup lang="ts">
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloError } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import dayjs from 'dayjs';
import { inject } from 'vue';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';

const props = defineProps<{
  file: {
    id: string;
    location: string;
  };
  buttonSize: string;
}>();

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const checkStorage = () => {
  return props.file.location.slice(0, 2) == 'gs' ? true : false;
};

const toast = useToast();

const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const signedURL = async () => {
  try {
    if (props.file) {
      const signUrlResult = await apolloClient.query({
        query: graphql(`
          query signedURL($fileId: ID!, $expires: DateTime!) {
            file(id: $fileId) {
              signedURL(expires: $expires, webReaderProtection: false) {
                link {
                  href
                }
              }
            }
          }
        `),
        variables: {
          fileId: props.file.id,
          expires: dayjs().add(1, 'minute').toISOString(),
        },
      });
      if (signUrlResult.data.file?.signedURL) {
        window.location = signUrlResult.data.file.signedURL.link.href;
      }
    }
  } catch (error: any) {
    if (error.graphQLErrors?.[0]?.extensions?.code === 'FORBIDDEN') {
      popErrorToast('Forbidden', 'User lacks the right to download this file.');
    } else {
      popErrorToast('Error', error?.message);
      throw error;
    }
  }
};

const urlClicked = () => {
  window.open(props.file.location, '_blank');
};
</script>
<style></style>
