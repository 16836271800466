<template>
  <div class="space-y-1">
    <label class="font-medium italic">{{ label }}</label>
    <ul :class="['list', { 'list-changed': hasChanged }]">
      <li
        class="p-4 flex justify-between items-center"
        v-for="range in internalModel"
      >
        <span class="font-semibold text-sm mr-1"
          >{{ range.from }} to {{ range.to }}</span
        >
        <span>
          <dm-button
            variant="danger"
            @click.prevent="removeRange(range)"
            size="xs"
            >remove</dm-button
          >
        </span>
      </li>

      <li class="p-4 flex justify-between items-center">
        <span class="mr-3">
          <input
            v-model="newFrom"
            class="form-input"
            id="form-elements-age-range-from-label"
            type="number"
            placeholder="From"
          />
        </span>
        -
        <span class="ml-3 mr-3">
          <input
            v-model="newTo"
            class="form-input"
            id="form-elements-age-range-to-label"
            type="number"
            placeholder="To"
          />
        </span>
        <span>
          <dm-button
            size="xs"
            :enabled="errors.length === 0"
            @click.prevent="addRange"
            >Add</dm-button
          >
        </span>
      </li>
    </ul>
    <!-- Alerts: Danger -->
    <div
      v-if="errors.length > 0"
      class="rounded bg-red-100 p-4 text-red-700 dark:bg-red-900 dark:bg-opacity-75 dark:text-red-100 md:p-5"
    >
      <div class="mb-3 flex items-center">
        <svg
          class="hi-solid hi-x-circle mr-3 inline-block h-6 flex-none text-red-500 dark:text-red-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
        <h3 class="font-semibold">Please fix the following errors:</h3>
      </div>
      <ul class="ml-8 list-inside space-y-2">
        <li v-for="error in errors" class="flex items-center">
          <svg
            class="hi-solid hi-arrow-narrow-right mr-2 inline-block h-4 flex-none"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          {{ error }}
        </li>
      </ul>
    </div>
    <!-- END Alerts: Danger -->
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue';

type Ranges = { from: number; to: number }[];

const props = defineProps<{
  label: string;
  initialValue: Ranges;
  from: number;
  to: number;
  modelValue: Ranges;
  validation: ({
    from,
    to,
  }: {
    from: number | '';
    to: number | '';
  }) => string[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: Ranges): void;
  (e: 'differs', value: boolean): void;
}>();

const newFrom = ref<number>(props.from);
const newTo = ref<number>(props.to);

const errors = ref<string[]>([]);

const internalModel: Ref<{ from: number; to: number }[]> = ref(
  JSON.parse(JSON.stringify(props.initialValue))
);

const hasChanged = computed(
  () =>
    internalModel.value.length != props.initialValue.length ||
    !!props.initialValue.find(
      (iv) =>
        !internalModel.value.find(
          (mv) => iv.from === mv.from && iv.to === mv.to
        )
    )
);

function addRange() {
  const validationErrors = props.validation({
    from: newFrom.value,
    to: newTo.value,
  });
  if (validationErrors.length > 0) {
    return;
  }

  internalModel.value = internalModel.value || [];
  internalModel.value.push({
    from: newFrom.value,
    to: newTo.value,
  });
  newFrom.value = props.from;
  newTo.value = props.to;
}

const removeRange = (range: { from: number; to: number }) => {
  if (internalModel.value) {
    internalModel.value = internalModel.value.filter(
      (c) => !(c.from == range.from && c.to == range.to)
    );
  }
};

onMounted(() => {
  watch(props, (newProps) => {
    internalModel.value = newProps.modelValue;
  });
  emit('update:modelValue', internalModel.value);
});

watch(
  internalModel,
  (newVal) => {
    emit('update:modelValue', newVal);
    emit('differs', hasChanged.value);
  },
  { deep: true }
);

watch([newFrom, newTo], () => {
  errors.value = props.validation({ from: newFrom.value, to: newTo.value });
});
</script>
<style scoped>
.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.list-changed {
  @apply border-purple-500  divide-purple-500;
}

.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
