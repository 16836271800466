import dayjs from 'dayjs';
import * as himarc from 'himarc';
import { isArray, isObject } from 'lodash';
import { subjects } from './marc21-subjects.json';
import {
  Audience,
  SubjectScheme,
  PublicationMetadata,
  PublicationNature,
} from './__generated__/graphql';

interface Publication
  extends Omit<PublicationMetadata, 'collections' | 'nature'> {
  isbn: string;
  //New interface with nullable nature property
  nature: PublicationNature | null;
}

/*Interface with the marc publication fields to search*/
interface MarcToPublication {
  isbn: any;
  nature1: any;
  nature2: any;
  title: any;
  subtitle: any;
  author: any;
  pageCount: any;
  ageRangeAudiences: any;
  lang: any;
  audiences: any;
  subjects: any;
  summary: any;
  publisher: any;
  datePublished1: any;
  datePublished2: any;
  datePublished3: any;
}
/*Defined marc21 fields */
const MARC_TO_PUBLICATION: MarcToPublication = {
  isbn: '020',
  nature1: '037',
  nature2: '256',
  title: '245',
  subtitle: '245',
  author: '100',
  pageCount: '300',
  ageRangeAudiences: '591',
  lang: '041',
  audiences: '521',
  subjects: '650',
  summary: '520',
  publisher: '260',
  datePublished1: '263',
  datePublished2: '264',
  datePublished3: '260',
};

/*Function to extract the interesting field
/*There are two possibilities:
- Array structure inside Marc21, ex:format1 && format 2
  [{…},{…}]
    format1
    0: {indicator1: "\\",indicator2:"\\",subFields:Array(1)}
      subFields = 0:{F:'Digital download'}
    1: {indicator1: "\\",indicator2:"\\",subFields:Array(2)}
      subFields = 0:{n:'Available'},1:{b:'COMERCIAL GRUPO ANAYA, S.A., [+34-913938600], [+34-913209129], [cga@anaya.es]'}
    format2
    0: {indicator1: "\\",indicator2:"\\",subFields:Array(1)}
      subFields = 0:{a:'EPUB'}
- Object structure inside Marc21, ex:Title
  {indicator1: "\\",indicator2:"\\",subFields:Array(1)}
    subFields = 0:{a:'Seis propuestas para el proximo milenio'}
*/
function extractMarcField(marcValue: any, keyField: string = 'a') {
  if (isArray(marcValue)) {
    for (const actualParameter of marcValue) {
      if (isArray(actualParameter.subFields)) {
        for (const actualSubField of actualParameter.subFields) {
          for (let key in actualSubField) {
            if (key == keyField) return actualSubField[key];
          }
        }
      }
    }
  } else if (marcValue?.hasOwnProperty('subFields')) {
    if (isArray(marcValue.subFields)) {
      for (const actualSubField of marcValue.subFields) {
        for (let key in actualSubField) {
          if (key == keyField) {
            return actualSubField[key];
          }
        }
      }
    }
  }
  return '';
}
function getLang(langMarc: any): string {
  const MarcField = extractMarcField(langMarc);
  if (MarcField != '') {
    const lang = MarcField.toLowerCase().replace(/\s/g, '');
    return lang.match(new RegExp('(spa|fra|en|cat|baq)', 'i')) ? lang : 'spa';
  }
  return 'spa';
}
function getSummary(summaryMarc: any): string {
  const MarcField = extractMarcField(summaryMarc);
  return MarcField != '' ? stripHtmlTags(MarcField) : '';
}
function getIsbn(isbnMarc: any, errors: Array<string>): string {
  const MarcField = extractMarcField(isbnMarc);
  if (MarcField == '') errors.push("This publication doesn't have isbn");
  return MarcField.toLowerCase().replace(/\s/g, '');
}
function getSubtitle(subtitleMarc: any): string | null {
  const result = extractMarcField(subtitleMarc, 'b');
  return result == '' ? null : result;
}
function getPublisher(publisherMarc: any, errors: Array<string>): string {
  const MarcField = extractMarcField(publisherMarc, 'b');
  if (MarcField == '') errors.push("This publication doesn't have publisher");
  return MarcField;
}
function getNaturePublication(
  nature1Marc: any,
  nature2Marc: any,
  errors: Array<string>
): PublicationNature | null {
  const regexpAudio = new RegExp('(MP3|audio)', 'i');
  const regexpPDF = new RegExp('PDF', 'i');
  const regexpEPUB = new RegExp('EPUB', 'i');

  const nature1 = extractMarcField(nature1Marc, 'f');
  if (nature1) {
    if (nature1.match(regexpPDF)) return 'PDF_BOOK';
    if (nature1.match(regexpAudio)) return 'AUDIO_BOOK';
    if (nature1.match(regexpEPUB)) return 'EPUB_BOOK';
  }

  const nature2 = extractMarcField(nature2Marc);
  if (nature2) {
    if (nature2.match(regexpPDF)) return 'PDF_BOOK';
    if (nature2.match(regexpAudio)) return 'AUDIO_BOOK';
    if (nature2.match(regexpEPUB)) return 'EPUB_BOOK';
  }

  errors.push("This publication doesn't have the right nature");
  return null;
}
function getTitle(titleMarc: any, errors: Array<string>): string {
  const MarcField = extractMarcField(titleMarc);
  if (MarcField == '') errors.push("This publication doesn't have title");
  return MarcField;
}
function capitalizeAuthor(author: string): string {
  if (author) {
    author = author.replace(/\s\s+/g, ' ');
    const words = author.split(' ');
    for (let i = 0; i < words.length; i++) {
      if (words[i] != 'de' || words[i].length >= 2) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
    }
    return words.join(' ');
  }
  return '';
}
function stripHtmlTags(sentence: string): string {
  return sentence.replace(/(<([^>]+)>)/gi, '');
}
function getAuthor(
  authorMarc: any,
  errors: Array<string>
): { name: string } | null {
  const MarcField = extractMarcField(authorMarc);
  if (MarcField == '') {
    errors.push("This publication doesn't have author");
    return null;
  }
  return { name: capitalizeAuthor(MarcField) };
}
function getDataFormated(dataPublishedMarc: any, identifier: string): string {
  const originalDate = dayjs(
    extractMarcField(dataPublishedMarc, identifier),
    'YYYYMMDD'
  );
  return originalDate.isValid() ? originalDate.toISOString() : '';
}
function getDataPublished(
  dataPublished1Marc: any,
  dataPublished2Marc: any,
  dataPublished3Marc: any
): string {
  let publishedData = '';
  publishedData = getDataFormated(dataPublished1Marc, 'a');
  if (publishedData == '') {
    publishedData = getDataFormated(dataPublished2Marc, 'c');
    if (publishedData == '') {
      publishedData = getDataFormated(dataPublished3Marc, 'c');
      if (publishedData == '') {
        publishedData = dayjs('19900101').toDate().toISOString();
      }
    }
  }
  return publishedData;
}
function getPageCount(pageCountMarc: any): number | null {
  if (isArray(pageCountMarc)) {
    for (const actualParameter of pageCountMarc) {
      if (isArray(actualParameter.subFields)) {
        let foundPagesIndicator = false;
        for (const actualSubField of actualParameter.subFields) {
          for (let key in actualSubField) {
            if (key == 'f')
              if (actualSubField[key].match(new RegExp('pages', 'i')))
                foundPagesIndicator = true;
            if (key == 'a' && foundPagesIndicator) {
              return Number(actualSubField[key]);
            }
          }
        }
      }
    }
  } else if (isObject(pageCountMarc)) {
  }
  return null;
}
function getAudiences(audiencesMarc: any): { code: Audience }[] {
  if (isArray(audiencesMarc)) {
    const regexpYoungAdultChildrenJuvenile = new RegExp(
      '(youngadult|children/juvenile|children)',
      'i'
    );
    const regexpAdultos = new RegExp('adultos', 'i');
    return audiencesMarc.reduce(
      (audiences: { code: Audience }[], currentAudience: any) => {
        if (isArray(currentAudience.subFields)) {
          for (const subfieldValue of currentAudience.subFields) {
            for (let key in subfieldValue) {
              if (key == 'a') {
                const currentValue = subfieldValue[key]
                  .replace(/\s/g, '')
                  .toLowerCase();
                if (currentValue.match(regexpYoungAdultChildrenJuvenile))
                  audiences.push({ code: 'YOUTH' });
                else if (currentValue.match(regexpAdultos))
                  audiences.push({ code: 'ADULT' });
              }
            }
          }
        }
        return audiences;
      },
      []
    );
  }
  return [];
}
function getAgeRangeAudiencesPublication(
  ageRangeMarc: any
): { from: number; to: number }[] {
  if (ageRangeMarc?.hasOwnProperty('subFields')) {
    const regexAges = new RegExp('ages', 'i');
    return ageRangeMarc.subFields.reduce(
      (ageRangeAudiences: { from: number; to: number }[], currentAge: any) => {
        Object.values(currentAge).forEach((val): void => {
          let splitted = String(val).trim().split(':');
          if (splitted.length > 0) {
            if (splitted[0].match(regexAges)) {
              if (splitted[1].includes('+')) {
                ageRangeAudiences.push({
                  from: +splitted[1].split('+')[0],
                  to: 99,
                });
              } else if (splitted[1].includes('-')) {
                const agesFromTo = splitted[1].split('-');
                ageRangeAudiences.push({
                  from: +agesFromTo[0],
                  to: +agesFromTo[1],
                });
              }
            }
          }
        });
        return ageRangeAudiences;
      },
      []
    );
  }
  return [];
}
function getSubjects(
  subjectsMarc: any
): { scheme: SubjectScheme; code: string }[] {
  if (isArray(subjectsMarc)) {
    return subjectsMarc.reduce(
      (
        BICSsubjects: { scheme: SubjectScheme; code: string }[],
        currentValue: any
      ) => {
        let subject = extractMarcField(currentValue);
        if (subject != '') {
          subject = subject
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f-\s]/g, '');
          const subjectComplete = subjects.find((currentValue) => {
            return (currentValue.category == subject &&
              currentValue.subcategory == '') ||
              currentValue.subcategory == subject
              ? true
              : false;
          });
          if (subjectComplete?.BIC) {
            BICSsubjects.push({ scheme: 'BIC', code: subjectComplete.BIC });
          }
        }
        return BICSsubjects;
      },
      []
    );
  }
  return [];
}
/*This function returns each Marc21 register in a Mrc file*/
export async function registerOfMarc21File(file: any): Promise<any[] | null> {
  let data = await file.arrayBuffer();
  const uint8array = new TextDecoder().decode(data);
  const dataFieldTokens = uint8array.split(
    String.fromCharCode(0x1e) + String.fromCharCode(0x1d)
  );
  dataFieldTokens.splice(dataFieldTokens.length - 1);
  return dataFieldTokens;
}
/*This function following the MARC_TO_PUBLICATION declarations returns the publication object formatized*/
export function marc21Format(dataFieldTokens: Array<any>) {
  let publicationsArray: Publication[] = [];
  let errorsForPublications: Array<any> = [];
  let errorsForPublication: Array<string> = [];

  dataFieldTokens.map((register: any) => {
    const fileMarcToObject = himarc.mrcToObject(register);
    const returnObjectPublication: MarcToPublication = {
      isbn: {},
      nature1: {},
      nature2: {},
      title: {},
      subtitle: {},
      author: {},
      pageCount: {},
      ageRangeAudiences: {},
      lang: {},
      audiences: {},
      subjects: {},
      summary: {},
      publisher: {},
      datePublished1: {},
      datePublished2: {},
      datePublished3: {},
    };
    for (const [key, value] of Object.entries(MARC_TO_PUBLICATION)) {
      Object.assign(returnObjectPublication, {
        [key]: fileMarcToObject[value],
      });
    }
    errorsForPublication = [];
    let publication = {
      nature: getNaturePublication(
        returnObjectPublication['nature1'],
        returnObjectPublication['nature2'],
        errorsForPublication
      ),
      isbn: getIsbn(returnObjectPublication['isbn'], errorsForPublication),
      ageRangeAudiences: getAgeRangeAudiencesPublication(
        returnObjectPublication['ageRangeAudiences']
      ),
      title: getTitle(returnObjectPublication['title'], errorsForPublication),
      subtitle: getSubtitle(returnObjectPublication['subtitle']),
      author: getAuthor(
        returnObjectPublication['author'],
        errorsForPublication
      ),
      summary: getSummary(returnObjectPublication['summary']),
      lang: getLang(returnObjectPublication['lang']),
      pageCount: getPageCount(returnObjectPublication['pageCount']),
      publisher: getPublisher(
        returnObjectPublication['publisher'],
        errorsForPublication
      ),
      audiences: getAudiences(returnObjectPublication['audiences']),
      published: getDataPublished(
        returnObjectPublication['datePublished1'],
        returnObjectPublication['datePublished2'],
        returnObjectPublication['datePublished3']
      ),
      paperIsbn: null,
      description: null,
      contributors: [],
      subjects: getSubjects(returnObjectPublication['subjects']),
      hasAdultConsideration: false,
    };
    publicationsArray.push(publication);
    errorsForPublications.push(errorsForPublication);
  });
  return [publicationsArray, errorsForPublications];
}
