<template>
  <!-- Command Palettes: Boxed with Icons -->
  <div class="z-30">
    <!-- Placeholder -->
    <div class="flex flex-col items-center justify-center gap-5 rounded-lg">
      <!-- Toggle Button -->
      <button
        type="button"
        class="group inline-flex min-w-56 items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-2 py-2 font-medium leading-6 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none"
        @click="showModal = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-slot="icon"
          class="hi-mini hi-magnifying-glass inline-block size-5 h-5 opacity-60 group-hover:text-blue-600 group-hover:opacity-100"
        >
          <path
            fill-rule="evenodd"
            d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="grow text-left opacity-60 group-hover:opacity-100">
          Search...
        </span>
        <span class="px-4 flex-none text-xs font-semibold opacity-75">
          <span class="opacity-75">
            {{ isMacOS ? '⌘ K' : 'Ctrl-K' }}
          </span>
        </span>
      </button>
      <!-- END Toggle Button -->
    </div>
    <!-- END Placeholder -->

    <!-- Backdrop -->
    <div
      v-show="showModal"
      @keydown.esc="goEsc"
      @keydown.left="goEsc"
      @click.self="showModal = false"
      @keydown.up="goUp"
      @keydown.down="goDown"
      @keydown.right="goRight"
      @keydown.enter="goEnter"
      class="fixed inset-0 z-100 overflow-y-auto overflow-x-hidden bg-gray-300/75 p-4 backdrop-blur-sm md:py-8 lg:px-8 lg:py-16"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
    >
      <!-- Command Palette -->
      <div
        class="mx-auto flex max-h-full h-full w-full max-w-4xl flex-col rounded-xl shadow-xl"
        role="document"
      >
        <!-- Search Input -->
        <div
          class="relative rounded-t-lg border-b border-gray-200/75 bg-white p-3"
        >
          <div class="flex w-full items-center rounded-lg bg-gray-100 px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              data-slot="icon"
              class="hi-mini hi-magnifying-glass inline-block size-5 h-5 opacity-50"
            >
              <path
                fill-rule="evenodd"
                d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              type="text"
              class="w-full border-none bg-transparent py-3 placeholder:text-gray-500 focus:outline-none focus:ring-0"
              placeholder="Search..."
              tabindex="0"
              role="combobox"
              aria-expanded="true"
              aria-autocomplete="list"
              ref="searchInput"
              v-model="searchKeyword"
            />
          </div>
        </div>
        <!-- EMD Search Input -->

        <div class="overflow-x-hidden relative flex-grow bg-white">
          <div class="w-full h-full">
            <!-- Listbox -->
            <ul
              v-if="results.length > 0"
              class="h-full overflow-auto bg-white p-3 space-y-2"
              role="listbox"
            >
              <li
                v-for="(result, i) in results"
                class="group flex cursor-pointer items-center justify-between gap-2 rounded-lg px-3 text-sm"
                :class="
                  selectedId == result.id
                    ? 'bg-blue-600 text-white'
                    : !isPinnedResults(result)
                    ? 'bg-white text-gray-600'
                    : 'bg-gray-100 text-gray-600'
                "
                role="option"
                tabindex="-1"
                aria-selected="false"
                @click="handleClickResult(i)"
                @mouseenter="handleMouseEnter(i)"
                :key="result.id"
              >
                <div class="flex grow items-center gap-3 py-2">
                  <div
                    class="flex flex-none items-center p-2 justify-center rounded-lg"
                    :class="
                      selectedId == result.id ? 'bg-blue-700' : 'bg-gray-200/75'
                    "
                    :id="
                      selectedId == result.id ? 'command-palette-selected' : ''
                    "
                  >
                    <SearchResultIcon :resultType="result.type" />
                  </div>
                  <div class="grow">
                    <div class="mb-0.5 font-medium">{{ result.title }}</div>
                    <div class="text-xs opacity-90">
                      {{ result.description }}
                    </div>
                  </div>
                  <div
                    v-if="result.nature && result.nature?.length > 0"
                    class="font-semibold inline-flex p-1 items-center text-xs rounded mt-0.5"
                    :class="colorAccordingToPublicationNature(result.nature)"
                  >
                    {{ splitPublicationNature(result.nature) }}
                  </div>
                </div>
                <div
                  class="cursor-pointer p-1 rounded-lg"
                  @click.stop.prevent="goRight()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    data-slot="icon"
                    class="hi-micro hi-chevron-double-right inline-block size-5 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.78 7.595a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06l2.72-2.72-2.72-2.72a.75.75 0 0 1 1.06-1.06l3.25 3.25Zm-8.25-3.25 3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06l2.72-2.72-2.72-2.72a.75.75 0 0 1 1.06-1.06Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </li>
              <div
                v-if="searchKeyword && loading"
                class="loading-ellipsis text-center text-base text-gray-500 space-y-3 py-1.5"
              >
                Loading
              </div>
              <!-- No Results Feedback -->
              <div
                v-else-if="searchKeyword && searchResults.length === 0"
                class="bg-white p-3"
              >
                <div class="space-y-3 py-1.5 text-center text-sm text-gray-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    data-slot="icon"
                    class="hi-solid hi-briefcase inline-block size-5 h-5 opacity-50"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                      clip-rule="evenodd"
                    />
                    <path
                      d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z"
                    />
                  </svg>
                  <p>No results</p>
                </div>
              </div>
              <!-- END No Results Feedback -->
            </ul>
            <!-- END Listbox -->
          </div>

          <!-- Highlighted Project Info -->
          <div
            class="absolute z-10 bottom-0 top-0 right-0 min-h-72 shadow-2xl items-center p-6 overflow-auto bg-white duration-75"
            :class="{
              'translate-x-full w-0 px-0': !showDetails,
              'translate-x-0 w-[98%]': showDetails,
            }"
          >
            <!-- Project Info -->
            <div
              class="w-full text-center"
              :class="
                selectedResult?.type === 'publication' ? 'grid grid-cols-2' : ''
              "
            >
              <div>
                <button @click="goBackArrow" class="text-left flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    data-slot="icon"
                    class="hi-outline hi-arrow-uturn-left inline-block size-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
                    />
                  </svg>
                </button>

                <div v-if="selectedResult?.type === 'publication'" class="mb-1">
                  <span
                    v-if="
                      !publicationPreviewResult && publicationPreviewLoading
                    "
                    >Loading preview...
                  </span>
                  <img
                    v-else-if="coverSrc"
                    :src="coverSrc"
                    alt="Cover Preview"
                    class="mx-auto shadow-slate-500 shadow-sm rounded-sm object-contain max-h-40"
                  />
                </div>
                <h3
                  class="text-xl semibold font-medium tracking-wider uppercase"
                >
                  {{ selectedResult?.title }}
                </h3>
                <p
                  v-if="selectedResult?.type === 'publication'"
                  class="text-gray-500"
                >
                  {{ publicationPreviewResult?.data?.publication?.isbn }} |
                  <span
                    v-if="
                      selectedResult.nature && selectedResult.nature?.length > 0
                    "
                    class="font-semibold p-0.5 my-auto text-sm rounded"
                    :class="
                      colorAccordingToPublicationNature(selectedResult.nature)
                    "
                  >
                    {{ splitPublicationNature(selectedResult.nature) }}</span
                  >
                </p>
                <p class="text-gray-500">
                  {{ selectedResult?.description }}
                </p>
              </div>
              <ul class="my-2 w-2/3 mx-auto">
                <li
                  class="my-2 cursor-pointer"
                  v-for="(action, i) in selectedActions"
                  :key="action.label"
                >
                  <div
                    class="rounded-lg border border-gray-300 px-1 py-1"
                    :class="{
                      'border-white bg-blue-600 text-white':
                        i == actionSelected,
                    }"
                    :id="
                      i == actionSelected
                        ? 'command-palette-selected-action'
                        : ''
                    "
                    @click="
                      action.action();
                      showModal = false;
                      resetSearch();
                    "
                    @mouseenter="handleMouseEnter(i)"
                  >
                    {{ action.label }}
                  </div>
                </li>
              </ul>
            </div>
            <!-- END Project Info -->
          </div>
        </div>

        <!-- Footer -->
        <div
          class="flex flex-wrap items-center gap-3.5 rounded-b-xl bg-gray-100 p-3"
        >
          <div class="inline-flex items-center gap-1.5 text-xs font-medium">
            <div
              class="rounded-lg border border-gray-300 px-1 py-0.5 font-semibold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                data-slot="icon"
                class="hi-micro hi-arrow-up inline-block size-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 14a.75.75 0 0 1-.75-.75V4.56L4.03 7.78a.75.75 0 0 1-1.06-1.06l4.5-4.5a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1-1.06 1.06L8.75 4.56v8.69A.75.75 0 0 1 8 14Z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                data-slot="icon"
                class="hi-micro hi-arrow-down inline-block size-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.75.75 0 0 1 .75.75v8.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.22 3.22V2.75A.75.75 0 0 1 8 2Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-gray-600">to navigate</span>
          </div>
          <div class="inline-flex items-center gap-1.5 text-xs font-medium">
            <div
              class="rounded-lg border border-gray-300 px-1 py-0.5 font-semibold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                data-slot="icon"
                class="hi-micro hi-arrow-right inline-block size-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M2 8a.75.75 0 0 1 .75-.75h8.69L8.22 4.03a.75.75 0 0 1 1.06-1.06l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06-1.06l3.22-3.22H2.75A.75.75 0 0 1 2 8Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <span class="text-gray-600">to see more options</span>
          </div>
          <div class="inline-flex items-center gap-1.5 text-xs font-medium">
            <div
              class="rounded-lg border border-gray-300 px-1 py-0.5 font-semibold"
            >
              Enter
            </div>
            <span class="text-gray-600">to navigate</span>
          </div>
          <div class="inline-flex items-center gap-1.5 text-xs font-medium">
            <div
              class="rounded-lg border border-gray-300 px-1 py-0.5 font-semibold"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                data-slot="icon"
                class="hi-micro hi-arrow-left inline-block size-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 8a.75.75 0 0 1-.75.75H4.56l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 1.06L4.56 7.25h8.69A.75.75 0 0 1 14 8Z"
                  clip-rule="evenodd"
                />
              </svg>
              ESC
            </div>
            <span class="text-gray-600">to close</span>
          </div>
        </div>
        <!-- END Footer -->
      </div>
      <!-- END Command Palette -->
    </div>
    <!-- END Backdrop -->
  </div>
  <!-- END Command Palettes: Boxed with Icons -->
</template>

<script setup lang="ts">
import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { DefaultApolloClient, useLazyQuery } from '@vue/apollo-composable';
import urlTemplate from 'url-template';
import {
  computed,
  nextTick,
  ref,
  onMounted,
  onUnmounted,
  watch,
  Ref,
  inject,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';
import {
  CommandPaletteSearchQuery,
  GetPublicationPreviewQuery,
} from '../../__generated__/graphql';

import { useAuth } from '../../auth';
import {
  validPublicationIdentifier,
  splitPublicationNature,
  colorAccordingToPublicationNature,
} from '../../utils';
import { GLOBAL_PAGE_ACTIONS } from '../../main';
import { getActions } from '../../resourceActions';
import SearchResultIcon, { ResultType } from './SearchResultIcon.vue';

const { canReadResourceTypeOnAtLeastOneNamespace } = useAuth();

export type Action = {
  label: string;
  action: () => void;
};

type Result = {
  id: string;
  type: ResultType;
  title: string;
  description: string;
  nature?: string;
  actions: Action[] | (() => Action[]);
};

const router = useRouter();
const route = useRoute();

const globalPageActions = inject(GLOBAL_PAGE_ACTIONS)!;

const searchInput: Ref<HTMLInputElement | null> = ref(null);
const searchKeyword: Ref<string | null> = ref('');
const loading: Ref<boolean> = ref(false);

const searchResults: Ref<Result[]> = ref([]);
const currentResourceActions: Ref<Result | null> = computed(() => {
  if (route.params.id) {
    const currentResource =
      (route?.params?.id as string)?.split('/')?.[3]?.slice(0, -1) ??
      'resource';
    return {
      id: `actual-${route.params.id as string}`,
      type: 'action',
      title: `Actions on the current ${currentResource}`,
      description: 'By default, copies the resource id.',
      actions: globalPageActions(),
    };
  } else {
    return null;
  }
});

const routingSection: Ref<Result> = computed(() => {
  const actions: Action[] = [
    {
      label: 'Dashboard',
      action: () => {
        router.push({ name: 'home' });
      },
    },
  ];
  if (canReadResourceTypeOnAtLeastOneNamespace('namespaces')) {
    actions.push({
      label: 'Manage Namespaces',
      action: () => {
        router.push({ name: 'namespaces' });
      },
    });
  }
  if (canReadResourceTypeOnAtLeastOneNamespace('licenses')) {
    actions.push({
      label: 'Manage Licenses',
      action: () => {
        router.push({ name: 'licenses' });
      },
    });
  }
  if (canReadResourceTypeOnAtLeastOneNamespace('publications')) {
    actions.push({
      label: 'Manage Publications',
      action: () => {
        router.push({ name: 'publications' });
      },
    });
  }
  if (canReadResourceTypeOnAtLeastOneNamespace('periodicals')) {
    actions.push({
      label: 'Manage Periodicals',
      action: () => {
        router.push({ name: 'periodicals' });
      },
    });
    actions.push({
      label: 'Manage Subscriptions',
      action: () => {
        router.push({ name: 'periodicalSubscriptions' });
      },
    });
  }

  return {
    id: 'routing',
    type: 'navigation',
    title: 'Navigate the app',
    description: 'By default, navigates to the dashboard.',
    actions,
  };
});

const results: Ref<Result[]> = computed(() => [
  ...(currentResourceActions.value ? [currentResourceActions.value] : []),
  ...[routingSection.value],
  ...searchResults.value,
]);

const isPinnedResults = (result: Result) => {
  if (result.type === 'action' || result.type === 'navigation') {
    return true;
  }
};

const resultSelected = ref(0);
const selectedResult = computed(() => results.value[resultSelected.value]);
const selectedId = computed(() => selectedResult.value?.id);
watch(selectedId, async () => {
  if (selectedId.value) {
    await nextTick();
    document.getElementById('command-palette-selected')?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }
});
const selectedActions = computed(() => {
  let actions = selectedResult.value?.actions;
  if (actions && !Array.isArray(actions)) {
    actions = actions();
  }
  return actions;
});

const handleClickResult = async (i: number) => {
  resultSelected.value = i;
  await nextTick();
  goEnter();
  searchInput.value?.focus();
};

const isUsingKeys = ref(false);

const handleMouseMove = () => {
  isUsingKeys.value = false;
};

const handleMouseEnter = (i: number) => {
  if (!isUsingKeys.value) {
    if (showDetails.value) {
      actionSelected.value = i;
    } else {
      resultSelected.value = i;
    }
  }
};

const actionSelected = ref(0);
watch(actionSelected, async () => {
  await nextTick();
  document.getElementById('command-palette-selected-action')?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
});

const goUp = () => {
  isUsingKeys.value = true;
  if (showDetails.value) {
    if (actionSelected.value == 0) {
      actionSelected.value = selectedActions.value.length - 1;
    } else {
      actionSelected.value--;
    }
  } else {
    if (resultSelected.value == 0) {
      resultSelected.value = results.value.length - 1;
    } else {
      resultSelected.value--;
    }
  }
};

const goDown = () => {
  isUsingKeys.value = true;
  if (showDetails.value) {
    if (actionSelected.value == selectedActions.value.length - 1) {
      actionSelected.value = 0;
    } else {
      actionSelected.value++;
    }
  } else {
    if (resultSelected.value == results.value.length - 1) {
      resultSelected.value = 0;
    } else {
      resultSelected.value++;
    }
  }
};

const showDetails = ref(false);

const goRight = async () => {
  isUsingKeys.value = true;
  actionSelected.value = 0;
  showDetails.value = true;
  if (selectedResult.value?.type === 'publication') {
    publicationPreviewVariables.value.id = selectedResult.value.id;
    await loadPublicationPreview();
  }
};

const goLeft = () => {
  actionSelected.value = 0;
  showDetails.value = false;
};

const goEnter = () => {
  isUsingKeys.value = true;
  showModal.value = false;
  if (showDetails.value) {
    selectedActions.value[actionSelected.value].action();
  } else {
    // default action
    selectedActions.value[0].action();
  }
};

const goEsc = () => {
  isUsingKeys.value = true;
  if (showDetails.value) {
    goLeft();
  } else {
    showModal.value = false;
  }
};

const goBackArrow = () => {
  isUsingKeys.value = true;
  goLeft();
  searchInput.value?.focus();
};

const showModal = ref(false);
watch(showModal, async () => {
  showDetails.value = false;
  if (showModal.value) {
    resetSearch();
    await nextTick();
    searchInput.value?.focus();
  }
});

const openModalShortcut = (ev: KeyboardEvent) => {
  if (ev.key == 'k' && ev.metaKey) {
    showModal.value = !showModal.value;
    ev.preventDefault();
  }
};

const isMacOS = ref(false);

onMounted(() => {
  if (window.navigator.userAgent.includes('Mac OS')) {
    isMacOS.value = true;
  }
  window.addEventListener('keydown', openModalShortcut);
  window.addEventListener('mousemove', handleMouseMove);
});

onUnmounted(() => {
  window.removeEventListener('keydown', openModalShortcut);
  window.removeEventListener('mousemove', handleMouseMove);
});

const itemSelected = (entityId: string, entityType: string) => {
  router.push({
    name: entityType,
    params: {
      id: entityId,
    },
  });
};

const resetSearch = () => {
  resultSelected.value = 0;
  actionSelected.value = 0;
  showDetails.value = false;
  searchKeyword.value = '';
  searchResults.value = [];
};

/*SEARCH AND INSTANT MATCH*/
const identifierMatch = (keyword: string) => {
  if (keyword) {
    loading.value = true;
    const matchData = keyword.match(/^https:\/\/(.+)\/(?<domain>.+)\/(.+)/);
    if (matchData) {
      let name = matchData.groups!['domain'];
      name = name.slice(0, name.length - 1);

      if (name === 'namespace') {
        keyword = matchData[3];
      }

      showModal.value = false;
      router.push({
        name,
        params: { id: keyword },
      });

      resetSearch();
      showModal.value = false;
      loading.value = false;
    }
  }
};

const instantMatchRegExp = '^https:\/\/(.+)\/(?<domain>.+)\/.+';

watch(searchKeyword, async (newValue) => {
  if (newValue != null && newValue.trim().length > 0) {
    if (instantMatchRegExp && newValue.match(instantMatchRegExp)) {
      resetSearch();
      identifierMatch(newValue);
    } else {
      loading.value = true;
      resultSelected.value = 0;
      searchResults.value = await searchGlobal(newValue);
      loading.value = false;
    }
  } else {
    resetSearch();
  }
});

const apolloClient = inject(
  DefaultApolloClient
) as ApolloClient<NormalizedCacheObject>;

const _PUBLICATIONS_FRAGMENT = graphql(`
  fragment PublicationsData on Publication {
    id
    namespaceId
    metadata {
      title
      nature
    }
    namespace {
      name
    }
  }
`);

async function searchGlobal(keyword: string): Promise<Result[]> {
  if (!keyword || keyword.trim().length === 0) {
    return [];
  }
  loading.value = true;
  const searchPublications =
    validPublicationIdentifier(keyword) &&
    canReadResourceTypeOnAtLeastOneNamespace('publications');
  const searchPublicationsByTitle =
    canReadResourceTypeOnAtLeastOneNamespace('publications');
  const searchPeriodicals =
    canReadResourceTypeOnAtLeastOneNamespace('periodicals');
  const searchNamespaces =
    canReadResourceTypeOnAtLeastOneNamespace('namespaces');

  const commandPaletteSearchQuery = graphql(`
    query CommandPaletteSearch(
      $isbn: PublicationIdentifier
      $keyword: String
      $searchPublications: Boolean!
      $searchPublicationsByTitle: Boolean!
      $searchPeriodicals: Boolean!
      $searchNamespaces: Boolean!
    ) {
      publicationsFilterByISBN: publications(
        filterBy: { isbn: $isbn }
        first: 10
      ) @include(if: $searchPublications) {
        edges {
          node {
            ...PublicationsData
          }
        }
      }

      publicationsFilterByTitle: publications(
        filterBy: { title: $keyword }
        first: 10
      ) @include(if: $searchPublicationsByTitle) {
        edges {
          node {
            ...PublicationsData
          }
        }
      }

      periodicals(filterBy: { keyword: $keyword }, first: 10)
        @include(if: $searchPeriodicals) {
        edges {
          node {
            origin
            identifier
            name
            type
          }
        }
      }

      namespaces(filterBy: { keyword: $keyword }, first: 10)
        @include(if: $searchNamespaces) {
        edges {
          node {
            id
            name
            description
          }
        }
      }
    }
  `);

  const variables = {
    isbn: '',
    keyword,
    searchPublications,
    searchPublicationsByTitle,
    searchPeriodicals,
    searchNamespaces,
  };

  if (searchPublications) {
    variables.isbn = keyword;
  }

  let result: ApolloQueryResult<CommandPaletteSearchQuery> | null = null;

  try {
    result = await apolloClient.query({
      query: commandPaletteSearchQuery,
      variables: variables,
    });
  } catch (error) {
    loading.value = false;
    throw error;
  }

  let results: Result[] = [];

  if (searchPublications && result.data?.publicationsFilterByISBN?.edges) {
    const publications: Result[] =
      result.data.publicationsFilterByISBN.edges.map(({ node }) => ({
        id: `${node.id}`,
        type: 'publication',
        title: node.metadata?.title ?? 'Untitled',
        description:
          node.namespace && node.namespace.name
            ? node.namespace.name
            : node.namespaceId,
        nature: node.metadata?.nature ?? '',
        actions: () => [
          {
            label: 'Navigate to publication',
            action: () => itemSelected(node.id, 'publication'),
          },
          ...getActions('publications', node.id),
        ],
      }));
    results = results.concat(publications);
  }

  if (
    searchPublicationsByTitle &&
    result.data?.publicationsFilterByTitle?.edges
  ) {
    const publications: Result[] =
      result.data.publicationsFilterByTitle.edges.map(({ node }) => ({
        id: `${node.id}`,
        type: 'publication',
        title: node.metadata?.title ?? 'Untitled',
        description:
          node.namespace && node.namespace.name
            ? node.namespace.name
            : node.namespaceId,
        nature: node.metadata?.nature ?? '',
        actions: () => [
          {
            label: 'Navigate to publication',
            action: () => itemSelected(node.id, 'publication'),
          },
          ...getActions('publications', node.id),
        ],
      }));

    results = results.concat(publications);
  }

  if (searchPeriodicals && result.data?.periodicals?.edges) {
    const periodicals: Result[] =
      result.data.periodicals.edges.map(({ node }) => ({
        id: `${node.origin}:${node.identifier}`,
        type: 'periodical',
        title: node.name,
        description: `${node.origin}:${node.identifier}`,
        nature: node.type ?? '',
        actions: () => [
          {
            label: 'Navigate to publication',
            action: () =>
              itemSelected(`${node.origin}:${node.identifier}`, 'periodical'),
          },
          ...getActions('periodicals', `${node.origin}:${node.identifier}`),
        ],
      })) ?? [];
    results = results.concat(periodicals);
  }

  if (searchNamespaces && result.data?.namespaces?.edges) {
    const namespaces: Result[] = result.data.namespaces.edges.map(
      ({ node }) => ({
        id: node.id,
        type: 'namespace',
        title: node.name,
        description: `${node.id}${
          node.description ? `: ${node.description}` : ''
        }`,
        actions: () => [
          {
            label: 'Navigate to namespace',
            action: () => itemSelected(node.id, 'namespace'),
          },
          ...getActions('namespaces', node.id),
        ],
      })
    );
    results = results.concat(namespaces);
  }
  loading.value = false;

  if (results) {
    return results;
  } else {
    return [];
  }
}

/* PREVIEW PUBLICATION */
const publicationPreviewVariables = ref({ id: '' });

let publicationPreviewResult: ApolloQueryResult<GetPublicationPreviewQuery> | null =
  null;

let publicationPreviewLoading = ref(true);
let coverSrc = ref(null);

const loadPublicationPreview = async () => {
  publicationPreviewLoading.value = true;
  coverSrc.value = null;

  publicationPreviewResult = await apolloClient.query({
    query: graphql(`
      query GetPublicationPreview($id: ID!) {
        publication(id: $id) {
          isbn
          cover {
            href
            templated
          }
        }
      }
    `),
    variables: publicationPreviewVariables.value,
  });

  if (publicationPreviewResult?.data?.publication) {
    const cover = publicationPreviewResult?.data?.publication?.cover;

    coverSrc.value = cover?.templated
      ? urlTemplate.parse(cover.href).expand({ variant: 'small' })
      : cover?.href;
    publicationPreviewLoading.value = false;
  }
};
</script>

<style scoped></style>
