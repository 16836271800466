<template>
  <!-- Divider: With Button Left -->
  <div class="flex items-center my-8">
    <button
      @click="switchButton()"
      type="button"
      class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-gray-300 bg-white text-gray-800 shadow-sm hover:text-gray-800 hover:bg-gray-100 hover:border-gray-300 hover:shadow focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
    >
      <svg
        v-if="!showContent"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        class="opacity-50 hi-solid hi-arrow-down inline-block w-4 h-4"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <svg
        v-if="showContent"
        fill="currentColor"
        viewBox="0 0 20 20"
        class="opacity-50 hi-solid hi-arrow-up inline-block w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
          clip-rule="evenodd"
        />
      </svg>

      <span> {{ props?.title || '' }}</span>
    </button>
    <span aria-hidden="true" class="grow bg-gray-200 rounded h-0.5"> </span>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from '@vue/reactivity';

const props = defineProps({
  title: { type: String, required: false },
});

const emits = defineEmits<{
  (e: 'showContent'): void;
  (e: 'buttonStatus', status: boolean): void;
}>();

const showContent: Ref<boolean> = ref(false);

const switchButton = () => {
  if (showContent.value === false) {
    showContent.value = true;
    emitShowCardContent();
  } else {
    showContent.value = false;
  }
  emitButtonStatus();
};

const emitShowCardContent = () => {
  emits('showContent');
};

const emitButtonStatus = () => {
  emits('buttonStatus', showContent.value);
};
</script>
