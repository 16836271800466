<template>
  <div
    v-if="
      canReadResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission) ||
      canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
    "
  >
    <div
      class="px-3 pt-5 pb-2 text-xs font-medium uppercase tracking-wider text-gray-400"
    >
      {{ sectionName }}
    </div>
    <router-link
      v-if="
        managePath &&
        canReadResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="managePath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-view-grid inline-block w-5 h-5"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Manage</span>
    </router-link>
    <router-link
      v-if="
        addNewPath &&
        canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addNewPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-plus-circle inline-block w-5 h-5 text-green-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Add New</span>
    </router-link>
    <router-link
      v-if="
        addNewPeriodicalPath &&
        canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addNewPeriodicalPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-plus-circle inline-block w-5 h-5 text-green-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Add New Periodical</span>
    </router-link>
    <router-link
      v-if="
        addManageSubscriptionsPath &&
        canReadResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addManageSubscriptionsPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-view-grid inline-block w-5 h-5"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Manage Subscriptions</span>
    </router-link>
    <router-link
      v-if="
        addPeriodicalSubscriptionsBulkPath &&
        canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addPeriodicalSubscriptionsBulkPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-plus-circle inline-block w-5 h-5 text-green-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Subscriptions Bulk Creation</span>
    </router-link>

    <router-link
      v-if="
        customIngestionHooksPath &&
        canReadResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="customIngestionHooksPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-view-grid inline-block w-5 h-5"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Custom Ingestion Hooks</span>
    </router-link>
    <router-link
      v-if="
        addBatchPath &&
        canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addBatchPath"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-plus-circle inline-block w-5 h-5 text-green-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Upload Excel File</span>
    </router-link>
    <router-link
      v-if="
        addMarc21Path &&
        canWriteResourceTypeOnAtLeastOneNamespace(resourceTypeForPermission)
      "
      :to="addMarc21Path"
      class="flex items-center space-x-3 px-3 font-medium rounded text-gray-300 hover:text-gray-100 hover:bg-gray-800 hover:bg-opacity-50 active:bg-gray-800 active:bg-opacity-25"
    >
      <span class="flex-none flex items-center opacity-50">
        <svg
          class="hi-outline hi-plus-circle inline-block w-5 h-5 text-green-300"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <span class="py-2 flex-grow">Upload Marc21 File</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { useAuth, existingResourceTypes } from '../auth';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SidebarResourceSection',
  props: {
    sectionName: {
      type: String,
      required: true,
    },
    resourceTypeForPermission: {
      type: String as PropType<existingResourceTypes>,
      required: true,
    },
    managePath: {
      type: String,
      required: false,
    },
    addNewPath: {
      type: String,
      required: false,
    },
    addNewPeriodicalPath: {
      type: String,
      required: false,
    },
    addBatchPath: {
      type: String,
      required: false,
    },
    addMarc21Path: {
      type: String,
      required: false,
    },
    addManageSubscriptionsPath: {
      type: String,
      required: false,
    },
    customIngestionHooksPath: {
      type: String,
      required: false,
    },
    addPeriodicalSubscriptionsBulkPath: {
      type: String,
      required: false,
    },
  },
  setup() {
    let {
      canReadResourceTypeOnAtLeastOneNamespace,
      canWriteResourceTypeOnAtLeastOneNamespace,
    } = useAuth();
    return {
      canReadResourceTypeOnAtLeastOneNamespace,
      canWriteResourceTypeOnAtLeastOneNamespace,
    };
  },
});
</script>

<style scoped>
.router-link-active {
  @apply text-gray-100;
  @apply bg-gray-800;
  @apply bg-opacity-50;
}
</style>
