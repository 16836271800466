<template>
  <div class="inline-block relative group">
    <div
      v-if="props.activateIndicator"
      :class="
        props.indicatorColor
          ? 'absolute top-0 left-0 w-3 h-3 -mt-1 -mr-1 rounded-full bg-green-400 border-2 border-white'
          : 'absolute top-0 left-0 w-3 h-3 -mt-1 -mr-1 rounded-full bg-red-400 border-2 border-white'
      "
    ></div>
    <button
      :disabled="buttonClicked || props.disable"
      @click.stop="emitButtonClicked()"
      type="button"
      class="inline-flex justify-center items-center space-x-2 border rounded-lg font-normal"
      :class="[
        buttonClicked || props.disable
          ? 'border-indigo-500 bg-indigo-500  text-indigo-100 cursor-not-allowed'
          : 'border-indigo-700 bg-indigo-700 text-indigo-100 hover:border-indigo-300 hover:text-indigo-300 hover:shadow-sm focus:ring focus:ring-indigo-300 focus:ring-opacity-25 active:border-indigo-200 active:shadow-none dark:border-indigo-200 dark:bg-indigo-200 dark:hover:border-indigo-300 dark:hover:bg-indigo-300 dark:focus:ring-indigo-500 dark:focus:ring-opacity-50 dark:active:border-indigo-200 dark:active:bg-indigo-200',
        classForSize(props.size),
      ]"
    >
      <svg
        v-if="!buttonClicked"
        class="hi-mini hi-arrow-path inline-block w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
          clip-rule="evenodd"
        />
      </svg>
      <svg
        v-else-if="buttonClicked"
        class="hi-mini hi-check inline-block w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
          clip-rule="evenodd"
        />
      </svg>
      <span>{{ props.name || '' }}</span>
    </button>
    <div
      v-if="props.tooltip"
      class="duration-50 invisible absolute bottom-full -ml-48 left-1/2 z-1 flex w-96 origin-bottom translate-y-2 scale-75 flex-col items-center justify-center pb-0.5 opacity-75 transition ease-out will-change-auto group-hover:visible group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100"
    >
      <div
        class="flex-none rounded-lg bg-gray-800 px-2.5 py-2 text-center text-xs font-semibold text-gray-50 dark:bg-gray-700"
        v-html="props.tooltip"
      />
      <div
        class="h-0 w-0 flex-none border-l-4 border-r-4 border-t-4 border-l-transparent border-r-transparent border-t-gray-800 dark:border-t-gray-700"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity';
import { Ref } from 'vue';

const props = defineProps({
  name: { type: String, required: false },
  disable: { type: Boolean, required: false },
  activateIndicator: { type: Boolean, required: false },
  indicatorColor: { type: Boolean, required: false },
  tooltip: { type: String, required: false },
  size: { type: String, default: 'm' },
});

const classForSize = (size: String): String => {
  return `size-${size}`;
};

const buttonClicked: Ref<boolean> = ref(false);
const emits = defineEmits<{ (e: 'clicked'): void }>();

const emitButtonClicked = () => {
  buttonClicked.value = true;
  emits('clicked');
};
</script>

<style>
.size-xs {
  @apply px-1 py-1;
  @apply leading-5;
  @apply text-xs;
}

.size-s {
  @apply px-3 py-2;
  @apply leading-5;
  @apply text-sm;
}

.size-m {
  @apply px-3 py-2;
  @apply leading-6;
}

.size-l {
  @apply px-4 py-3;
  @apply leading-6;
}

.size-xl {
  @apply px-6 py-4;
  @apply leading-6;
}
</style>
