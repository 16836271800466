<template>
  <span :class="{ 'cursor-pointer': clickable }" @click.prevent="handleClick">
    {{ date }}
    <div class="group relative inline-block">
      <svg
        v-if="clickable"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        data-slot="icon"
        class="mb-3 h-4 hi-micro hi-information-circle inline-block size-1"
      >
        <path
          fill-rule="evenodd"
          d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.75 8a.75.75 0 0 0 0 1.5h.75v1.75a.75.75 0 0 0 1.5 0v-2.5A.75.75 0 0 0 8.25 8h-1.5Z"
          clip-rule="evenodd"
        />
      </svg>
      <div
        class="duration-50 invisible absolute bottom-full left-1/2 z-1 -ml-20 flex w-40 origin-bottom translate-y-2 scale-75 flex-col items-center justify-center pb-0.5 opacity-75 transition ease-out will-change-auto group-hover:visible group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100"
      >
        <div
          class="flex-none rounded-lg bg-gray-800 px-2.5 py-2 text-center text-xs font-semibold text-gray-50 dark:bg-gray-700"
        >
          {{ timezoneLabel }}
        </div>
        <div
          class="h-0 w-0 flex-none border-l-4 border-r-4 border-t-4 border-l-transparent border-r-transparent border-t-gray-800 dark:border-t-gray-700"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  </span>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';

const props = withDefaults(
  defineProps<{
    date: string;
    clickable?: boolean;
    localized?: boolean;
  }>(),
  {
    clickable: false,
    localized: true,
  }
);

const isLocalized = ref(props.localized);
const handleClick = () => {
  if (props.clickable) {
    isLocalized.value = !isLocalized.value;
  }
};

const dateFormat = 'yyyy-MM-dd HH:mm ZZZZ';
const date = computed(() =>
  isLocalized.value
    ? DateTime.fromISO(props.date).toFormat(dateFormat)
    : DateTime.fromISO(props.date).toUTC().toFormat(dateFormat)
);

const timezoneLabelFormat = 'z';
const timezoneLabel = computed(() =>
  isLocalized.value
    ? DateTime.fromISO(props.date).toFormat(timezoneLabelFormat)
    : DateTime.fromISO(props.date).toUTC().toFormat(timezoneLabelFormat)
);
</script>
