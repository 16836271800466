<template>
  <h2 class="page-title">Admin Tools</h2>

  <!-- Tabs: Default in Card -->
  <div
    class="flex flex-col rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100"
  >
    <TabGroup>
      <!-- Nav Tabs -->
      <TabList
        class="flex items-center border-b border-gray-200/75 text-sm dark:border-gray-700"
      >
        <Tab as="template" v-for="tab in tabListArray">
          <button
            class="-mb-px flex items-center space-x-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500 focus:ring-opacity-25 md:px-5"
            :class="{
              'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400':
                changeSelectedTab(tab.name),
              'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400':
                changeSelectedTab(tab.name),
            }"
          >
            {{ tab.name }}
          </button>
        </Tab>
      </TabList>
      <!-- END Nav Tabs -->

      <!-- Tab Content -->
      <TabPanels class="grow p-5">
        <TabPanel>
          <h4 class="mb-2 text-lg font-bold">Files Repackaging</h4>
          <TriggerRepackByFileList />
        </TabPanel>
      </TabPanels>
      <!-- END Tab Content -->
    </TabGroup>
  </div>
  <!-- END Tabs: Default in Card -->
</template>

<script setup lang="ts">
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import TriggerRepackByFileList from './files/TriggerRepackByFileList.vue';

const tabListArray = [
  {
    name: 'Files Repackaging',
    component: 'TriggerRepackByFileList',
    selected: true,
  },
];

const changeSelectedTab = (tabName: string) => {
  tabListArray.forEach((tab) => {
    tab.selected = tab.name === tabName;
  });
};
</script>
