<template>
  <svg
    @click="switchInfoCard"
    class="hi-outline hi-information-circle inline-block w-6 h-6 m-1"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
    />
    <title>Click for more details.</title>
  </svg>
  <div
    v-if="showInfo"
    class="p-4 md:p-5 rounded text-gray-700 bg-gray-100 w-auto h-fit"
  >
    <div class="flex items-center">
      <h3 class="font-normal">{{ formInfo }}</h3>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from '@vue/reactivity';

const props = defineProps<{
  formInfo: string;
}>();

const showInfo: Ref<boolean> = ref(false);

const switchInfoCard = () => {
  if (showInfo.value === false) {
    showInfo.value = true;
  } else {
    showInfo.value = false;
  }
};
</script>

<style>
svg:hover {
  stroke: rgb(75, 85, 99);
}
</style>
