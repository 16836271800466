<template>
  <div class="spacey-8">
    <form class="space-y-6" v-if="!loading">
      <div class="section">
        <!-- Target Info -->
        <div class="left-col-block">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <information-circle-icon class="w-5 h-5 inline-block" />

            <span>Namespace</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">
            Namespace where the publication will be created.
          </p>
        </div>
        <!-- END Target Info -->

        <!-- Card: Target -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <div v-if="route.query.namespace">
                {{ route.query.namespace }}
              </div>
              <div v-else>
                <namespace-selector
                  v-model="namespaceSelectedRef"
                  :class="[
                    'form-input',
                    { 'form-input-required-missing': !namespaceSelectedRef },
                  ]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <!-- Target Info -->
        <div class="left-col-block">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <span>Publication</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5">
            The publication identifier (ISBN).
          </p>
        </div>
        <!-- END Target Info -->

        <!-- Card: Target -->
        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <input
                v-model="isbnRef"
                :disabled="existingPublication"
                type="text"
                :class="[
                  'form-input',
                  {
                    'form-input-required-missing':
                      isbnRef.toString().trim() == '',
                  },
                ]"
                id="form-elements-isbn"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">Mandatory metadata.</p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-title">Title</label>
              <input
                v-model="titleRef"
                type="text"
                :class="[
                  'form-input',
                  { 'form-input-required-missing': titleRef.trim() == '' },
                ]"
                id="form-elements-title"
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-author"
                >Author</label
              >
              <input
                v-model="authorRef"
                type="text"
                :class="[
                  'form-input',
                  { 'form-input-required-missing': authorRef.trim() == '' },
                ]"
                id="form-elements-author"
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-lang"
                >Language</label
              >
              <input
                v-model="langRef"
                :class="[
                  'form-input',
                  {
                    'form-input-required-missing':
                      !langRef || langRef.length > 5,
                  },
                ]"
                id="form-elements-lang"
                type="text"
                placeholder="Enter the language."
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-publisher"
                >Publisher</label
              >
              <input
                v-model="publisherRef"
                :class="[
                  'form-input',
                  {
                    'form-input-required-missing':
                      (publisherRef ?? '').trim() == '',
                  },
                ]"
                id="form-elements-publisher"
                type="text"
                placeholder="Enter the publisher."
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-nature"
                >Nature</label
              >
              <select
                v-model="natureRef"
                :class="[
                  'form-input',
                  { 'form-input-required-missing': !natureRef },
                ]"
                id="form-elements-nature"
              >
                <option value="EPUB_BOOK" key="EPUB_BOOK">EPUB_BOOK</option>

                <option value="PDF_BOOK" key="PDF_BOOK">PDF_BOOK</option>

                <option value="AUDIO_BOOK" key="AUDIO_BOOK">AUDIO_BOOK</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">Other metadata.</p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-subtitle"
                >Subtitle</label
              >
              <input
                v-model="subtitleRef"
                type="text"
                class="form-input"
                id="form-elements-subtitle"
              />
            </div>

            <div class="space-y-1">
              <div>
                <label class="font-medium" for="form-elements-description"
                  >Description</label
                >
                <information-button :formInfo="descriptionFormInfo" />
              </div>
              <textarea
                v-model="descriptionRef"
                class="form-input"
                type="text"
                id="form-elements-description"
                placeholder="Enter the description or leave empty."
              />
            </div>

            <div class="space-y-1">
              <div>
                <label class="font-medium" for="form-elements-summary"
                  >Summary</label
                >
                <information-button :formInfo="summaryFormInfo" />
              </div>
              <textarea
                v-model="summaryRef"
                class="form-input"
                type="text"
                id="form-elements-summary"
                placeholder="Enter the summary or leave empty."
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-imprint"
                >Imprint</label
              >
              <input
                v-model="imprintRef"
                class="form-input"
                id="form-elements-imprint"
                type="text"
                placeholder="Enter the imprint or leave empty."
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-page-count"
                >Number of Pages</label
              >
              <input
                v-model="pageCountRef"
                type="number"
                class="form-input"
                id="form-elements-page-count"
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-paper-isbn"
                >Paper ISBN</label
              >
              <input
                v-model="paperIsbnRef"
                class="form-input"
                id="form-elements-paper-isbn"
                type="text"
                placeholder="Enter the paper ISBN or leave empty."
              />
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-published"
                >Publication Date</label
              >
              <DateInput
                v-model="publishedRef"
                placeholder="Enter the publication date or leave empty (YYYY-MM-DD)"
              />
            </div>

            <div class="space-y-1">
              <label for="form-input-hasAdultConsideration" class="font-medium"
                >Only Adult</label
              >
              <input
                v-model="hasAdultConsideration"
                class="block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="checkbox"
                id="tk-form-elements-hasAdultConsideration"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">
            Add or remove publication's contributors.
          </p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-is-part-type"
                >Contributors</label
              >
              <ul class="list">
                <li
                  class="p-4 flex justify-between items-center"
                  v-for="contributor in contributorsRef"
                >
                  <span class="font-semibold text-sm mr-1">{{
                    contributor.role
                  }}</span>
                  <span class="text-green-500 text-sm">{{
                    contributor.person.name
                  }}</span>
                  <span>
                    <dm-button
                      variant="danger"
                      @click.prevent="
                        removeContributor(
                          contributor.role,
                          contributor.person.name
                        )
                      "
                      size="xs"
                      >remove</dm-button
                    >
                  </span>
                </li>

                <li class="p-4 flex justify-between items-center">
                  <span class="font-semibold text-sm mr-1">
                    <select
                      v-model="newContributorRoleRef"
                      class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
                    >
                      <option value="AUTHOR">AUTHOR</option>
                      <option value="TRANSLATOR">TRANSLATOR</option>
                      <option value="EDITOR">EDITOR</option>
                      <option value="ARTIST">ARTIST</option>
                      <option value="ILLUSTRATOR">ILLUSTRATOR</option>
                      <option value="LETTERER">LETTERER</option>
                      <option value="PENCILER">PENCILER</option>
                      <option value="COLORIST">COLORIST</option>
                      <option value="INKER">INKER</option>
                      <option value="NARRATOR">NARRATOR</option>
                    </select>
                  </span>
                  <span>
                    <input
                      v-model="newContributorNameRef"
                      class="form-input"
                      id="form-elements-new-contributor-name"
                      type="text"
                      placeholder="Enter the name of the contributor."
                    />
                  </span>
                  <span>
                    <dm-button size="xs" @click.prevent="addNewContributor"
                      >Add</dm-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">
            Add or remove publication's subjects.
          </p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-is-part-type"
                >Subjects</label
              >
              <ul class="list">
                <li
                  class="p-4 flex justify-between items-center"
                  v-for="subject in subjectsRef"
                >
                  <span class="font-semibold text-sm mr-1">{{
                    subject.scheme
                  }}</span>
                  <span class="text-green-500 text-sm">{{ subject.code }}</span>
                  <span>
                    <dm-button
                      variant="danger"
                      @click.prevent="
                        removeSubject(subject.scheme, subject.code)
                      "
                      size="xs"
                      >remove</dm-button
                    >
                  </span>
                </li>

                <li class="p-4 flex justify-between items-center">
                  <span class="font-semibold text-sm mr-1">
                    <select
                      v-model="newSubjectSchemeRef"
                      class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
                    >
                      <option
                        v-for="subjectScheme of subjectsScheme"
                        :value="subjectScheme"
                      >
                        {{ subjectScheme }}
                      </option>
                    </select>
                  </span>
                  <span>
                    <input
                      v-model="newSubjectCodeRef"
                      class="form-input"
                      id="form-elements-new-subject-code"
                      type="text"
                      placeholder="Enter the subject code."
                    />
                  </span>
                  <span>
                    <dm-button size="xs" @click.prevent="addNewSubject"
                      >Add</dm-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">
            Add or remove publication's collections.
          </p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-is-part-type"
                >Collections</label
              >
              <ul class="list">
                <li
                  class="p-4 flex justify-between items-center"
                  v-for="collection in collectionsRef"
                >
                  <span class="font-semibold text-sm mr-1">{{
                    collection.label
                  }}</span>
                  <span>
                    <dm-button
                      variant="danger"
                      @click.prevent="removeCollection(collection.label)"
                      size="xs"
                      >remove</dm-button
                    >
                  </span>
                </li>

                <li class="p-4 flex justify-between items-center">
                  <span>
                    <input
                      v-model="newCollectionLabelRef"
                      class="form-input"
                      id="form-elements-new-collection-label"
                      type="text"
                      placeholder="Enter the collection label."
                    />
                  </span>
                  <span>
                    <dm-button size="xs" @click.prevent="addNewCollection"
                      >Add</dm-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">
            Add or remove publication audiences.
          </p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium">Audiences</label>
              <ul class="list">
                <li class="p-4 flex justify-between items-center">
                  <input
                    type="checkbox"
                    v-model="adultAudienceRef"
                    id="audience-ADULT"
                  />
                  <label for="audience-ADULT">ADULT</label>
                </li>
                <li class="p-4 flex justify-between items-center">
                  <input
                    type="checkbox"
                    v-model="youthAudienceRef"
                    id="audience-YOUTH"
                  />
                  <label for="audience-YOUTH">YOUTH</label>
                </li>
                <li class="p-4 flex justify-between items-center">
                  <input
                    type="checkbox"
                    v-model="teenAudienceRef"
                    id="audience-TEEN"
                  />
                  <label for="audience-TEEN">TEEN</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="left-col-block">
          <p class="text-gray-500 text-sm mb-5">
            Add or remove publication audiences as age ranges, in years.
          </p>
        </div>

        <div class="right-col-block">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <IntRangesInput
                label="Audience Age Ranges"
                :from="0"
                :to="99"
                v-model="ageRangeAudiencesRef"
                :initialValue="[]"
                :validation="ageRangeValidation"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <p class="text-gray-500 text-sm mb-5">
            If publication is part of a series, fill the following fields.
          </p>
        </div>
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <div class="p-2 lg:p-2 float-right">
            <select
              v-model="isPartOfTypeRef"
              class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
            >
              <option value="periodical">Periodical</option>
              <option value="bookseries">Book Series</option>
            </select>
          </div>

          <div class="p-5 lg:p-6 flex-grow w-full">
            <div class="space-y-1">
              <label class="font-medium" for="form-elements-is-part-issn"
                >Series ISSN</label
              >
              <input
                v-model="seriesIssnRef"
                class="form-input"
                type="text"
                id="form-elements-is-part-issn"
                placeholder="Enter the ISSN or leave empty."
              />
            </div>

            <div class="space-y-1" v-if="isPartOfTypeRef == 'periodical'">
              <label class="font-medium" for="form-elements-is-part-type"
                >Series Type</label
              >

              <select
                v-model="seriesTypeRef"
                class="form-input"
                id="form-elements-is-part-type"
              >
                <option :value="undefined" key="none">None</option>
                <option value="NEWSPAPER" key="NEWSPAPER">Newspaper</option>
                <option value="MAGAZINE" key="MAGAZINE">Magazine</option>
              </select>
            </div>

            <div class="space-y-1">
              <label class="font-medium" for="form-elements-is-part-type"
                >Series Name</label
              >
              <input
                v-model="seriesNameRef"
                class="form-input"
                type="text"
                id="form-elements-is-part-type"
                placeholder="Enter the type or leave empty."
              />
            </div>

            <div class="space-y-1" v-if="isPartOfTypeRef == 'bookseries'">
              <label class="font-medium" for="form-elements-position-in-series"
                >Position in Series</label
              >
              <input
                v-model="seriesPositionRef"
                class="form-input"
                type="number"
                id="form-elements-position-in-series"
                placeholder="Enter the position in series or leave empty."
              />
            </div>

            <div class="space-y-1" v-if="isPartOfTypeRef == 'periodical'">
              <label class="font-medium" for="form-elements-issue-number"
                >Issue Number</label
              >
              <input
                v-model="seriesIssueNumberRef"
                class="form-input"
                id="form-elements-issue-number"
                type="text"
                placeholder="Enter the issue number or leave empty."
              />
            </div>
          </div>
        </div>
      </div>

      <error-display
        :error="savePublicationError"
        headerMessage="Failed to save changes."
      />

      <div class="md:flex md:flex-row md:space-x-5">
        <div class="md:flex-none md:w-1/3 text-center md:text-left"></div>
        <div class="flex flex-col rounded shadow-sm overflow-hidden md:w-2/3">
          <div class="space-y-1">
            <dm-button
              class="mr-4"
              type="submit"
              :enabled="metadataIsReady"
              @click.prevent="savePublication()"
              :loading="savePublicationLoading"
              >Save Changes</dm-button
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/solid';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import utc from 'dayjs';

import { graphql } from '../../__generated__/gql';
import {
  AgeRangeInYears,
  Audience,
  CollectionInput,
  Contributor,
  CreatePublicationFromMetadataMutationVariables,
  PeriodicalType,
  PublicationNature,
  Role,
  SubjectInput,
  SubjectScheme,
} from '../../__generated__/graphql';

import DateInput from '../base/DateInput.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import InformationButton from '../base/InformationButton.vue';
import IntRangesInput from './edition/IntRangesInput.vue';

const router = useRouter();
const route = useRoute();

// whether or not the publication already exists
const existingPublication = ref(route.query.isbn ? true : false);

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> = ref({
  id: (route.query.namespace_id as string) || '',
  name: (route.query.namespace as string) || '',
});
const isbnRef = ref(`${route.query.isbn ?? ''}` || '');
const titleRef = ref('');
const authorRef = ref('');
const subtitleRef = ref<string | undefined>();
const pageCountRef = ref<number | undefined>();
const natureRef = ref<PublicationNature>('EPUB_BOOK');
const langRef = ref<string>('fra');
const descriptionRef = ref<string | undefined>();
const summaryRef = ref<string | undefined>();
const imprintRef = ref<string | undefined>();
const paperIsbnRef = ref<string | undefined>();
const publisherRef = ref<string | undefined>();
const publishedRef = ref<string | undefined>();
const seriesIssnRef = ref<string | undefined>();
const seriesTypeRef = ref<PeriodicalType | undefined>();
const seriesNameRef = ref<string | undefined>();
const seriesIssueNumberRef = ref<string | undefined>();
const seriesPositionRef = ref<number | undefined>();
const isPartOfTypeRef = ref<string>('periodical');
const contributorsRef = ref<Contributor[] | undefined>([]);
const newContributorNameRef = ref<string>('');
const newContributorRoleRef = ref<Role>('AUTHOR');
const subjectsRef = ref<SubjectInput[] | undefined>([]);
const collectionsRef = ref<CollectionInput[] | undefined>([]);
const ageRangeAudiencesRef = ref<AgeRangeInYears[]>([]);
const newCollectionLabelRef = ref<string>('');
const ageRangeFromRef = ref<number | ''>(0);
const ageRangeToRef = ref<number | ''>(99);
const hasAdultConsideration = ref<boolean>(false);

const subjectsScheme = ref<Array<SubjectScheme>>([
  'THEMA',
  'BISAC',
  'BIC',
  'FEEDBOOKS',
  'CUSTOM',
]);
const newSubjectSchemeRef = ref<SubjectScheme>(subjectsScheme.value[0]);
const newSubjectCodeRef = ref<string>('');
const adultAudienceRef = ref<boolean>(false);
const youthAudienceRef = ref<boolean>(false);
const teenAudienceRef = ref<boolean>(false);

const ageRangeValidation = ({
  from,
  to,
}: {
  from: number | '';
  to: number | '';
}): string[] => {
  const errors: string[] = [];
  if (from === '') {
    errors.push('From cannot be empty');
  }
  if (to === '') {
    errors.push('To cannot be empty');
  }
  if (from >= to) {
    errors.push('To must be greater than From');
  }
  if (0 > to || to > 140) {
    errors.push('To must be a valid age');
  }
  if (0 > from || from > 140) {
    errors.push('From must be a valid age');
  }
  return errors;
};

const { loading } = useQuery(
  graphql(`
    query NewPublicationViewerQuery {
      viewer {
        namespaces {
          name
          id
        }
      }
    }
  `),
  null,
  {
    fetchPolicy: 'cache-and-network',
  }
);

const metadataIsReady = computed(() => {
  return !!(
    namespaceSelectedRef.value &&
    isbnRef.value != '' &&
    titleRef.value &&
    authorRef.value &&
    publisherRef.value &&
    natureRef.value &&
    langRef.value &&
    langRef.value.length <= 5
  );
});

const addNewContributor = () => {
  if (contributorsRef.value) {
    contributorsRef.value.push({
      role: newContributorRoleRef.value,
      person: { name: newContributorNameRef.value },
    });
    newContributorNameRef.value = '';
    newContributorRoleRef.value = 'AUTHOR';
  }
};

const removeContributor = (role: string, name: string) => {
  if (contributorsRef.value) {
    const contributor = contributorsRef.value.find(
      (c) => c.person.name === name && c.role === role
    );
    contributorsRef.value = contributorsRef.value.filter(
      (c) => c != contributor
    );
  }
};

const addNewSubject = () => {
  if (subjectsRef.value) {
    subjectsRef.value.push({
      scheme: newSubjectSchemeRef.value,
      code: newSubjectCodeRef.value,
    });
    newSubjectCodeRef.value = '';
    newSubjectSchemeRef.value = 'THEMA';
  }
};

const removeSubject = (scheme: string, code: string) => {
  if (subjectsRef.value) {
    const subject = subjectsRef.value.find(
      (s) => s.scheme === scheme && s.code === code
    );
    subjectsRef.value = subjectsRef.value.filter((s) => s != subject);
  }
};

const addNewCollection = () => {
  if (collectionsRef.value) {
    collectionsRef.value.push({
      label: newCollectionLabelRef.value,
    });
    newCollectionLabelRef.value = '';
  }
};

const removeCollection = (label: string) => {
  if (collectionsRef.value) {
    collectionsRef.value = collectionsRef.value.filter((c) => c.label != label);
  }
};

const addNewAudienceAgeRange = () => {
  if (
    ageRangeFromRef.value === '' ||
    ageRangeToRef.value === '' ||
    ageRangeFromRef.value > ageRangeToRef.value - 1
  ) {
    return;
  }

  if (ageRangeAudiencesRef.value) {
    ageRangeAudiencesRef.value.push({
      from: ageRangeFromRef.value,
      to: ageRangeToRef.value,
    });
    ageRangeFromRef.value = 0;
    ageRangeToRef.value = 99;
  }
};

const removeAudienceAgeRange = (audienceAgeRange: AgeRangeInYears) => {
  if (ageRangeAudiencesRef.value) {
    ageRangeAudiencesRef.value = ageRangeAudiencesRef.value.filter(
      (range) => range != audienceAgeRange
    );
  }
};

const publicationCreationVariables: Ref<CreatePublicationFromMetadataMutationVariables> =
  computed(() => {
    const audiences: { code: Audience }[] = [];
    if (adultAudienceRef.value) {
      audiences.push({ code: 'ADULT' });
    }
    if (youthAudienceRef.value) {
      audiences.push({ code: 'YOUTH' });
    }
    if (teenAudienceRef.value) {
      audiences.push({ code: 'TEEN' });
    }

    return {
      isbn: isbnRef.value,
      namespaceId: namespaceSelectedRef.value?.id ?? '',
      input: {
        title: titleRef.value,
        subtitle: subtitleRef.value,
        pageCount: pageCountRef.value,
        author: { name: authorRef.value },
        contributors: contributorsRef.value || [],
        nature: natureRef.value,
        summary: summaryRef.value,
        paperIsbn: paperIsbnRef.value,
        description: descriptionRef.value,
        published:
          publishedRef.value && utc(publishedRef.value, 'YYYY-MM-DD').toDate(),
        lang: langRef.value,
        publisher: publisherRef.value ?? '',
        imprint: imprintRef.value,
        subjects: subjectsRef.value || [],
        collections: collectionsRef.value || [],
        isPartOf: seriesIssnRef.value
          ? {
              issn: seriesIssnRef.value,
              name: seriesNameRef.value,
              issueNumber: seriesIssueNumberRef.value,
              type: seriesTypeRef.value,
              position: seriesPositionRef.value,
            }
          : undefined,
        audiences,
        ageRangeAudiences: ageRangeAudiencesRef.value,
        hasAdultConsideration: hasAdultConsideration.value,
      },
    };
  });

const {
  mutate: createNewPublicationMetadata,
  loading: createNewPublicationMetadataLoading,
  error: createNewPublicationMetadataError,
  onDone: onCreateNewPublicationMetadataDone,
  onError: onCreateNewPublicationMetadataError,
} = useMutation(
  graphql(`
    mutation CreatePublicationMetadata(
      $namespaceId: ID!
      $isbn: PublicationIdentifier!
      $input: PublicationMetadataInput!
    ) {
      pushPublicationMetadata(
        namespaceId: $namespaceId
        isbn: $isbn
        input: $input
      ) {
        id
      }
    }
  `),
  () => ({
    variables: publicationCreationVariables.value,
  })
);

onCreateNewPublicationMetadataDone((result) => {
  if (result.data?.pushPublicationMetadata) {
    router.push({
      name: 'publication',
      params: {
        id: result.data?.pushPublicationMetadata.id,
      },
    });
  }
});

onCreateNewPublicationMetadataError((result) => {
  if (result.graphQLErrors[0]?.extensions?.code != 'BAD_USER_INPUT') {
    throw new Error(result.message);
  }
});

const {
  mutate: createNewPublication,
  loading: createNewPublicationLoading,
  error: createNewPublicationError,
  onDone: onCreateNewPublicationDone,
  onError: onCreateNewPublicationError,
} = useMutation(
  graphql(`
    mutation createPublication(
      $namespaceId: ID!
      $isbn: PublicationIdentifier!
      $input: PublicationMetadataInput!
    ) {
      createPublicationFromMetadata(
        namespaceId: $namespaceId
        isbn: $isbn
        input: $input
      ) {
        success
        publication {
          id
        }
      }
    }
  `),
  () => ({
    variables: publicationCreationVariables.value,
  })
);

onCreateNewPublicationDone((result) => {
  if (result.data?.createPublicationFromMetadata.success) {
    router.push({
      name: 'publication',
      params: {
        id: result.data.createPublicationFromMetadata.publication?.id,
      },
    });
  }
});

onCreateNewPublicationError((result) => {
  if (result.graphQLErrors[0]?.extensions?.code != 'BAD_USER_INPUT') {
    throw new Error(result.message);
  }
});

const savePublication = existingPublication.value
  ? createNewPublicationMetadata
  : createNewPublication;
const savePublicationLoading = existingPublication.value
  ? createNewPublicationMetadataLoading
  : createNewPublicationLoading;
const savePublicationError = existingPublication.value
  ? createNewPublicationMetadataError
  : createNewPublicationError;

const descriptionFormInfo: string =
  'Enter a detailed description, summary, abstract, or excerpt to provide readers with a comprehensive understanding of the publication. The description field allows you to share additional details beyond the summary and metadata, offering a chance to provide richer information about the publication.';
const summaryFormInfo: string =
  'Enter a concise summary, abstract, or excerpt that captures the essence of the publication. The summary offers readers an overview into the content and serves as a brief introduction to quickly assess its relevance and appeal.';
</script>

<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-required-missing {
  @apply focus:border-pink-500 focus:ring-pink-500 border-pink-500;
}

.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.right-col-block {
  @apply flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3;
}

.section {
  @apply md:flex md:space-x-5;
}

.left-col-block {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}

.section-right {
  @apply md:flex-none md:w-1/3 text-center md:text-left;
}
</style>
