<template>
  <div
    class="p-4 md:p-5 rounded text-orange-700 bg-orange-100 dark:text-orange-100 dark:bg-orange-900 dark:bg-opacity-75"
    v-if="warningList && warningList.length > 0"
  >
    <div class="flex items-center mb-3">
      <svg
        class="hi-solid hi-exclamation inline-block w-5 h-5 mr-3 flex-none text-orange-500 dark:text-orange-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
          clip-rule="evenodd"
        />
      </svg>
      <h3 class="font-semibold grow">{{ headerMessage }}</h3>
    </div>

    <ul class="list-inside ml-8 space-y-2">
      <li class="flex items-center" v-for="item in warningList">
        <svg
          class="hi-solid hi-arrow-narrow-right inline-block w-4 h-4 flex-none mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
        {{ item }}
      </li>
    </ul>

    <div class="flex items-center ml-8 mt-2" v-if="footerMessage">
      <h3 class="font-semibold">
        {{ footerMessage }}
      </h3>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    headerMessage: {
      type: String,
      required: true,
    },
    footerMessage: {
      type: String,
      required: false,
    },
    warning: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  setup(props) {
    const warningList = computed<any>(() => {
      if (Array.isArray(props.warning)) {
        return props.warning;
      } else if (props.warning == null) {
        return null;
      } else {
        return [props.warning];
      }
    });

    return { warningList };
  },
});
</script>
