<template>
  <div class="space-y-1">
    <label class="font-medium italic">{{ label }}</label>
    <ul :class="['list', { 'list-changed': hasChanged }]">
      <li
        class="p-4 flex justify-between items-center"
        v-for="item in internalModel"
      >
        <span class="font-semibold text-sm mr-1">
          {{ item.join(',') }}
        </span>
        <span>
          <dm-button
            variant="danger"
            @click.prevent="removeItem(item)"
            size="xs"
            >remove</dm-button
          >
        </span>
      </li>

      <li class="p-4 flex justify-between items-center">
        <span>
          <select
            v-model="newValue"
            class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
          >
            <option v-for="option of leftOptions" :value="option">
              {{ option.join(',') }}
            </option>
          </select>
        </span>
        <span>
          <dm-button
            size="xs"
            :enabled="newValue != null"
            @click.prevent="addItem"
            >Add</dm-button
          >
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { differenceWith } from 'lodash';
import { computed, onMounted, Ref, ref, watch } from 'vue';

const props = defineProps<{
  label: string;
  initialValue?: string[][];
  options: string[][];
  placeholder?: string | null;
  modelValue: string[][] | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[][] | null | undefined): void;
  (e: 'differs', value: boolean): void;
}>();

const internalModel: Ref<string[][]> = ref(
  props.initialValue && JSON.parse(JSON.stringify(props.initialValue))
);

const leftOptions = computed(() => {
  return differenceWith(
    props.options,
    internalModel.value,
    (c1, c2) => c1.length === c2.length && c1.every((v) => c2.includes(v))
  );
});

const hasChanged = computed(() => {
  return (
    internalModel.value?.length != props.initialValue?.length ||
    !!props.initialValue?.find(
      (iv) =>
        !internalModel.value?.find(
          (mv) => iv.length === mv.length && iv.every((v) => mv.includes(v))
        )
    )
  );
});

function addItem() {
  internalModel.value = internalModel.value || [];
  internalModel.value.push(newValue.value as string[]);
}

const removeItem = (item: string[]) => {
  if (internalModel.value) {
    internalModel.value = internalModel.value.filter(
      (c) => !(c.length === item.length && c.every((v) => item.includes(v)))
    );
  }
};

const newValue = ref<string[] | null>(
  leftOptions.value.length > 0 ? leftOptions.value[0] : null
);

onMounted(() => {
  watch(props, (newProps) => {
    internalModel.value = newProps.modelValue!;
  });
  emit('update:modelValue', internalModel.value);
});

watch(
  internalModel,
  (newVal) => {
    emit('update:modelValue', newVal);
    emit('differs', hasChanged.value);
    newValue.value = leftOptions.value.length > 0 ? leftOptions.value[0] : null;
  },
  { deep: true }
);
</script>
<style scoped>
.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.list-changed {
  @apply border-purple-500  divide-purple-500;
}
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
