interface Release {
  version: string;
  changelog: string;
  components: Component[];
}

interface Component {
  name: string;
  changes: Change[];
}

interface Change {
  type: ChangeType;
  description: string;
}

export enum ChangeType {
  Added = 'Added',
  Fixed = 'Fixed',
  Changed = 'Changed',
  Removed = 'Removed',
}

export const CHANGELOG_DATA: Release[] = [
  {
    version: '2024-10-29',
    changelog:
      'License expiration is deprecated. Use license cancellation instead.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Removed,
            description:
              'Removes the `expire license` action, now use `cancel license` instead.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Removed,
            description:
              'Removes the possibility to only expire a license. Use the cancelLicense mutation instead to set the license status as well.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-10-07',
    changelog:
      'Adding the possibility to trigger a repackaging on multiple files.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a tab on Admin Tools page to allow the selection of multiple file IDs, in order to repack their missing packaging.',
          },
          {
            type: ChangeType.Removed,
            description:
              'Removes the cover repackaging tab on Admin Tools page.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds the possibility to repack multiple files at the same time with a list of file ids.',
          },
          {
            type: ChangeType.Added,
            description:
              'Adds the possibility to filter files by namespace, nature and/or repackaging status.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-07-26',
    changelog: 'Changes in the file list order.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'Files are now displayed by creation date. We limit the repackaging of files at the last uploaded file that do not have a package.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-05-21',
    changelog: 'Modify default action for results of command palette.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'Using enter now navigates to the searched resource, to see more actions available on the resource use the right arrow.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-05-15',
    changelog: 'Adds the option to create open access license.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Adds a checkbox in the license's creation form to create an open access license.",
          },
        ],
      },
    ],
  },
  {
    version: '2024-05-15',
    changelog: 'Adds a command palette.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Allows users to search for publications, periodicals, namespaces and navigate trough Warehouse.',
          },
          {
            type: ChangeType.Added,
            description:
              'Allows users to access actions for the viewed resource directly from the command palette.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-04-12',
    changelog: 'Adds a progress bar to the upload file section.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Adds a progress bar to track file uploads.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-04-11',
    changelog: 'Adds flexibility to the licenses creation stencil.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "A new, simpler English stencil has been added to the 'Upload Excel File' section, requiring only essential information for creating licenses. Each field is described in the column header.",
          },
          {
            type: ChangeType.Changed,
            description:
              "For the Spanish stencil, which is only relevant to Ebiblio, we've added information on whether the column is mandatory or optional.",
          },
        ],
      },
    ],
  },
  {
    version: '2024-03-19',
    changelog:
      'Adds the possibility to Administrators to delete a periodical subscription previously created.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a delete button to the periodical subscription. This button allows us to delete the chosen periodical template. It is only available for Administrators.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-03-12',
    changelog:
      'Adding the possibility to enter the maximum loan duration in days, hours, minutes.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'In the license creation form, the maximum loan duration must be entered using days, hours, minutes or seconds with the unit following the number (e.g. 3d 2h 30m 6000s).',
          },
        ],
      },
    ],
  },
  {
    version: '2024-03-11',
    changelog:
      'Adding the possibility to trigger a cover repackaging on multiple publications.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a tab on Admin Tools page to allow the selection of multiple publication IDs, in order to repack their current cover.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds the possibility to repack the current cover of multiple publications using their IDs.',
          },
        ],
      },
    ],
  },
  {
    version: '2024-03-01',
    changelog: 'Change namespace details.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Add a description to your namespace.',
          },
          {
            type: ChangeType.Removed,
            description: 'Remove bucket name option from namespace.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-12-06',
    changelog: 'Add the possibility to archive a file.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a page to display file information and trigger actions on it.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds the possibility to archive a file and its related representations.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-11-28',
    changelog:
      "Supported multiple Subjects schemes at Publication's creation by an excel file",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Adds the possibility to choose the input subject's scheme at the excel file and the subject's scheme at the publication's creation. This also allows the transformation logic between them to be implemented. Ex: eBiblio -> BIC",
          },
        ],
      },
    ],
  },
  {
    version: '2023-11-20',
    changelog: 'Accessibility Metadata',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Add the possibility to merge extracted accessibility metadata to a publication's metadata.",
          },
        ],
      },
    ],
  },
  {
    version: '2023-10-20',
    changelog: 'SearchBar',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Add the possibility to search for a namespace by its ID.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-10-18',
    changelog: 'Supported Onix categories',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Addition of the 'TEEN' audience and 'CUSTOM' subject category in the creation or editing form of a publication.",
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "In publication's metadata, adds TEEN to the supported audiences and CUSTOM to the supported subject schemes.",
          },
        ],
      },
      {
        name: 'ONIX',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'During the upload of an ONIX file, support for audiences (codes 01, 02 & 03 from codeList 28), age ranges(code 18 from codeList 30), and Publisher’s Own category code (23 from codeList 27).',
          },
        ],
      },
    ],
  },
  {
    version: '2023-10-12',
    changelog: 'Namespace relations',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a page to display content providers and licensers relation between namespaces.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-09-26',
    changelog: 'Publication nature',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              "The xlsx template for adding multiple publications has been modified. We changed the 'format' column to the 'nature' column. Please download the new version before using it.",
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'Publication metadata format field is now deprecated, use nature instead. The nature of a publication can be AUDIO_BOOK, EPUB_BOOK or PDF_BOOK',
          },
        ],
      },
    ],
  },
  {
    version: '2023-09-12',
    changelog: 'Licenses cancellation',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Add the possibility to cancel a license',
          },
          {
            type: ChangeType.Added,
            description:
              'Distinction between active licenses (which are available, not expired and have usages left) and inactive licenses (the others). ',
          },
        ],
      },
    ],
  },
  {
    version: '2023-08-30',
    changelog: "Support subscription/s order's excel file",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'New support page for creating subscription/s from a purchase order from an excel file',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added a method, createPeriodicalTemplates, to create multiple periodical Templates',
          },
          {
            type: ChangeType.Added,
            description:
              'Added a method, periodicalsByOriginsAndIdentifiers, to consult multiple periodicals',
          },
        ],
      },
    ],
  },
  {
    version: '2023-08-28',
    changelog: "Periodical's edit page",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Adds a page for editting the periodical's basic info such as name and publisher.",
          },
        ],
      },
    ],
  },
  {
    version: '2023-08-15',
    changelog: 'Badge notification for new changes on the Changelog tab.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'New changes will be indicated by a notification badge on the Changelog tab.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-08-08',
    changelog: 'Add interface for testing lending flow.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'New action in license page to create loan.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-08-07',
    changelog: 'Display full-width screen when the sidebar is closed.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'Main layout now takes full screen when the sidebar is closed.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-07-24',
    changelog: "Support order's excel file",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'New support page for creating license/s from a purchase order from an excel file',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added a method, createLicenses, to create multiple new licenses',
          },
        ],
      },
    ],
  },
  {
    version: '2023-07-19',
    changelog: 'Publication UI changes.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Add the possibility to drop files.',
          },
          {
            type: ChangeType.Added,
            description: 'Minor changes to UI.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-06-30',
    changelog: 'Newspaper and Magazine contract reports',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a page for listing, filtering and exporting periodical contracts.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'A new method called periodicalsTemplate has been created. Its main purpose is to let the client filter periodical templates by their namespaces or their status (active/not active). We also add the possibility of filtering the periodical templates by their periodical’s origin or identifier.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-06-19',
    changelog: 'Periodicals UI improvements.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'The associated publications to the periodical template have been filtered by origin',
          },
          {
            type: ChangeType.Added,
            description:
              "Pagination has been added to periodical templates' publications",
          },
        ],
      },
    ],
  },
  {
    version: '2023-05-17',
    changelog:
      "Adding the possibility to trigger a file's excerpt regeneration.",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Adding the possibility to trigger a file's excerpt regeneration.",
          },
        ],
      },
    ],
  },
  {
    version: '2023-05-12',
    changelog:
      "Adding the possibility to trigger a file's cover upload to Cloudflare.",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Adding the possibility to trigger a file's cover upload to Cloudflare.",
          },
          {
            type: ChangeType.Added,
            description:
              'In the list of files, display in descending order according to their update date. For each nature of file, the line of the most recent is shaded.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-05-09',
    changelog: 'Add accessibility fields to publication metadata editor.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds support for the edition of accessibility metadata in the publication editor.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-05-01',
    changelog: 'Adding the ability to view the Custom Ingestion Hooks.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'New entry added to the Sidebar to view the Custom Ingestion Hooks.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-04-24',
    changelog: "Adding the possibility to trigger a file's repackaging.",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "On a publication's file list, adding a button to trigger its repackaging.",
          },
        ],
      },
    ],
  },
  {
    version: '2023-04-18',
    changelog: 'User tokens management page.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds a page for listing, creating and destroying user tokens.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-04-12',
    changelog: 'Displaying the License Status Document on the loan page.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Display of the LSD on the loan page with the possibility to copy the document.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-04-11',
    changelog:
      'Adding the possibility to expire multiple licenses in a publication and filter licenses in a publication by an Order Identifier.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Possibility to select multiple licenses and expire them.',
          },
          {
            type: ChangeType.Added,
            description:
              'Possibility to filter licenses by the ORDER IDENTIFIER.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Add a LicenseFilter to select all licenses from an Order Identifier.',
          },
          {
            type: ChangeType.Added,
            description: 'Add a query to expire multiple Licenses.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-31',
    changelog:
      'Adding the possibility to search for a publication by its title.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Possibility to search for a publication by its title on the search bar.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Add a publication filter by title.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-28',
    changelog:
      'Total size of files displayed on the Dashboard and the Namespace status page',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Files' size displayed on Dashboard and Namespace pages.",
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adding the possibility to query for the total size of files',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-23',
    changelog: 'List of loans linked to a license displayed.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Displaying all loans, active or past, related to a license.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adding the possibility to query for all past loans related to a license.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-16',
    changelog: 'Support Marc21',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'New support page for creating new publications from a Marc21 file.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-15',
    changelog: 'List of files linked to a publication displayed.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Displaying all files related to a publication with a download link.',
          },
        ],
      },
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Signed URL available to download any given file.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-03-08',
    changelog: 'UI changes',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'License creation date added on its info page.',
          },
          {
            type: ChangeType.Added,
            description: 'Developer documentation added on the sidebar menu.',
          },
          {
            type: ChangeType.Changed,
            description: 'Removal of unused fixed rate badges from the UI.',
          },
        ],
      },
    ],
  },
  {
    version: '2023-01-06',
    changelog: 'Audio encoding jobs management.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Support for listing, opening logs of and re-running audio encoding jobs.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-12-01',
    changelog: 'Support new web reader for full publications.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Support new web reader for full publications in both its prod and staging setups.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-11-07',
    changelog: 'Support new web reader for samples and shares for pdfs.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Supports opening pdf content for samples and shares in the new web-reader.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-09-22',
    changelog: 'Support new web reader for samples and shares.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Supports opening epub content for samples and shares in the new web-reader.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-07-07',
    changelog: 'Support series in excel file upload for publications.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Support setting a series (ISSN) in the publications excel.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-06-21',
    changelog: 'Filter periodical templates',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Add support for filtering periodical templates.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-06-08',
    changelog: 'Public URLs',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Add support for publications' public web-reader URLs.",
          },
        ],
      },
    ],
  },
  {
    version: '2022-05-15',
    changelog: 'Add web reader support.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Support opening a publication in the web reader.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-05-02',
    changelog: "loan's duration cap.",
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Support limiting loan's duration to licenses' expiration.",
          },
        ],
      },
    ],
  },
  {
    version: '2022-04-20',
    changelog: 'Fix Global Search.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Fixed,
            description:
              'Fix the global search component behavior under limited user rights.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-03-18',
    changelog: 'Periodical subscriptions custom attributes.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Let the user add custom attributes to periodical subscriptions.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-03-17',
    changelog: 'Fix license creation .',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Fixed,
            description:
              'Fixes the creation of a license with an expiration time.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-03-16',
    changelog: 'Allow uploading zip files from publication page.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Allow uploading zip files from publication page (for audiobooks).',
          },
        ],
      },
    ],
  },

  {
    version: '2022-03-11',
    changelog: 'Support audiences and original path after authentication.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Adds support for setting audiences during creation and edition of publications.',
          },
        ],
      },

      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Fixed,
            description:
              'After returning from auth0 authentication, redirect the user to the original path.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-02-23',
    changelog: 'Fix publication editor bug.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Fixed,
            description: 'Publication editors were ignoring the paper ISBN.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-02-03',
    changelog: 'Add namespace filtering',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added a field that allows users to filter the namespaces according to their name',
          },
        ],
      },
    ],
  },
  {
    version: '2022-02-02',
    changelog: 'Adapts the frontend for reduced permissions.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Changed,
            description:
              'Users with reduced permissions will only see what they have the right to see.',
          },
        ],
      },
    ],
  },
  {
    version: '2022-01-07',
    changelog: 'Publications can now be created using the frontend.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'A new form to create a publication.',
          },
          {
            type: ChangeType.Added,
            description:
              'A new page to create multiple publications by uploading an excel file.',
          },
        ],
      },
    ],
  },
  {
    version: '2021-12-16',
    changelog: 'The collections can now be edited.',
    components: [
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'A new `collection` on PublicationMetadata and the equivalent fields in the various create/update methods',
          },
        ],
      },
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'A new repeatable field for the collections in the metadata edit form',
          },
        ],
      },
    ],
  },
  {
    version: '2021-11-25',
    changelog: 'The subtitle can now be edited.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'A new field to edit the subtitle has been added in the metadata editing screen.',
          },
        ],
      },
    ],
  },
  {
    version: '2021-11-17',
    changelog: 'Frontend improvements.',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              "Button to force update of all publication's licenses.",
          },
          {
            type: ChangeType.Added,
            description: 'Return to publications page after edition.',
          },
        ],
      },
    ],
  },
  {
    version: '2021-11-15',
    changelog: "Added support for modifying publication's metadata.",
    components: [
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: 'New update metadata mutation.',
          },
        ],
      },
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'New update metadata UI.',
          },
        ],
      },
    ],
  },
  {
    version: '2021-10-28',
    changelog: "Added support for publication's fileuploads",
    components: [
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Added federated namespace to Publication',
          },
          {
            type: ChangeType.Added,
            description:
              'Added support to get an ephermeral upload URL for a namespace incoming area.',
          },
        ],
      },
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added the functionality to upload files related to a publication.',
          },
        ],
      },
    ],
  },
  {
    version: '2021-10-20',
    changelog: 'Added support for custom attributes in licenses',
    components: [
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Added a new attribute License.customAttributes',
          },
          {
            type: ChangeType.Added,
            description:
              'Added a new input field LicenseInput.customAttributes',
          },
        ],
      },
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added a table of custom attributes for a license when present.',
          },
          {
            type: ChangeType.Added,
            description:
              'Added a field to specify custom attributes when creating a license',
          },
        ],
      },
    ],
  },
  {
    version: '2021-10-19',
    changelog: 'Added support for user and namespace management',
    components: [
      {
        name: 'API',
        changes: [
          {
            type: ChangeType.Added,
            description: "Adds methods to manage a user's roles and namespaces",
          },
        ],
      },
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description: 'Added a page to invite a new user',
          },
          {
            type: ChangeType.Added,
            description: 'Added a page to create new namespaces',
          },
          {
            type: ChangeType.Added,
            description:
              'Added a page to add and remove namespaces and roles from a user',
          },
        ],
      },
    ],
  },
  {
    version: '2021-08-06',
    changelog: 'The return loan button now works in a license page',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Fixed,
            description:
              'Made the return button work on the active loans of a license',
          },
          {
            type: ChangeType.Changed,
            description:
              'Removed the return loan button from the generic list of loans',
          },
        ],
      },
    ],
  },
  {
    version: '2021-07-20',
    changelog: 'We have added a new changelog! You found it!',
    components: [
      {
        name: 'Frontend',
        changes: [
          {
            type: ChangeType.Added,
            description:
              'Added a new changelog page and its accompanying menu item',
          },
        ],
      },
    ],
  },
];
