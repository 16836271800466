<template>
  <div class="table-wrap">
    <template v-if="loading">Loading...</template>
    <template v-else-if="namespaces">
      <Table
        :headers="['Namespace', '']"
        :hasNextPage="!!relatedNamespaces?.pageInfo.hasNextPage"
        :hasPreviousPage="!!relatedNamespaces?.pageInfo.hasPreviousPage"
        @changePage="(e) => (e === 'next' ? loadNext() : loadPrevious())"
      >
        <tr
          v-for="edge of namespaces"
          :key="edge.node.id"
          class="even:bg-gray-50"
        >
          <td>
            {{ edge.node.name }}
            <router-link
              :to="{
                name: 'namespace',
                params: { id: edge.node.id },
              }"
            >
              <p>{{ edge.node.id }}</p>
            </router-link>
          </td>
          <td v-if="canModifyRelatedNamespaces()" class="text-right">
            <button
              @click="deleteRelation(edge.node.id)"
              class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none p-2 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
            >
              <svg
                class="bi bi-trash3-fill inline-block w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 16"
                aria-hidden="true"
              >
                <path
                  d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </Table>
    </template>
    <div class="add-relation" v-if="canModifyRelatedNamespaces()">
      <namespace-selector
        resource-type="namespaces"
        v-model="addNamespaceRef"
      />
      <button
        @click="addNamespace"
        class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none p-2 leading-5 text-sm border-gray-300 bg-white shadow-sm focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-white active:border-white active:shadow-none"
      >
        <svg
          class="bi bi-plus-lg inline-block w-5 h-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 16"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';
import { useQuery, useApolloClient } from '@vue/apollo-composable';
import { useToast } from 'vue-toastification';

import { graphql } from '../../../__generated__/gql';

import { useAuth } from '../../../auth';
import NamespaceSelector from '../NamespaceSelector.vue';
import {
  ContentProvidersQuery,
  ContentProvidersQueryVariables,
  LicensersQuery,
  LicensersQueryVariables,
  RelatedLicensesFragment,
} from '../../../__generated__/graphql';

import Table from '../../base/Table.vue';

const toast = useToast();

const { canWriteResourceTypeOnAtLeastOneNamespace } = useAuth();

const canModifyRelatedNamespaces = () =>
  canWriteResourceTypeOnAtLeastOneNamespace('namespaces');

type Role = 'allowedContentProviders' | 'allowedLicensers';

const props = defineProps<{
  listedRole: Role;
  namespaceId: string;
}>();

const _RELATED_LICENSES_FRAGMENT = graphql(`
  fragment RelatedLicenses on NamespaceConnection {
    edges {
      node {
        id
        name
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
      startCursor
    }
  }
`);

const contentProvidersQuery = graphql(`
  query ContentProviders(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    namespace(id: $id) {
      allowedContentProviders(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        ...RelatedLicenses
      }
    }
  }
`);

const licensersQuery = graphql(`
  query Licensers(
    $id: ID!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    namespace(id: $id) {
      allowedLicensers(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        ...RelatedLicenses
      }
    }
  }
`);

const relatedNamespaces: Ref<RelatedLicensesFragment | null> = computed(
  () =>
    // FIXME
    // @ts-ignore
    result.value?.namespace?.[
      props.listedRole
    ] as unknown as RelatedLicensesFragment
);

const perPage = 10;

const variables: Ref<ContentProvidersQueryVariables | LicensersQueryVariables> =
  computed(() => ({
    id: props.namespaceId,
    first: perPage,
    last: null,
    after: null,
    before: null,
  }));

const { result, loading, refetch, fetchMore } = useQuery<
  ContentProvidersQuery | LicensersQuery
>(
  props.listedRole == 'allowedContentProviders'
    ? contentProvidersQuery
    : licensersQuery,
  variables
);

const loadNext = () =>
  fetchMore({
    variables: {
      after: relatedNamespaces.value?.pageInfo?.endCursor,
      first: perPage,
      last: null,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });

const loadPrevious = () =>
  fetchMore({
    variables: {
      before: relatedNamespaces.value?.pageInfo?.startCursor,
      last: perPage,
      first: null,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });

const namespaces = computed(() => relatedNamespaces.value?.edges);

const apolloClient = useApolloClient();

// DELETE
const deleteRelation = async (listedNamespaceId: string) => {
  const contentProviderId =
    props.listedRole == 'allowedContentProviders'
      ? listedNamespaceId
      : props.namespaceId;
  const licenserId =
    props.listedRole == 'allowedLicensers'
      ? listedNamespaceId
      : props.namespaceId;

  try {
    await apolloClient.client.mutate({
      mutation: graphql(`
        mutation RemoveAllowedContentNamespaceForLicenser(
          $content: ID!
          $licenser: ID!
        ) {
          removeAllowedContentNamespaceForLicenser(
            content: $content
            licenser: $licenser
          )
        }
      `),
      variables: {
        content: contentProviderId,
        licenser: licenserId,
      },
    });
    refetch();
  } catch (error) {
    toast.error(
      'An error has occurred while removing a relation between namespaces.'
    );
    throw error;
  }
};

// ADD
const addNamespaceRef = ref<{ name: string; id: string } | null>(null);

const addNamespace = async () => {
  const contentProviderId =
    props.listedRole == 'allowedContentProviders'
      ? addNamespaceRef.value?.id
      : props.namespaceId;
  const licenserId =
    props.listedRole == 'allowedLicensers'
      ? addNamespaceRef.value?.id
      : props.namespaceId;

  addNamespaceRef.value = null;

  if (!(licenserId && contentProviderId)) {
    return;
  }

  const namespaceToAddAlreadyInRelation = namespaces.value?.find(
    (edge) =>
      edge.node.id ==
      (props.listedRole == 'allowedContentProviders'
        ? contentProviderId
        : licenserId)
  );

  if (namespaceToAddAlreadyInRelation != undefined) {
    toast.info(
      `Namespace "${namespaceToAddAlreadyInRelation.node.name}" is already a ${
        props.listedRole == 'allowedContentProviders'
          ? 'content provider'
          : 'licenser'
      } for this namespace.`
    );
    return;
  }

  try {
    await apolloClient.client.mutate({
      mutation: graphql(`
        mutation AddAllowedContentNamespaceForLicenser(
          $content: ID!
          $licenser: ID!
        ) {
          addAllowedContentNamespaceForLicenser(
            content: $content
            licenser: $licenser
          )
        }
      `),
      variables: {
        content: contentProviderId,
        licenser: licenserId,
      },
    });
    refetch();
  } catch (error) {
    toast.error(
      'An error has occurred while adding a relation between namespaces.'
    );
    throw error;
  }
};
</script>

<style scoped>
.table-wrap {
  @apply border border-gray-200 rounded overflow-x-auto min-w-full bg-white;
}

table {
  @apply min-w-full text-sm align-middle whitespace-nowrap table-fixed;
}

tr {
  @apply border-b border-gray-200;
}

th {
  @apply p-3 text-gray-700 bg-gray-100 font-semibold text-sm tracking-wider uppercase text-left w-1/3;
}

td {
  @apply p-3 text-gray-900;
}

td p {
  @apply text-gray-500;
}

tfoot td {
  @apply p-3 text-gray-700 bg-gray-100 font-semibold text-sm;
}

.add-relation {
  @apply m-3 flex flex-row space-x-2;
}

.pagination {
  @apply flex flex-row justify-around;
}

.pagination button {
  @apply text-sm
    border-gray-300
    bg-white

    inline-flex
    justify-center
    items-center
    space-x-2
    px-2
    py-1

    rounded
    border
    font-semibold
    leading-5
    shadow-sm

    focus:ring
    focus:ring-gray-500
    focus:ring-opacity-25
    focus:outline-none

    active:bg-white
    active:border-white active:shadow-none;
}

.buttons {
  @apply space-x-2;
}
</style>
