<template>
  <dm-search-dialog
    @selected="emitSelected"
    :search="searchNamespaces"
    buttonLabel="Add Namespace"
    :buttonLoading="buttonLoading"
    buttonVariant="simple"
  />
</template>

<script lang="ts">
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { inject } from 'vue';

import { graphql } from '../__generated__/gql';

import DmSearchDialog from './base/SearchDialog.vue';

const SEARCH_NAMESPACES = graphql(`
  query Search($keyword: String!) {
    namespaces(filterBy: { keyword: $keyword }, first: 5) {
      edges {
        node {
          id
          description
          name
        }
      }
    }
  }
`);

export default {
  name: 'namespace-search',
  props: {
    buttonLoading: {
      type: Boolean,
      required: false,
    },
    buttonLabel: {
      type: String,
      required: false,
      default: 'Search...',
    },
  },
  emits: ['selected'],
  components: {
    DmSearchDialog,
  },
  setup(_: any, context: any) {
    const apolloClient = inject(
      DefaultApolloClient
    ) as ApolloClient<NormalizedCacheObject>;

    const emitSelected = function (event: any) {
      context.emit('selected', event);
    };
    const searchNamespaces = async (keyword: string) => {
      const result = await apolloClient.query({
        query: SEARCH_NAMESPACES,
        variables: { keyword },
      });
      return result.data.namespaces.edges.map((edge: any) => ({
        id: edge.node.id,
        label: edge.node.name,
      }));
    };
    return { emitSelected, searchNamespaces };
  },
};
</script>
