<template>
  <message-confirmation
    :isHidden="hideArchiveFileAlert"
    titleInfoMessage="Are you sure you want to archive this file?"
    bodyInfoMessage="This will result in archiving the files derived from it and their representations. This can make a publication non-borrowable"
    variant="alert"
    @answerUser="answerArchiveFileAlert"
    button-cancel-message="Cancel Archival"
    button-confirm-message="Confirm Archival"
    :show-cancel-button="true"
    :large="true"
  ></message-confirmation>

  <button
    @click.stop="showArchiveFileAlert(props.file.id)"
    :class="
      props.buttonSize === 'large'
        ? 'inline-flex justify-center items-center space-x-2 border rounded-lg font-normal px-3 py-2 leading-6 border-indigo-700 bg-indigo-700 text-indigo-100 hover:border-indigo-300 hover:text-indigo-300 hover:shadow-sm focus:ring focus:ring-indigo-300 focus:ring-opacity-25 active:border-indigo-200 active:shadow-none dark:border-indigo-200 dark:bg-indigo-200 dark:hover:border-indigo-300 dark:hover:bg-indigo-300 dark:focus:ring-indigo-500 dark:focus:ring-opacity-50 dark:active:border-indigo-200 dark:active:bg-indigo-200'
        : ''
    "
  >
    <svg
      class="bi bi-archive inline-block"
      :class="props.buttonSize === 'small' ? 'w-5 h-5' : 'w-4 h-4'"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 16"
      aria-hidden="true"
    >
      <path
        d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
      />
    </svg>
    <span v-if="props?.buttonSize === 'large'">Archive</span>
  </button>
</template>

<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable';
import { Ref, ref } from 'vue';
import { useToast } from 'vue-toastification';

import { graphql } from '../../__generated__/gql';

import MessageConfirmation from '../base/DialogMessage.vue';

const props = defineProps<{
  file: {
    id: string;
    nature: string;
    mime: string;
  };
  buttonSize: string;
}>();

const emits = defineEmits<{
  (e: 'archived'): void;
}>();

const toast = useToast();
const popToast = (toastTitle: string, toastContent: string) => {
  toast(toastTitle.toUpperCase() + '\n' + toastContent);
};

const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const hideArchiveFileAlert = ref(true);
const selectedFileId: Ref<String> = ref('');

const answerArchiveFileAlert = async (answer: boolean) => {
  hideArchiveFileAlert.value = true;
  if (answer && selectedFileId.value) {
    archiveFile({ fileId: selectedFileId.value as string });
  }
  selectedFileId.value = '';
};

const showArchiveFileAlert = (fileId: string) => {
  selectedFileId.value = fileId;
  hideArchiveFileAlert.value = false;
};

const ARCHIVE_FILE = graphql(`
  mutation archiveFile($fileId: ID!) {
    archiveFile(fileId: $fileId) {
      success
    }
  }
`);

const {
  mutate: archiveFile,
  onDone: onArchiveFileDone,
  onError: onArchiveFileError,
} = useMutation(ARCHIVE_FILE);

onArchiveFileDone(() => {
  emits('archived');
  hideArchiveFileAlert.value = true;
  popToast('File', ' has been archived.');
});

onArchiveFileError((error) => {
  hideArchiveFileAlert.value = true;
  popErrorToast('Error', error.message);
});
</script>

<style></style>
