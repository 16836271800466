<template>
  <h2 class="page-title">New Subscription</h2>
  <div v-if="loading || createLoading">Loading</div>
  <div v-else-if="createError">{{ createError }}</div>
  <form v-else-if="result" @submit="createNewSubscription()" class="space-y-6">
    <div class="space-y-1">
      <label class="font-medium" for="tk-inputs-default-select"
        >Namespace</label
      >
      <namespace-selector v-model="namespaceSelectedRef" />
    </div>
    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-starts-at"
        >Starts at</label
      >
      <DateInput
        v-model="startsAt"
        class="w-full block leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
        id="tk-form-elements-starts-at"
        placeholder="Enter the beginning date (YYYY-MM-DD)"
      />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-ends-at">Ends at</label>
      <div
        v-if="startsAt"
        class="font-semibold inline-flex px-2 py-1 mx-2 leading-4 text-xs rounded-full text-blue-700 bg-blue-200 hover:bg-blue-400 hover:border-blue-700 hover:text-white"
      >
        <a @click.prevent="yearLater()">a year later</a>
      </div>
      <DateInput
        v-model="endsAt"
        class="w-full block leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
        id="tk-form-elements-ends-at"
        placeholder="Enter the beginning date (YYYY-MM-DD)"
      />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-count">Count</label>
      <input
        v-model.number="count"
        class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
        type="number"
        id="tk-form-elements-count"
        placeholder="Enter the number of licensees"
      />
    </div>

    <div class="space-y-1">
      <label class="font-medium" for="tk-form-elements-concurrency"
        >Concurrency</label
      >
      <input
        v-model.number="concurrency"
        class="w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
        type="number"
        id="tk-form-elements-concurrency"
        placeholder="Enter the number of concurrent uses"
      />
    </div>
    <div class="space-y-1">
      <label class="font-medium" for="form-elements-is-part-type"
        >Custom Attributes</label
      >
      <ul class="list">
        <li
          class="p-4 flex justify-between items-center"
          v-for="attribute in customAttributesRef"
        >
          <span class="font-semibold text-sm mr-1">{{ attribute.key }}</span>
          <span class="font-semibold text-sm mr-1">{{ attribute.value }}</span>
          <span>
            <dm-button
              variant="danger"
              @click.prevent="removeCustomAttribute(attribute.key)"
              size="xs"
              >remove</dm-button
            >
          </span>
        </li>

        <li class="flex justify-start items-center">
          <input
            v-model="newAttributeKeyRef"
            class="w-full grow block border border-gray-200 rounded mr-3 px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            id="form-elements-new-attribute-key-label"
            type="text"
            placeholder="Enter the attribute key."
          />
          <input
            v-model="newAttributeValueRef"
            class="w-full grow block border border-gray-200 rounded px-3 py-2 leading-6 mr-3 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
            id="form-elements-new-attribute-value-label"
            type="text"
            placeholder="Enter the attribute value."
          />
          <dm-button size="xs" @click.prevent="addNewCustomAttribute"
            >Add</dm-button
          >
        </li>
      </ul>
    </div>

    <div class="space-y-1">
      <dm-button :enabled="validForm">Create Subscription</dm-button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { Ref, computed, ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';
import { CreatePeriodicalTemplateMutationVariables } from '../../__generated__/graphql';

import NamespaceSelector from '../namespaces/NamespaceSelector.vue';
import DateInput from '../base/DateInput.vue';

interface Attribute {
  key: string;
  value: string;
}

const route = useRoute();
const router = useRouter();
const id = route.params.id as string;
const firstDash = id.indexOf(':');

const originAsString: string = id.slice(0, firstDash);
const origin =
  originAsString == 'DM_SPAIN'
    ? 'DM_SPAIN'
    : originAsString == 'ODILO_SPAIN'
    ? 'ODILO_SPAIN'
    : null;

if (!origin) {
  throw new Error(
    `Problem when routing from /periodical/:id: ${id}. DM_SPAIN or ODILO_SPAIN expected.`
  );
}

const periodical: string = id.slice(firstDash + 1);
const customAttributesRef = ref<Attribute[]>([]);
const newAttributeKeyRef = ref<string>('');
const newAttributeValueRef = ref<string>('');

const { result, loading } = useQuery(
  graphql(`
    query GetPeriodicalForNew($origin: PeriodicalOrigin!, $identifier: ID!) {
      periodical(origin: $origin, identifier: $identifier) {
        identifier
        name
      }
    }
  `),
  {
    origin,
    identifier: periodical,
  }
);

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const count = ref<number>(1);
const concurrency = ref<number>(1);
const startsAt = ref<string | null>(null);
const endsAt = ref<string | null>(null);

const yearLater = () => {
  if (startsAt.value !== null) {
    const starts = new Date(startsAt.value);
    endsAt.value = new Date(
      starts.getFullYear() + 1,
      starts.getMonth(),
      starts.getDate() + 1
    )
      .toISOString()
      .slice(0, 16);
  }
};

const validForm = computed(() => {
  return !!(
    namespaceSelectedRef.value?.id != '' &&
    count.value > 0 &&
    concurrency.value > 0 &&
    startsAt.value &&
    startsAt.value != '' &&
    endsAt.value &&
    endsAt.value != ''
  );
});

const variables: Ref<CreatePeriodicalTemplateMutationVariables> = computed(
  () => ({
    origin,
    periodical,
    namespace: namespaceSelectedRef.value?.id ?? '',
    count: count.value,
    concurrency: concurrency.value,
    startsAt: startsAt.value,
    endsAt: endsAt.value,
    customAttributes: customAttributesRef.value,
  })
);

const {
  mutate: createNewSubscription,
  loading: createLoading,
  error: createError,
  onDone: onCreateDone,
} = useMutation(
  graphql(`
    mutation CreatePeriodicalTemplate(
      $origin: PeriodicalOrigin!
      $namespace: ID!
      $periodical: ID!
      $count: Int!
      $concurrency: Int!
      $startsAt: Date
      $endsAt: Date
      $customAttributes: [AttributeInput!]
    ) {
      createPeriodicalTemplate(
        input: {
          namespaceId: $namespace
          periodicalOrigin: $origin
          periodicalIdentifier: $periodical
          count: $count
          concurrency: $concurrency
          startsAt: $startsAt
          endsAt: $endsAt
          customAttributes: $customAttributes
        }
      ) {
        id
      }
    }
  `),
  () => ({ variables: variables.value })
);

const addNewCustomAttribute = () => {
  if (customAttributesRef.value) {
    customAttributesRef.value.push({
      key: newAttributeKeyRef.value,
      value: newAttributeValueRef.value,
    });
    newAttributeKeyRef.value = '';
    newAttributeValueRef.value = '';
  }
};

const removeCustomAttribute = (key: string) => {
  if (customAttributesRef.value) {
    customAttributesRef.value = customAttributesRef.value.filter(
      (c) => c.key != key
    );
  }
};

onCreateDone((_result) => {
  router.push({ name: 'periodical', params: { id: route.params.id } });
});
</script>
