import { DefaultApolloClient } from '@vue/apollo-composable';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core';
import { inject } from 'vue';

import { graphql } from '../../__generated__/gql';

// An item to display all namespaces
export const SELECT_ALL_NAMESPACES: { id: string; name: string } = {
  id: '[ALL]',
  name: 'All namespaces',
};

// Query to list all namespaces
export const QUERY_ALL_NAMESPACES = graphql(`
  query NamespaceListing {
    namespaces(first: 999) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`);

export const getAllNamespaces = async (): Promise<
  { id: string; name: string }[]
> => {
  const apolloClient = inject(
    DefaultApolloClient
  ) as ApolloClient<NormalizedCacheObject>;
  const result = await apolloClient.query({ query: QUERY_ALL_NAMESPACES });
  return result?.data?.namespaces.edges.map((edge) => edge.node) ?? [];
};
