<template>
  <h2 class="page-title">New Periodical</h2>
  <div class="space-y-8">
    <form @submit.prevent="performCreateNewPeriodical()" class="space-y-6">
      <div class="md:flex md:space-x-5">
        <!-- Target Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Basic Information</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5 text-justify">
            The basic information we need to know about the periodical. It will
            be used to populate the metadata of each publication.
          </p>
        </div>
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3"
        >
          <!-- Card Body: Target -->
          <div class="p-5 lg:p-6 flex-grow w-full space-y-6">
            <div class="space-y-1">
              <label for="form-input-origin" class="font-medium"
                >Subscription origin</label
              >
              <select v-model="origin" class="form-input" id="form-input-type">
                <option value="ODILO_SPAIN">Odilo (Spain)</option>
                <option value="DM_SPAIN">De Marque (Spain)</option>
              </select>

              <p class="text-sm text-gray-500">
                The origin of the subscription used by this periodical. For now
                we only support the odilo and demarque, for Spain.
              </p>
            </div>
            <div class="space-y-1">
              <label for="form-input-identifier" class="font-medium"
                >Identifier</label
              >
              <input
                v-model="identifier"
                :class="[
                  'form-input',
                  {
                    'form-input-required': identifierIsNull,
                  },
                ]"
                type="text"
                id="form-input-identifier"
                placeholder="xyz1"
              />
              <p v-if="identifierIsNull" class="text-red-500 font-medium">
                The field identifier can't be empty
              </p>
              <p class="text-sm text-gray-500">
                The identifier for the periodical as a series. Each publication
                will be given that value as an ISSN. For Odilo-provided
                periodicals, it should match the CID_<strong>{{
                  identifier || 'xyz1'
                }}</strong
                >.txt found on their FTP.
              </p>
            </div>
            <div class="space-y-1">
              <label
                for="tk-form-layouts-multiple-sections-name"
                class="font-medium"
                >Name</label
              >
              <input
                v-model="name"
                :class="[
                  'form-input',
                  {
                    'form-input-required': nameIsNull,
                  },
                ]"
                type="text"
                id="tk-form-layouts-multiple-sections-name"
                placeholder="El País"
              />
              <p v-if="nameIsNull" class="text-red-500 font-medium">
                The field name can't be empty
              </p>
            </div>
            <div class="space-y-1">
              <label for="form-input-type" class="font-medium">Type</label>
              <select v-model="type" class="form-input" id="form-input-type">
                <option value="NEWSPAPER">Newspaper</option>
                <option value="MAGAZINE">Magazine</option>
              </select>
              <p class="text-sm text-gray-500">
                Will determine the max loan duration
              </p>
            </div>
            <div class="space-y-1">
              <label
                for="tk-form-layouts-multiple-sections-publisher"
                class="font-medium"
                >Publisher</label
              >
              <input
                v-model="publisher"
                class="form-input"
                type="text"
                id="tk-form-layouts-multiple-sections-publisher"
                placeholder="RBA"
              />
            </div>
            <div class="space-y-1">
              <label for="form-input-lang" class="font-medium">Language</label>
              <select v-model="lang" class="form-input" id="form-input-lang">
                <option v-for="language in languages" :value="language.value">
                  {{ language.name }}
                </option>
              </select>
            </div>
            <div class="space-y-1">
              <label class="font-medium" for="tk-form-elements-summary"
                >Summary</label
              >
              <textarea
                v-model="summary"
                class="form-input"
                id="tk-form-elements-summary"
                :rows="4"
                placeholder="Enter further details"
              ></textarea>
            </div>
            <div class="space-y-1">
              <label
                for="tk-form-input-hasAdultConsideration"
                class="font-medium"
                >Only Adult</label
              >
              <input
                v-model="hasAdultConsideration"
                class="block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                type="checkbox"
                id="tk-form-input-hasAdultConsideration"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Custom Attributes -->
      <div class="md:flex md:space-x-5">
        <!-- Custom Attributes Info -->
        <div class="md:flex-none md:w-1/3 text-center md:text-left">
          <h3
            class="flex items-center justify-center md:justify-start space-x-2 font-semibold mb-1"
          >
            <svg
              class="hi-solid hi-user-circle inline-block w-5 h-5 text-indigo-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Custom Attributes</span>
          </h3>
          <p class="text-gray-500 text-sm mb-5 text-justify">
            Here we specify the ftp server's name and the CID_<strong>{{
              identifier || 'xyz1'
            }}</strong>
            .txt file's relative route within its server. If any of its
            paremeters are empty, none will be taken into account.
          </p>
        </div>
        <!-- Card: Custom Attributes -->
        <div
          class="flex flex-col rounded shadow-sm bg-white overflow-hidden md: md:w-2/3"
        >
          <div class="p-5 lg:p-6 flex-grow w-full flex flex-col space-y-1">
            <label class="font-medium" for="tk-form-elements-sumnmary"
              >Ftp Server</label
            >
            <input
              v-model="ftpServer"
              class="form-input"
              type="text"
              id="tk-form-layouts-multiple-sections-publisher"
              placeholder="periodicals"
            />
          </div>
          <div
            class="px-5 pb-5 lg:px-6 lg:pb-6 flex-grow w-full flex flex-col space-y-1"
          >
            <warning-display
              :warning="warningMessage"
              headerMessage="Remember to fill both fields"
            ></warning-display>
          </div>
          <div
            class="px-5 pb-5 lg:px-6 lg:pb-6 flex-grow w-full flex flex-col space-y-1"
          >
            <label class="font-medium" for="tk-form-elements-sumnmary"
              >Ftp Path</label
            >
            <input
              v-model="ftpPath"
              class="form-input"
              type="text"
              id="tk-form-layouts-multiple-sections-publisher"
              placeholder="/prisa"
            />
          </div>
        </div>
      </div>
      <!-- Actions -->
      <div class="md:flex md:flex-row md:space-x-5">
        <div class="md:flex-none md:w-1/3 text-center md:text-left"></div>
        <div class="flex flex-col rounded overflow-hidden md:w-2/3">
          <div class="space-y-1">
            <dm-button
              type="submit"
              :loading="createLoading"
              :enabled="!nameIsNull && !identifierIsNull"
              >Create Periodical</dm-button
            >
          </div>
        </div>
      </div>
    </form>
    <div
      v-if="visibleErrorMessage"
      class="flex flex-none items-center fixed top-8 right-0 left-0 z-30 dark:bg-gray-800 lg:pl-64"
    >
      <div
        class="flex items-center mx-2 lg:mx-4 w-full p-2 rounded text-red-700 bg-red-100 dark:text-red-100 dark:bg-red-900 dark:bg-opacity-75"
      >
        <svg
          class="hi-solid hi-x-circle inline-block w-5 h-5 mr-3 flex-none text-red-500 dark:text-red-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
        <h3 class="font-semibold grow">
          {{ errorMessage }}
        </h3>
        <button
          type="button"
          @click="closeErrorMessage()"
          class="inline-flex justify-center items-center space-x-2 rounded border font-semibold focus:outline-none ml-3 px-2 py-1 leading-5 text-sm border-transparent text-red-600 hover:text-red-400 focus:ring focus:ring-red-500 focus:ring-opacity-50 active:text-red-600 dark:text-red-500 dark:hover:text-red-400 dark:focus:ring dark:focus:ring-red-400 dark:focus:ring-opacity-50 dark:active:text-red-500"
        >
          <svg
            class="hi-solid hi-x inline-block w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <!-- Card Body: User Profile -->
</template>
<script setup lang="ts">
import { useMutation } from '@vue/apollo-composable';
import { Ref, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import WarningDisplay from '../WarningDisplay.vue';
import { PeriodicalOrigin, PeriodicalType } from '../../__generated__/graphql';

const CREATE_PERIODICAL = graphql(`
  mutation CreatePeriodical(
    $origin: PeriodicalOrigin!
    $identifier: String!
    $name: String!
    $publisher: String
    $lang: String!
    $summary: String
    $type: PeriodicalType!
    $customAttributes: [AttributeInput!]
    $hasAdultConsideration: Boolean!
  ) {
    createPeriodical(
      input: {
        origin: $origin
        identifier: $identifier
        name: $name
        publisher: $publisher
        lang: $lang
        summary: $summary
        type: $type
        customAttributes: $customAttributes
        hasAdultConsideration: $hasAdultConsideration
      }
    ) {
      origin
      identifier
    }
  }
`);
const languages: { name: string; value: string }[] = [
  { name: 'Catalan', value: 'cat' },
  { name: 'English', value: 'eng' },
  { name: 'French', value: 'fre' },
  { name: 'Galician', value: 'glg' },
  { name: 'Italian', value: 'ita' },
  { name: 'Spanish', value: 'spa' },
];
const router = useRouter();
const ftpServer = ref<string>('');
const ftpPath = ref<string>('');
const warningMessageContent: string =
  'If any of its paremeters are empty, none will be taken into account.';
const warningMessage: Ref<null | string> = computed(() =>
  ftpServer.value.replace(/\s\s+/g, '') === '' ||
  ftpPath.value.replace(/\s\s+/g, '') === ''
    ? warningMessageContent
    : null
);
const visibleErrorMessage: Ref<boolean> = ref(false);
const errorMessage: Ref<string> = ref('');
const origin = ref<PeriodicalOrigin>('ODILO_SPAIN');
const identifier = ref<string>('');
const identifierIsNull = computed(
  () => identifier.value.replace(/\s/g, '') === ''
);
const name = ref<string>('');
const nameIsNull = computed(() => name.value.replace(/\s/g, '') === '');
const publisher = ref<string | null>(null);
const lang = ref<string>(languages[0].value);
const summary = ref<string | undefined>(undefined);
const hasAdultConsideration = ref<boolean>(false);
const type = ref<PeriodicalType>('NEWSPAPER');
const customAttributes = computed(() =>
  ftpServer.value.replace(/\s\s+/g, '') != '' &&
  ftpPath.value.replace(/\s\s+/g, '') != ''
    ? [
        {
          key: 'ftp_server',
          value: ftpServer.value.replace(/\s\s+/g, ' ').trim(),
        },
        { key: 'ftp_path', value: ftpPath.value.replace(/\s\s+/g, ' ').trim() },
      ]
    : null
);

const {
  mutate: createNewPeriodical,
  onError: onCreateError,
  onDone: onCreateDone,
  loading: createLoading,
} = useMutation(CREATE_PERIODICAL, () => ({
  variables: {
    origin: origin.value,
    identifier: identifier.value.replace(/\s\s+/g, ' ').trim(),
    name: name.value.replace(/\s\s+/g, ' ').trim(),
    publisher: publisher.value?.replace(/\s\s+/g, ' ').trim(),
    summary: summary.value?.replace(/\s\s+/g, ' ').trim(),
    lang: lang.value,
    type: type.value,
    customAttributes: customAttributes.value,
    hasAdultConsideration: hasAdultConsideration.value,
  },
}));

onCreateDone((result) => {
  visibleErrorMessage.value = false;
  router.push({
    name: 'periodical',
    params: {
      id: `${result.data?.createPeriodical?.origin}:${result.data?.createPeriodical?.identifier}`,
    },
  });
});
onCreateError((error) => {
  errorMessage.value = error.message;
  visibleErrorMessage.value = true;
});
function performCreateNewPeriodical() {
  visibleErrorMessage.value = false;
  createNewPeriodical();
}
function closeErrorMessage() {
  visibleErrorMessage.value = false;
}
</script>
<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}
.form-input-required {
  @apply focus:border-red-500 focus:ring-red-500 border-red-500;
}
</style>
