<template>
  <template v-if="loading"> Loading...</template>
  <template v-else-if="loans">
    <h2 class="page-title">Loans</h2>
    <Table
      center-header
      :headers="[
        'Loan',
        'Borrower',
        'Transaction',
        'Expires',
        'Status',
        'Actions',
      ]"
    >
      <tr
        v-for="loan of loans.edges"
        :key="loan.cursor"
        class="border-b border-gray-200"
      >
        <td class="p-3">
          <p class="font-medium text-xs">
            <router-link :to="{ name: 'loan', params: { id: loan.node.id } }">
              {{ loan.node.id }}
            </router-link>
          </p>
        </td>
        <td class="p-3 text-gray-500">
          {{ loan.node.borrowerId }}
        </td>
        <td class="p-3 text-center">
          {{ loan.node.transactionId }}
        </td>
        <td class="p-3 text-center">
          {{ loan.node.expires }}
        </td>
        <td class="p-3 text-center">
          {{ loan.node.status }}
        </td>
        <td class="p-3 text-center"></td>
      </tr>
    </Table>
  </template>
</template>

<script lang="ts">
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import { graphql } from '../../__generated__/gql';

import Table from '../base/Table.vue';

export default defineComponent({
  name: 'manage-licenses',
  methods: {
    formattedId(id: string): string {
      let splits = id.split('/');
      return splits[splits.length - 1];
    },
  },
  setup() {
    const route = useRoute();
    const { result, loading } = useQuery(
      graphql(`
        query GetLoans {
          loans(orderBy: { field: CREATED_AT, direction: DESC }) {
            pageInfo {
              hasNextPage
            }
            edges {
              cursor
              node {
                id
                borrowerId
                transactionId
                expires
                status
              }
            }
          }
        }
      `)
    );

    const loans = useResult(result);

    return { loans, loading };
  },
});
</script>
