<template>
  <div class="space-y-1">
    <div>
      <label class="font-medium italic">{{ label }}</label>
      <information-button v-if="props.formInfo" :formInfo="props.formInfo" />
    </div>
    <textarea
      v-model="internalModel as string"
      :class="[
        'form-input',
        {
          'form-input-changed': hasChanged,
        },
      ]"
      type="text"
      id="form-elements-description"
      :placeholder="placeholder || ''"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import InformationButton from '../../base/InformationButton.vue';

const props = defineProps<{
  label: string;
  initialValue?: string | null;
  placeholder?: string | null;
  formInfo?: string | null;
  modelValue: string | null | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | undefined): void;
  (e: 'differs', value: boolean): void;
}>();

const internalModel = ref(props.initialValue);
const hasChanged = computed(() => internalModel.value !== props.initialValue);

onMounted(() => {
  watch(props, (newProps) => {
    internalModel.value = newProps.modelValue;
  });
  emit('update:modelValue', internalModel.value);
});

watch(internalModel, (newVal) => {
  emit('update:modelValue', newVal);
  emit('differs', hasChanged.value);
});
</script>
<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}
</style>
