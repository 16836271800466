<template>
  <button
    type="submit"
    class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-4 py-3 leading-6 rounded"
    :disabled="!enabled"
    :class="[
      !enabled ? 'button-disabled' : classForVariant(variant),
      full ? 'w-full' : '',
      classForSize(size),
    ]"
  >
    <div class="flex justify-center items-center" v-if="loading">
      <div class="animate-spin rounded-full mr-2 h-4 w-4 border-b-2"></div>
    </div>
    <slot />
  </button>
</template>

<script setup lang="ts">
interface Props {
  enabled?: boolean;
  full?: boolean;
  variant?: string;
  loading?: boolean;
  size?: string;
}
const props = withDefaults(defineProps<Props>(), {
  enabled: true,
  full: false,
  variant: 'default',
  loading: false,
  size: 'm',
});

const classForVariant = (variant: string): string => {
  switch (variant) {
    case 'danger':
      return 'danger-enabled';
    case 'default':
      return 'button-enabled';
    case 'input-box':
      return 'button-input-box';
    case 'simple':
      return 'simple-btn';
    default:
      return 'button-enabled';
  }
};

const classForSize = (size: String): String => {
  return `size-${size}`;
};
</script>

<style scoped>
.danger-enabled {
  @apply border-orange-700;
  @apply bg-orange-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-orange-800;
  @apply hover:border-orange-800;
  @apply focus:ring focus:ring-orange-500 focus:ring-opacity-50;
  @apply active:bg-orange-700 active:border-orange-700;
}
.button-input-box {
  @apply border border-gray-200 rounded;
  @apply text-gray-400;
  @apply pr-20;
}

.size-xs {
  @apply px-2 py-1;
  @apply leading-5;
  @apply text-sm;
}

.size-s {
  @apply px-3 py-2;
  @apply leading-5;
  @apply text-sm;
}

.size-m {
  @apply px-3 py-2;
  @apply leading-6;
}

.size-l {
  @apply px-4 py-3;
  @apply leading-6;
}

.size-xl {
  @apply px-6 py-4;
  @apply leading-6;
}

.button-enabled {
  @apply border-indigo-700;
  @apply bg-indigo-700;
  @apply text-white;
  @apply hover:text-white;
  @apply hover:bg-indigo-800;
  @apply hover:border-indigo-800;
  @apply focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
  @apply active:bg-indigo-700;
  @apply active:border-indigo-700;
}

.button-disabled {
  @apply border-gray-300;
  @apply bg-gray-300;
  @apply text-gray-700;
  @apply cursor-not-allowed;
}
.simple-btn {
  @apply w-fit;
  @apply block;
  @apply p-3;
  @apply shadow-sm;
  @apply border;
  @apply border-slate-100;
  @apply font-medium;
  @apply text-sm;
  @apply text-center;
  @apply bg-gray-50;
  @apply hover:bg-gray-100;
  @apply hover:bg-opacity-75;
  @apply active:bg-gray-50;
  @apply text-indigo-600;
  @apply hover:text-indigo-500;
}
</style>
